angular.module('app').controller('chartsCtrl', ['$scope','$rootScope', 'chartsService', '$sce', '$state','$interval','$stateParams',chartsCtrl]);

function chartsCtrl(r,state,chartsService, sce,$stateParams,interval,scope) {
  var vm = this;  
  vm.sensor = $stateParams.params.sensor;
  vm.gateway = $stateParams.params.gateway;
  vm.temperature_url = '';  
  vm.current_url = '';
  vm.voltage_url = '';
  vm.currents_url = '';
  vm.power_url = '';
  vm.cosenoPhi_url = '';
  vm.kwh_url = '';
  vm.consumoKwh_url = '';
  vm.cantidadAperturas_url = '';


  vm.setTemperature = function () {
    vm.current_url = vm.temperature_url;
  }
  vm.setVoltage = function () {
    vm.current_url = vm.voltage_url;
  }
  vm.setCurrent = function () {
    vm.current_url = vm.currents_url; 
  }
  vm.setActivePower = function () {
    vm.current_url = vm.active_power_url;
  }
  vm.setAparentPower = function () {
    vm.current_url = vm.aparent_power_url;
  }
  vm.setReactivePower = function () {
    vm.current_url = vm.reactive_power_url;
  }
  vm.setCosenoPhi = function () {
    vm.current_url = vm.cosenoPhi_url;
  }
  vm.setKwh = function () {
    vm.current_url = vm.kwh_url;
  }
  vm.setConsumoKwh = function () {
    vm.current_url = vm.consumoKwh_url;
  }
  vm.setDoorOpen = function () {
    vm.current_url = vm.cantidadAperturas_url; 
  }


  vm.get = function() {
		chartsService.getCharts().then(function(data) {
      vm.temperature_url = sce.trustAsResourceUrl( data.Temperature.url + vm.sensor);      
      vm.voltage_url = sce.trustAsResourceUrl( data.Voltage.url + vm.sensor);
      vm.currents_url = sce.trustAsResourceUrl( data.Current.url + vm.sensor);
      vm.active_power_url = sce.trustAsResourceUrl( data.ActivePower.url + vm.sensor);      
      vm.aparent_power_url = sce.trustAsResourceUrl( data.AparentPower.url + vm.sensor);      
      vm.reactive_power_url = sce.trustAsResourceUrl( data.ReactivePower.url + vm.sensor);      
      vm.cosenoPhi_url = sce.trustAsResourceUrl( data.CosPhi.url + vm.sensor);
      vm.kwh_url = sce.trustAsResourceUrl( data.kWh.url + vm.sensor);
      vm.consumoKwh_url = sce.trustAsResourceUrl( data.kWhAmount.url + vm.sensor);      
      vm.cantidadAperturas_url = sce.trustAsResourceUrl( data.DoorOpenCount.url + vm.sensor);

      vm.current_url = vm.temperature_url;
		});
  }
  
  vm.get();
  $('#btn_temperature_chart').click();
}