angular.module('app')
    .config(['$stateProvider', '$urlRouterProvider', '$locationProvider', function($stateProvider, $urlRouterProvider, $locationProvider) {
        $urlRouterProvider.otherwise("/");
        $stateProvider
            .state('app', {
                url: '/',
                views: {
                    '@': {
                        templateUrl: 'templates/layout.html'
                    },
                    'navView@app': {
                        templateUrl: 'templates/nav.html',
                        controller: 'navCtrl as ctrl'
                    },
                    'contentView@app': {
                        templateUrl: 'templates/mapa.html',
                        controller: 'mapaCtrl as ctrl'
                    }
                },
                data:{
                    requiresLogin: true,
                    // requiresRole: 'ADMIN',
                    pageTitle:'Inicio'
                }
            })
            .state('auth', {
                url: '/login',
                views: {
                    '@': {
                        templateUrl: 'templates/layout.html'
                    },
                    'contentView@auth': {
                        templateUrl: 'templates/login.html',
                        controller: 'loginCtrl as ctrl'
                    }
                }
            })
            .state('app.users', {
                url: 'usuarios',
                views: {
                    'contentView@app': {
                        templateUrl: 'templates/usuarios.html',
                        controller: 'usuariosCtrl as ctrl'
                    }
                },
                data:{
                    requiresLogin: true,
                    // requiresRole: 'ADMIN',
                    pageTitle:'Usuarios'
                }
            })
            .state('app.tachas', {
                url: 'heladeras',
                views: {
                    'contentView@app': {
                        templateUrl: 'templates/tachas.html',
                        controller: 'tachasCtrl as ctrl'
                    }
                },
                data:{
                    requiresLogin: true,
                    // requiresRole: 'ADMIN',
                    pageTitle:'Tachas'
                }
            })
            .state('app.mapa', {
                url: 'mapa',
                views: {
                    'contentView@app': {
                        templateUrl: 'templates/mapa.html',
                        controller: 'mapaCtrl as ctrl'
                    }
                },
                data:{
                    requiresLogin: true,
                    // requiresRole: 'ADMIN',
                    pageTitle:'Mapa de tachas instaladas'
                }
            })
            .state('app.logs', {
                url: 'ajustes',
                views: {
                    'contentView@app': {
                        templateUrl: 'templates/logs.html',
                        controller: 'logsCtrl as ctrl'
                    }
                },
                data:{
                    requiresLogin: true,
                    // requiresRole: 'ADMIN',
                    pageTitle:'Logs de actividad'
                }
            })
            .state('app.logsCmd', {
                url: 'logscmd',
                views: {
                    'contentView@app': {
                        templateUrl: 'templates/logsCmd.html',
                        controller: 'logsCmdCtrl as ctrl'
                    }
                },
                data:{
                    requiresLogin: true,
                    // requiresRole: 'ADMIN',
                    pageTitle:'Logs de actividad de comandos'
                }
            })
            .state('app.charts', {
                url: 'charts/:sensor/:gateway',
                views: {
                    'contentView@app': {
                        templateUrl: 'templates/charts.html',
                        controller: 'chartsCtrl as ctrl'
                    }
                },
                data:{
                    requiresLogin: true,
                    // requiresRole: 'ADMIN',
                    pageTitle:'Flujo de eventos de sensores'
                }
            })
            .state('app.events', {
                url: 'events/:sensor',
                views: {
                    'contentView@app': {
                        templateUrl: 'templates/events.html',
                        controller: 'eventsCtrl as ctrl'
                    }
                },
                data:{
                    requiresLogin: true,
                    // requiresRole: 'ADMIN',
                    pageTitle:'Lista de eventos de sensores'
                }
            });
        $locationProvider.html5Mode(true);
    }]);