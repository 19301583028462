angular.module('app').factory('chartsService', ['$rootScope', '$http', chartsService]);

function chartsService(r, h) {
    var service = {
        getCharts: getCharts
    };
    return service;

    function getCharts(o) {
        return h.get(apiRoute + '/api/config/graphics').then(function(resp) {
            return resp.data;
        });
    }

}