angular.module('app').factory('logsService', ['$rootScope', '$http', logsService]);

function logsService(r, h) {
    var service = {
        getAll: get,
        exportCsv: exportCsv,
        generateReport: generateReport,
        getComercios: getComercios,
        comerciosUpdate: comerciosUpdate,
        newComercio: newComercio,
        fabricantesUpdate: fabricantesUpdate,
        getFabricantes: getFabricantes,
        newFabricante: newFabricante,
        servicesUpdate: servicesUpdate,
        getServices: getServices,
        newService: newService,
        clientesUpdate: clientesUpdate,
        getClientes: getClientes,
        newCliente: newCliente,
        instaladorasUpdate: instaladorasUpdate,
        getInstaladoras: getInstaladoras,
        newInstaladora: newInstaladora,
        getAlarmas: getAlarmas,
        alarmasUpdate: alarmasUpdate,
        modelosUpdate: modelosUpdate,
        getModelos: getModelos,
        newModelo: newModelo,
        gatewaysUpdate: gatewaysUpdate,
        getGateways: getGateways,
        newGateway: newGateway,
        smtpUpdate: smtpUpdate,
        getSmtp: getSmtp,
        getEventsByUID : getEventsByUID,
        getEventsByDateRange: getEventsByDateRange,
        getCharts: getCharts

    };
    return service;

    function generateReport(o){

        return h.post(apiRoute + '/api/report/generate?uid='+o.uid+'&from='+o.from+'&to='+o.to+'&gatewayVersion='+o.gatewayVersion,o).then(function(resp) {
            return resp.data;
        }); 

    }

    function exportCsv(o){
        return h.post(apiRoute + '/api/logs/csv/',o).then(function(resp) {
            return resp.data;
        });
    }

    function get() {
        return h.get(apiRoute + '/api/logs/').then(function(resp) {
            return resp.data;
        });
    }

// COMERCIOS

    function comerciosUpdate(o) {
        return h.put(apiRoute + '/api/group/'+o._id,o).then(function(resp) {
            return resp.data;
        });
    }

    function getComercios() {
        return h.get(apiRoute + '/api/group/').then(function(resp) {
            return resp.data;
        });
    }

    function newComercio(o) {
        return h.post(apiRoute + '/api/group/',o).then(function(resp) {
            return resp.data;
        });
    
    }

// FABRICANTES

    function fabricantesUpdate(o) {
        return h.put(apiRoute + '/api/manufacturer/'+o._id,o).then(function(resp) {
            return resp.data;
        });
    }

    function getFabricantes() {
        return h.get(apiRoute + '/api/manufacturer/').then(function(resp) {
            return resp.data;
        });
    }

    function newFabricante(o) {
        return h.post(apiRoute + '/api/manufacturer/',o).then(function(resp) {
            return resp.data;
        });
    }

// MODELOS

    function modelosUpdate(o) {
        return h.put(apiRoute + '/api/model/'+o._id,o).then(function(resp) {
            return resp.data;
        });
    }

    function getModelos() {
        return h.get(apiRoute + '/api/model/').then(function(resp) {
            return resp.data;
        });
    }

    function newModelo(o) {
        return h.post(apiRoute + '/api/model/',o).then(function(resp) {
            return resp.data;
        });
    }

// GATEWAYS

    function gatewaysUpdate(o) {
        return h.put(apiRoute + '/api/sensor/'+o._id,o).then(function(resp) {
            return resp.data;
        });
    }

    function getGateways() {
        return h.get(apiRoute + '/api/sensor/').then(function(resp) {
            return resp.data;
        });
    }

    function newGateway(o) {
        return h.post(apiRoute + '/api/sensor/',o).then(function(resp) {
            return resp.data;
        });
    }

// SERVICIOS EXTRENOS

    function servicesUpdate(o) {
        return h.put(apiRoute + '/api/service/'+o._id,o).then(function(resp) {
            return resp.data;
        });
    }

    function getServices() {
        return h.get(apiRoute + '/api/service/').then(function(resp) {
            return resp.data;
        });
    }

    function newService(o) {
        return h.post(apiRoute + '/api/service/',o).then(function(resp) {
            return resp.data;
        });
    }

// CLIENTES 

    function clientesUpdate(o) {
        return h.put(apiRoute + '/api/client/'+o._id,o).then(function(resp) {
            return resp.data;
        });
    }

    function getClientes() {
        return h.get(apiRoute + '/api/client/').then(function(resp) {
            return resp.data;
        });
    }

    function newCliente(o) {
        return h.post(apiRoute + '/api/client/',o).then(function(resp) {
            return resp.data;
        });
    }

// INSTALADORAS
       
    function instaladorasUpdate(o) {
        return h.put(apiRoute + '/api/installer/'+o._id,o).then(function(resp) {
            return resp.data;
        });
    }

    function getInstaladoras() {
        return h.get(apiRoute + '/api/installer/').then(function(resp) {
            return resp.data;
        });
    }

    function newInstaladora(o) {
        return h.post(apiRoute + '/api/installer/',o).then(function(resp) {
            return resp.data;
        });
    }

// ALARMAS

    function alarmasUpdate(o) {
        return h.put(apiRoute + '/api/notification/'+o._id,o).then(function(resp) {
            return resp.data;
        });
    }

    function getAlarmas() {
        return h.get(apiRoute + '/api/notification/').then(function(resp) {
            return resp.data;
        });
    } 
    

// SMTP

    function smtpUpdate(o) {
        return h.put(apiRoute + '/api/smtpconfig/'+o._id,o).then(function(resp) {
            return resp.data;
        });
    }

    function getSmtp() {
        return h.get(apiRoute + '/api/smtpconfig/').then(function(resp) {
            return resp.data;
        });
    }

// INFORMES

    function getEventsByUID(o) {
        return h.get(apiRoute + '/api/events/csv?uid='+o).then(function(resp) {
            return resp.data;
        });
    }

    function getEventsByDateRange(dateFrom, dateTo, idTacha) {
        return h.get(apiRoute + '/api/events/csv?from='+ dateFrom +'&to='+ dateTo +'&uid='+idTacha).then(function(resp) {
            return resp.data;
        });
    }

    function getCharts(from, to) {
        return h.get(apiRoute + '/api/config/graphics?from=' + from + '&to=' + to).then(function(resp) {
            return resp.data;
        });
    }
}