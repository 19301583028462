angular.module('app').factory('eventsService', ['$rootScope', '$http', eventsService]);

function eventsService(r, h) {
    var service = {
        getAll: getAll,
        getStatus:getStatus,
        update: update,
        create: create,
        mqttStatus: mqttStatus,
        mqttUpdate: mqttUpdate,
        exportCsv: exportCsv,
        getOne: getOne,
        getInfo: getInfo,
        getOneEvents: getOneEvents,
        getImageById: getImageById
    };
    return service;

    function exportCsv(o){
        return h.post(apiRoute + '/api/tachas/csv/',o).then(function(resp) {
            return resp.data;
        });
    }
    function getAll() {
        return h.get(apiRoute + '/api/tachas/').then(function(resp) {
            return resp.data;
        });
    }
    function getOne(o) {
        return h.get(apiRoute + '/api/events?grouped=true&uid='+o).then(function(resp) {
            return resp.data;
        });
    }
    function getOneEvents(o) {
        return h.get(apiRoute + '/api/events?uid='+o).then(function(resp) {
            return resp.data;
        });
    }
    function getStatus() {
        return h.get(apiRoute + '/api/tachas/status/').then(function(resp) {
            return resp.data;
        });
    }
    function getInfo(o) {
        return h.get(apiRoute + '/api/sensable/',o).then(function(resp) {
            return resp.data;
        });
    }
    function mqttStatus() {
        return h.get(apiRoute + '/api/tachas/mqtt').then(function(resp) {
            return resp.data;
        });
    }
    function mqttUpdate(o) {
        return h.post(apiRoute + '/api/tachas/mqtt/',o).then(function(resp) {
            return resp.data;
        });
    }
    function create(o) {
        return h.post(apiRoute + '/api/tachas/',o).then(function(resp) {
            return resp.data;
        });
    }
    function update(o) {
        return h.put(apiRoute + '/api/tachas/'+o._id,o).then(function(resp) {
            return resp.data;
        });
    }
    function getImageById(idImage) {
        return h.get(apiRoute + '/api/image/image/'+idImage ).then(function(resp) {
            return resp.data;
        }); 
    }
        
}