angular.module('app').factory('tachasService', ['$rootScope', '$http', tachasService]);

function tachasService(r, h) {
    var service = {
        getAll: getAll,
        getStatus:getStatus,
        update: update,
        create: create,
        mqttStatus: mqttStatus,
        mqttUpdate: mqttUpdate,
        exportCsv: exportCsv,
        getSensableOptions: getSensableOptions,
        getAllActiveAlarms: getAllActiveAlarms,
        getAllActiveAlarmsByClient:getAllActiveAlarmsByClient,
        getAllEvents: getAllEvents,
        getAllEventsByClient: getAllEventsByClient,
        getAllEventsDateRange: getAllEventsDateRange,
        getAllFinishedAlarms: getAllFinishedAlarms,
        getAllFinishedAlarmsByClient: getAllFinishedAlarmsByClient,        
        getAllFinishedAlarmsDateRange: getAllFinishedAlarmsDateRange,
        getAllFinishedAlarmsDateRangeByClient: getAllFinishedAlarmsDateRangeByClient,
        getImages: getImages,
        resetKwh: resetKwh,
        getImageById: getImageById,
        getAllByClient: getAllByClient
    };
    return service;

    function exportCsv(o){
        return h.post(apiRoute + '/api/tachas/csv/',o).then(function(resp) {
            return resp.data;
        });
    }
    function getAll() {
        return h.get(apiRoute + '/api/sensable/').then(function(resp) {
            return resp.data;
        });
    }
    function getAllByClient(clientId) {
        return h.get(apiRoute + '/api/sensable?client='+clientId).then(function(resp) {
            return resp.data;
        });
    }    
    function getStatus() {
        return h.get(apiRoute + '/api/tachas/status/').then(function(resp) {
            return resp.data;
        });
    }
    function mqttStatus() {
        return h.get(apiRoute + '/api/tachas/mqtt').then(function(resp) {
            return resp.data;
        });
    }
    function mqttUpdate(o) {
        return h.post(apiRoute + '/api/tachas/mqtt/',o).then(function(resp) {
            return resp.data;
        });
    }
    function create(o) {
        return h.post(apiRoute + '/api/sensable/',o).then(function(resp) {
            return resp.data;
        });
    }
    function update(o) {
        return h.put(apiRoute + '/api/sensable/'+o._id,o).then(function(resp) {
            return resp.data;
        });
    }

  //  SENSABLE OPTIONS

    function getSensableOptions() {
        return h.get(apiRoute + '/api/sensableoptions/').then(function(resp) {
            return resp.data;
        });
    }
    
  // ACTIVE ALARMS

    function getAllActiveAlarms() {
        return h.get(apiRoute + '/api/alarm/active?grouped=true').then(function(resp) {
            return resp.data;
        });
    }
    function getAllActiveAlarmsByClient(clientId) {
        return h.get(apiRoute + '/api/alarm/active?grouped=true&client='+clientId).then(function(resp) {
            return resp.data;
        });
    }

  // FINISHED ALARMS

    function getAllFinishedAlarms() {
        return h.get(apiRoute + 'api/alarm/finished?grouped=true').then(function(resp) {
            return resp.data;
        });
    }
    function getAllFinishedAlarmsByClient(clientId) {
        return h.get(apiRoute + 'api/alarm/finished?grouped=true&client='+clientId).then(function(resp) {
            return resp.data;
        });
    }    
    function getAllFinishedAlarmsDateRange(dateFrom, dateTo) {
        return h.get(apiRoute + '/api/alarm/finished?grouped=true&from='+ dateFrom +'&to='+ dateTo).then(function(resp) {
            return resp.data;
        });
    }
    function getAllFinishedAlarmsDateRangeByClient(dateFrom, dateTo, clientId) {
        return h.get(apiRoute + '/api/alarm/finished?grouped=true&from='+ dateFrom +'&to='+ dateTo + '&client='+ clientId).then(function(resp) {
            return resp.data;
        });
    }
    
  // EVENTS ALL

    function getAllEvents() {
        return h.get(apiRoute + '/api/events?grouped=true').then(function(resp) {
            return resp.data;
        });
    } 
    function getAllEventsByClient(clientId) {
        return h.get(apiRoute + '/api/events?grouped=true&client='+clientId).then(function(resp) {
            return resp.data;
        });
    }
    function getAllEventsDateRange(UID, dateFrom, dateTo) {
        return h.get(apiRoute + '/api/events?grouped=true&from='+ dateFrom +'&to='+ dateTo +'&uid='+ UID).then(function(resp) {
            return resp.data;
        });
    }      

 // IMAGE ALL

    function getImages(o) {
        return h.get(apiRoute + '/api/image/'+o.UID).then(function(resp) {
            return resp.data;
        }); 
    } 
    function getImageById(idImage) {
        return h.get(apiRoute + '/api/image/image/'+idImage ).then(function(resp) {
            return resp.data;
        }); 
    }

  // RESET KWH

    function resetKwh(o) {
        return h.post(apiRoute + '/api/sensable/resetkwh/'+o.UID).then(function(resp) {
            return resp.data;
        });
    }

}