angular.module('app').controller('mapaCtrl', ['$scope','$rootScope', 'mapaService','tachasService', '$state','$interval', mapaCtrl]);

function mapaCtrl(s,r, mapaService, tachasService, state,interval) {
	var vm = this;
  vm.list = [];
  vm.orig = [];
  vm.status = {};
  vm.statusDesc = [];
  var markers = L.markerClusterGroup();
  var options;
  var mymap;
  var group;
  vm.cant_green_status = 0;
  vm.cant_yellow_status = 0;
  vm.cant_red_status = 0;
  vm.cant_grey_status = 0;
  vm.cant_total = 0;
  vm.monitorStatus = 1;
  vm.geoReference = [];
    
  
  vm.userClient = r.user.client ? r.user.client.Name : "Admin";
 

  if (vm.userClient != "Admin") {
    vm.userClientId = r.user.client._id;

  } else { }
  
  function getTachaPopup(t){

    // var html = "<b>Alias: "+t.Alias+"</b>";
    //   // html += "<br>HW UID: "+t.HWUID;
    // // if(t.STATUS)
    // //   html += "<br>Estado: "+t.STATUS;
    // // if(t.FW_VERSION)
    // //   html += "<br>Firmware: "+t.FW_VERSION;
    // // if(t.ADDRESS_CALLE && t.ADDRESS_NUMERACION)
    // //   html += "<br>"+t.ADDRESS_CALLE+" "+t.ADDRESS_NUMERACION;
    // // if(t.ADDRESS_NOMBRE)
    // //   html += "<br>"+t.ADDRESS_NOMBRE;
    // // if(t.ID_SLOT)
    // //   html += "<br>Slot: "+t.ID_SLOT;
    // return html;

  }

  var LeafIcon = L.Icon.extend({
    options: {
        shadowUrl: '../css/images/leaf-shadow.png',
        shadowSize:   [0, 0],
        // shadowAnchor: [4, 62]
    }
  });

  var greenIcon = new LeafIcon({iconUrl: '../css/images/marker-icon-verde.png'}),
      yellowIcon = new LeafIcon({iconUrl: '../css/images/marker-icon-amarillo.png'}),
      redIcon = new LeafIcon({iconUrl: '../css/images/marker-icon-rojo.png'});
      greyIcon = new LeafIcon({iconUrl: '../css/images/marker-icon-gris.png'});

  mapaService.getBounds().then(function(mapconfig){

    if (vm.userClient != "Admin") {
      vm.userClientId = r.user.client._id;
      vm.geoReference.push(parseFloat(r.user.client.geoReference.split(',')[0]));
      vm.geoReference.push(parseFloat(r.user.client.geoReference.split(',')[1]));
  
    } else {
      vm.geoReference.push(-34.63525);
      vm.geoReference.push(-58.37621333333333);    
    }

    options = {
      attributionControl: false,
      layers: [
        L.tileLayer(mapconfig.tile_server, { maxZoom: 17, minZoom: 11, attribution: '...' }),
    ],
    zoom: 12,
    setView: true,
    zoomControl: true,
    // bounds: mapconfig.bounds[0],
    // maxBounds : mapconfig.bounds[0],
    // maxBounds: [
    //     //south west
    //     [-34.460112, -58.767274],
    //     //north east
    //     [-34.754567, -58.235000]
    //   ],
    center: L.latLng(vm.geoReference)
  }; 
  
  mymap = L.map('mapid', options);
  group = L.featureGroup(this.markers);
  
});

vm.markerGroup = [];
vm.isAlarm = function(event) {
  var alarm_status = ['Sistema en Alarma', 'Bateria baja', 'Energía desconectada'];
  return (alarm_status.indexOf(event) > -1);
}

function cssColorNotificationStatus(nameAlarm, status) {
  if (status.value != "none") {
    if ( status.notificationStatus == 0) {
      $('#span_'+nameAlarm).css('color','#28a745');
    } else if (status.notificationStatus == 1) {
      $('#span_'+nameAlarm).css('color','#ffc107');
    } else if (status.notificationStatus == 2) {
      $('#span_'+nameAlarm).css('color','#dc3545');
    }
  } else  {}  
}

function getBatteryStatusName (status){
  switch (status) {
    case "0":
      return "Sin Alimentación";
    case "1":
      return "Cargada";
    case "2":
      return "Cargando";
    case "3":
      return "Ausente";
    case "4":
      return "Error";
    default:
      return "-";
  }
  }

function modalStatus(item) {
  	
    vm.mode = 'view'; 

    $('#newAlias').empty().text(item.Alias ? item.Alias : "");
    $('#newGroupName').empty().text(item.group.Name ? item.group.Name : "");
    $('#newGroupAddress').empty().text(item.group.Address ? item.group.Address : "");
    $('#newClientName').empty().text(item.client.Name ? item.client.Name : "");
    $('#newSerialNumber').empty().text(item.SerialNumber ? item.SerialNumber : "");
    $('#newModelId').empty().text(item.model.ID ? item.model.ID : "");
    $('#newManufacturerName').empty().text(item.model.manufacturer.name ? item.model.manufacturer.name : "");
    $('#newManufacturingDate').empty().text(item.ManufacturingDate ? item.ManufacturingDate : "");
    $('#newInstalationDate').empty().text(item.InstalationDate ? item.InstalationDate : "");

    $('#newUID').empty().text(item.UID ? item.UID : "");

    if (item.sensor) {
      $('#newCarrier').empty().text(item.sensor.Carrier ? item.sensor.Carrier : "");
      $('#newNumberSimCard').empty().text(item.sensor.NumberSimCard ? item.sensor.NumberSimCard : "");
      $('#newIMEI').empty().text(item.sensor.IMEI ? item.sensor.IMEI : "");
      $('#newFirmwareVersion').empty().text(item.sensor.FirmwareVersion ? item.sensor.FirmwareVersion : "");
      $('#newGatewayVersion').empty().text(item.sensor.GatewayVersion ? item.sensor.GatewayVersion : "");
      $('#newHardwareVersion').empty().text(item.sensor.HardwareVersion ? item.sensor.HardwareVersion : "");
      $('#newSensorSerialNumber').empty().text(item.sensor.SerialNumber ? item.sensor.SerialNumber : "");
    } else {
      $('#newCarrier').empty();
      $('#newNumberSimCard').empty();
      $('#newIMEI').empty();
      $('#newFirmwareVersion').empty();
      $('#newGatewayVersion').empty();
      $('#newHardwareVersion').empty();
      $('#newSensorSerialNumber').empty();
    }

    $('#newInstalador').empty().text(item.Instalador ? item.Instalador : "");
   
    // Main status
		$('#span_mainStatus').css('color','&#x25C9');
    if (item.status.LastCommunication.value != "") {
      if ( item.status.mainStatus == 0) {        
        $('#span_mainStatus').css('color','#28a745');
      } else if (item.status.mainStatus == 1) {
        $('#span_mainStatus').css('color','#ffc107');
      } else if (item.status.mainStatus == 2) {
        $('#span_mainStatus').css('color','#dc3545');
      }
    } else {}

    // Last Communication
    $('#spanUpdate').empty();
    if (item.status.LastCommunication.value != "") {
      $('#spanUpdate').text(item.status.dt_update);
    } else {}    

    // Power Supply
		$('#span_PowerSupply').css('color','&#x25C9');
    if (item.status.PowerSupply.value == 0) {
      $('#text_PowerSupply').text("OFF");
    } else if (item.status.PowerSupply.value == 1) {
      $('#text_PowerSupply').text("ON");
    } else {
      $('#text_PowerSupply').text("-");
    }

    // Battery
		$('#span_Battery').css('color','&#x25C9');
    if (item.status.Battery.value == 'none') { 
      $('#text_Battery').text("-");
    } else {
      getBatteryStatusName(item.status.Battery.value);
    }        
                                
    // Temperature    
    $('#span_Temperature').css('color','&#x25C9');
    if (item.status.Temperature.value == "NaN") {
      $('#text_Temperature').text("Desconectado");
    } else if (item.status.Temperature.value == "none"){ 
      $('#text_Temperature').text("-");
    } else {
      $('#text_Temperature').text(""+ item.status.Temperature.value + " °C");
    }

    // Current		
		$('#span_Current').css('color','&#x25C9');
    if (item.status.Current.value == "none") {
      $('#text_Current').text("-");
    } else {
      $('#text_Current').text(""+ item.status.Current.value + " A");
    }                                   

    // Voltage
		$('#span_Voltage').css('color','&#x25C9');
    if (item.status.Voltage.value == "none") {
      $('#text_Voltage').text("-");
    } else {
      $('#text_Voltage').text(""+ item.status.Voltage.value + " V");
    } 

    // Active power 
		$('#span_ActivePower').css('color','&#x25C9');
    if (item.status.ActivePower.value == "none") {
      $('#text_ActivePower').text("-");
    } else {
      $('#text_ActivePower').text(""+ item.status.ActivePower.value + " W");
    } 

    // Aparent Power
		$('#span_AparentPower').css('color','&#x25C9');
    if (item.status.AparentPower.value == "none") {
      $('#text_AparentPower').text("-");
    } else {
      $('#text_AparentPower').text(""+ item.status.AparentPower.value + " VA");
    } 

    // Reactive Power
		$('#span_ReactivePower').css('color','&#x25C9');
    if (item.status.ReactivePower.value == "none") {
      $('#text_ReactivePower').text("-");
    } else {
      $('#text_ReactivePower').text(""+ item.status.ReactivePower.value + " VAR");
    }

    // CosPhi
		$('#span_CosPhi').css('color','&#x25C9');
    if (item.status.CosPhi.value == "none") {
      $('#text_CosPhi').text("-");
    } else {
      $('#text_CosPhi').text(""+item.status.CosPhi.value+"");
    }

    // Kwh
		$('#span_Kwh').css('color','&#x25C9');
    if (item.status.Kwh.value == "none") {
      $('#text_Kwh').text("-");
    } else {
      $('#text_Kwh').text(""+ item.status.Kwh.value + " Kwh");
    }

    // Kwh Amount
		$('#span_KwhAmount').css('color','&#x25C9');
    if (item.status.KwhAmount.value == "none") {
      $('#text_KwhAmount').text("-");
    } else {
      $('#text_KwhAmount').text("$ "+ item.status.KwhAmount.value+"");
    }

    // Door state
		$('#span_DoorState').css('color','&#x25C9');
    if (item.status.DoorState.last_alarm_value == "none") {
      $('#text_DoorState').text("-");
    } else {

      $('#text_DoorState').text(""+item.status.DoorState.value+"");
    }

    // Door open count
		$('#span_DoorOpenCount').css('color','&#x25C9');
    if (item.sensor) {
      if (item.sensor.GatewayVersion == 'Basic') {        
        $('#text_DoorOpenCount').text("-");
      } else {
        if (item.status.DoorOpenCount.value == "none") {
          $('#text_DoorOpenCount').text("-");
        } else {
          $('#text_DoorOpenCount').text(""+item.status.DoorOpenCount.value+"");
        }
        cssColorNotificationStatus("DoorOpenCount", item.status.DoorOpenCount);
      }
    } else {
      $('#text_DoorOpenCount').text("-");
    }

    

    // Door open time
		$('#span_DoorOpenTime').css('color','&#x25C9');
    if (item.status.DoorOpenTime.value == "none") {
      $('#text_DoorOpenTime').text("-");
    } else {
      $('#text_DoorOpenTime').text(""+ item.status.DoorOpenTime.value + " seg");
      cssColorNotificationStatus("DoorState", item.status.DoorState);
    }

    // GSM Signal
		$('#span_GSM_Signal').css('color','&#x25C9');
    if (item.status.GSM_Signal.value == "none") {
      $('#text_GSM_Signal').text("-");
    } else {
      $('#text_GSM_Signal').text(""+ item.status.GSM_Signal.value + " dBm");
    }

    // Ant disconnect
    $('#span_Ant_Disconnect').css('color','&#x25C9');
    if (item.status.Ant_Disconnect.value == "none") {
      $('#text_Ant_Disconnect').text("-");
    } else {
      if (item.status.Ant_Disconnect.notificationStatus == 0) {
        $('#text_Ant_Disconnect').text("Normal");
      } else if (item.status.Ant_Disconnect.notificationStatus == 1) {
        $('#text_Ant_Disconnect').text("Alarma");
      } else {}
    }
    
    // Geocerca		
		$('#span_Geocerca').css('color','&#x25C9');
    if (item.sensor) {
      if (item.sensor.GatewayVersion == 'Basic') {        
        $('#text_Geocerca').text("-");
      } else {
        cssColorNotificationStatus("Geocerca", item.status.GPS);
        if (item.status.GPS.notificationStatus == 0) {
          $('#text_Geocerca').text("Normal");
        } else if (item.status.GPS.notificationStatus == 1) {
          $('#text_Geocerca').text("Alarma");
        } else {}
      }
    } else {
      $('#text_Geocerca').text("-");
    }

    // Movement
		$('#span_Movement').css('color','&#x25C9');
    if (item.status.Movement.value == "none") {
      $('#text_Movement').text("-");
    } else {
      if (item.status.Movement.value == 1) {
        $('#text_Movement').text("Normal");
      } else if (item.status.Movement.value == 2) {
        $('#text_Movement').text("Inclinada");
      } else if (item.status.Movement.value == 3) {
        $('#text_Movement').text("Acostada");
      } else {}
    }    

    // Prog Command
		$('#span_ProgCommand').css('color','&#x25C9');
    if (item.status.ProgCommand.value == "none") {
      $('#text_ProgCommand').text("-");
    } else {
      $('#text_ProgCommand').text(""+item.status.ProgCommand.value+"");
    }

    // Tampers Int
		$('#span_TampersInt').css('color','&#x25C9');
    if (item.sensor) {
      if (item.sensor.GatewayVersion == 'Basic') {        
        $('#text_TampersInt').text("-");
      } else {
        cssColorNotificationStatus("TampersInt", item.status.TampersInt);
        if (item.status.TampersInt.notificationStatus == 0) {
          $('#text_TampersInt').text("Alarma");
        } else if (item.status.TampersInt.notificationStatus == 1) {
          $('#text_TampersInt').text("Normal");
        } else {}
      }
    } else {
      $('#text_TampersInt').text("-");
    }

    
    
    // Tampers Ext
		$('#span_TampersExt').css('color','&#x25C9');
    if (item.sensor) {
      if (item.sensor.GatewayVersion == 'Basic') {        
        $('#text_TampersExt').text("-");
      } else {
        cssColorNotificationStatus("TampersExt", item.status.TampersExt);
        if (item.status.TampersExt.notificationStatus == 0) {
          $('#text_TampersExt').text("Alarma");
        } else if (item.status.TampersExt.notificationStatus == 1) {
          $('#text_TampersExt').text("Normal");
        } else {}
      }
    } else {
      $('#text_TampersExt').text("-");
    }
   
    // Tampers Controller
		$('#span_TampersController').css('color','&#x25C9');
    if (item.sensor) {
      if (item.sensor.GatewayVersion == 'Basic') {        
        $('#text_TampersController').text("-");
      } else {
        cssColorNotificationStatus("TampersController", item.status.TampersController);
        if (item.status.TampersController.notificationStatus == 1) {
          $('#text_TampersController').text("Alarma");
        } else if (item.status.TampersController.notificationStatus == 0) {
          $('#text_TampersController').text("Normal")
        } else {}
      }
    } else {
      $('#text_TampersController').text("-");
    }
       
			cssColorNotificationStatus("PowerSupply", item.status.PowerSupply);
      cssColorNotificationStatus("Temperature", item.status.Temperature);
      cssColorNotificationStatus("Battery", item.status.Battery);			      
      cssColorNotificationStatus("Current", item.status.Current);
      cssColorNotificationStatus("Voltage", item.status.Voltage);
      cssColorNotificationStatus("ActivePower", item.status.ActivePower);
      cssColorNotificationStatus("AparentPower", item.status.AparentPower);
      cssColorNotificationStatus("ReactivePower", item.status.ReactivePower);
      cssColorNotificationStatus("CosPhi", item.status.CosPhi);
      cssColorNotificationStatus("Kwh", item.status.Kwh);
      cssColorNotificationStatus("KwhAmount", item.status.KwhAmount);
      cssColorNotificationStatus("DoorOpenTime", item.status.DoorOpenTime);
      cssColorNotificationStatus("GSM_Signal", item.status.GSM_Signal);
      cssColorNotificationStatus("Ant_Disconnect", item.status.Ant_Disconnect);
      cssColorNotificationStatus("Movement", item.status.Movement);
      cssColorNotificationStatus("ProgCommand", item.status.ProgCommand);

	  // HELADERA DESACTIVADA
		if ( item.active == false) {
			$('#span_mainStatus').css('color','#ccc');
			$('#span_estado').removeClass('hidden');
		} else {
			$('#span_estado').addClass('hidden');
		}
		
  $('#general_status_map').modal();

}


vm.getAll = function() { 
   if (vm.userClient == "Admin") {
    tachasService.getAll().then(function(data) {

      vm.cant_total = data.length;

      vm.list = data.map(function(t){

        if(t.active == false){
          vm.cant_grey_status++
          
        } else {
          console.log(t.status.LastCommunication.value)
                if (t.status.mainStatus == 0 && t.status.LastCommunication.value != '') {
                  vm.cant_green_status++
                } else if (t.status.mainStatus == 1) {
                  vm.cant_yellow_status++
                } else if (t.status.mainStatus == 2) {
                  vm.cant_red_status++
                } else {}
        }

        if (t.GPS.lat != undefined) {
          if(t.active == false){
            var marker = L.marker(L.latLng(
              t.GPS.lat, t.GPS.lon),{
                title:t.Alias,
                data:t ,
                icon: greyIcon        
              }).on('click', function(e) {
                modalStatus(t);
              });
              // marker.bindPopup(modalStatus(t));
              markers.addLayer(marker);
              vm.markerGroup.push(marker);
          } else {
            if (t.status.mainStatus == 0) {
              var iconStatus = {};
              if (t.status.LastCommunication.value.length != 0) {
                iconStatus.value = greenIcon;
              } else {
                iconStatus.value = greyIcon;
              }
              var marker = L.marker(L.latLng(
                t.GPS.lat, t.GPS.lon),{
                  title:t.Alias,
                  data:t ,
                  icon: iconStatus.value        
                }).on('click', function(e) {
                  modalStatus(t);
                });
                // marker.bindPopup(modalStatus(t));
                markers.addLayer(marker);
                vm.markerGroup.push(marker);            
            } else if (t.status.mainStatus == 1) {
              var marker = L.marker(L.latLng(
                t.GPS.lat, t.GPS.lon),{
                  title:t.Alias,
                  data:t,
                  icon: yellowIcon         
                }).on('click', function(e) {
                  modalStatus(t);
                });
                // marker.bindPopup(getTachaPopup(t));
                markers.addLayer(marker);
                vm.markerGroup.push(marker);
            } else if (t.status.mainStatus == 2) {
              var marker = L.marker(L.latLng(
                t.GPS.lat, t.GPS.lon),{
                  title:t.Alias,
                  data:t,
                  icon: redIcon         
                }).on('click', function(e) {
                    modalStatus(t);
                  });
                // marker.bindPopup(getTachaPopup(t));
                markers.addLayer(marker);
                vm.markerGroup.push(marker);     
            } else {}

          }
          

        } else {}
          $('#divContainerMap').css('visibility','visible');
        });

        mymap.addLayer(markers);
        group = new L.featureGroup(vm.markerGroup);
        vm.orig = angular.copy(vm.list);

    }); 
  } else { 
    tachasService.getAllByClient(vm.userClientId).then(function(data) {

      vm.cant_total = data.length;

      vm.list = data.map(function(t){

        if (t.status.mainStatus == 0) {
          vm.cant_green_status++
        } else if (t.status.mainStatus == 1) {
          vm.cant_yellow_status++
        } else if (t.status.mainStatus == 2) {
          vm.cant_red_status++
        } else {}

        if (t.GPS.lat != undefined) {
          if (t.status.mainStatus == 0) {
            var marker = L.marker(L.latLng(
              t.GPS.lat, t.GPS.lon),{
                title:t.Alias,
                data:t ,
                icon: greenIcon        
              }).on('click', function(e) {
                modalStatus(t);
              });
              // marker.bindPopup(modalStatus(t));
              markers.addLayer(marker);
              vm.markerGroup.push(marker);            
          } else if (t.status.mainStatus == 1) {
            var marker = L.marker(L.latLng(
              t.GPS.lat, t.GPS.lon),{
                title:t.Alias,
                data:t,
                icon: yellowIcon         
              }).on('click', function(e) {
                modalStatus(t);
              });
              // marker.bindPopup(getTachaPopup(t));
              markers.addLayer(marker);
              vm.markerGroup.push(marker);
          } else if (t.status.mainStatus == 2) {
            var marker = L.marker(L.latLng(
              t.GPS.lat, t.GPS.lon),{
                title:t.Alias,
                data:t,
                icon: redIcon         
              }).on('click', function(e) {
                  modalStatus(t);
                });
              // marker.bindPopup(getTachaPopup(t));
              markers.addLayer(marker);
              vm.markerGroup.push(marker);     
          } else {}

        } else {}
          $('#divContainerMap').css('visibility','visible');
        });

        mymap.addLayer(markers);
        group = new L.featureGroup(vm.markerGroup);
        vm.orig = angular.copy(vm.list);

    }); 
    
  } 
    
  tachasService.getStatus().then(function(data){
    vm.status = data;
    vm.statusDesc = Object.keys(data);
  });
} 
  vm.modaltacha = function(e){
    vm.tachadetail = getTachaPopup(e.target.options.data);
    s.$digest();
    $('#detail').modal();
  }

  vm.getAll();
  // r.intervalRefresh = interval(vm.getAll,900000);
  interval.cancel(r.intervalRefresh);
}
