angular.module('app').controller('logsCtrl', ['$rootScope', 'tachasService','usuariosService', '$sce', '$interval', 'logsService', 'growl', '$state', '$filter', 'blockUI', logsCtrl]);

function logsCtrl(r,tachasService, usuariosService, sce, interval, logsService, growl, state, blockUI, f) {
	var vm = this;
	vm.new = {};
	vm.list = [];
	vm.orig = [];
	vm.selected = {};
	vm.tableConfig = { "itemsPerPage": 10, "maxPages": 5 };
	vm.filterText = '';	
	vm.graph_dateFrom;
	vm.graph_dateTo;
	vm.graph_item;
	vm.userRol = state.current.data.requiresRole;
	
	vm.userClient = r.user.client ? r.user.client.Name : "Admin";
    if (vm.userClient != "Admin") {
		vm.userClientId = r.user.client._id;
	}

	vm.filtrarTabla = function () {
		vm.list = f("filter")(vm.orig, vm.filterText);
	};

	vm.export = function () {
		logsService.exportCsv(vm.list).then(function (data) {
			r.downloadCSV(data, 'logs');
		});
	}

	vm.getAll = function () {
		logsService.getAll().then(function (data) {
			vm.list = data;
			vm.orig = angular.copy(vm.list);
		});
	}

	vm.getComercios = function () {
		logsService.getComercios().then(function (data) {
			vm.comercios_list = data;
			vm.orig = angular.copy(vm.comercios_list);
		});
	}

function validateInput(modal) {
	var inputVacios = 0;
		$('#'+modal+' input').each(function(index) { 
			$('#'+modal+' span:eq('+ (index+1) +')').addClass('hidden');			
			$('#'+modal+' input:eq('+ index +')').removeClass('inputVacio inputLleno');
			if ($('#'+modal+' input:eq('+ index +')').val() == "" ) {
				$('#'+modal+' input:eq('+ index +')').addClass('inputVacio').change(function() {
					if ($('#'+modal+' input:eq('+ index +')').val() == "" ) {
						$('#'+modal+' input:eq('+ index +')').removeClass('inputLleno').addClass('inputVacio');
						$('#'+modal+' span:eq('+ (index+1) +')').removeClass('hidden');		
					} else {
						$('#'+modal+' input:eq('+ index +')').removeClass('inputVacio').addClass('inputLleno');
						$('#'+modal+' span:eq('+ (index+1) +')').addClass('hidden');
					}					
				  });
				$('#'+modal+' span:eq('+ (index+1) +')').removeClass('hidden');
				inputVacios++
			} else {}

		});
	return inputVacios;
}

function clearValidation(modal) {
	$('#'+modal+' input').each(function(index) { 
		$('#'+modal+' span:eq('+ (index+1) +')').addClass('hidden');			
		$('#'+modal+' input:eq('+ index +')').removeClass('inputVacio inputLleno');
	});
}

  /*********************************** COMERCIOS CONTROLLERS *****************************************/

	vm.comercios_list = [];
	vm.comercios_orig = [];

	vm.getComercios = function () {
		logsService.getComercios().then(function (data) {
			vm.comercios_list = data;
			vm.comercios_orig = angular.copy(vm.comercios_list);
		});
	}

	vm.newComercio = function () {
		vm.new = {};
		vm.mode = 'new';
		clearValidation("modalComercios");		
		$('#editComercio').modal();
	};

	vm.editComercio = function (item) {
		vm.new = angular.copy(item);
		vm.mode = 'edit';
		clearValidation("modalComercios");
		$('#editComercio').modal();
	};

	vm.comerciosUpdate = function () {
		var inputVacios = validateInput("modalComercios");		
		if (inputVacios === 0) {
			var obj = angular.copy(vm.new);
			if (obj._id)
				logsService.comerciosUpdate(obj).then(function () {
					vm.new = {};
					vm.getComercios();
					$('.modal').modal('hide');
					growl.success('Se actualizo la locación exitosamente.');
				});
			else {
				logsService.newComercio(obj).then(function () {
					vm.new = {};
					vm.getComercios();
					$('.modal').modal('hide');
					growl.success('Se creo la locación exitosamente.');
				});
			}
		} else {}
	};

	/*********************************** EXPORTAR TABLAS DE AJUSTES A CSV *****************************************/

	vm.exportTabToCSV = function(tab_name){
		var header = '';
		var properties = '';
		var list = ''
		var name_file = ''
		switch(tab_name){
			case 'comercios':
				header = ['Nombre', 'Dirección', 'Teléfono', 'Email', 'CUIT', 'Costo Kw/h', 'Distribuidora']
				properties = [
					['Name'],				
					['Address'],				
					['Phone'],				
					['Email'],			
					['CUIT'],	
					['kWhCost'],		
					['Distributor']
				]
				list = 'comercios_list'
				name_file='locaciones'
				break;
			case 'clientes':
				header = ['Nombre', 'Descripción', 'Geo referencia']
				properties = [
					['Name'],				
					['descripcion'],
					['geoReference']							
				]
				list = 'clientes_list'
				name_file='clientes'
				break;
			case 'usuarios':
				header = ['Email', 'Nombre', 'Apellido', 'Cliente', 'Activa']
				properties = [
					['email'],				
					['nombre'],				
					['apellido'],	
					['client', 'Name'],
					['toggleActive']		
				]
				list = 'usuarios_list'
				name_file='usuarios'
				break;	
			case 'fabricantes':
				header = ['Nombre', 'Descripción']
				properties = [
					['name'],				
					['descripcion'],					
				]
				list = 'fabricantes_list'
				name_file='fabricantes'
				break;	
			case "modelos":
				header = ['Nombre', 'Capacidad', 'Refrigerante', 'Clase climática', 'Recupero de temperatura(min)', 'Fabricante']
				properties = [
					['ID'],				
					['volumen_bruto'],	
					['refrigerante'],	
					['clase_cimatica'],
					['recover_time'],
					['manufacturer', 'name'],			
				]
				list = 'modelos_list'
				name_file='modelos'
				break;
			case 'gateways':
				header = ['N. de serie', 'Carrier', 'Tel.(sin card)', 'Gateway versión', 'Hardware versión', 'Firmware versión', 'IMEI', 'Hardware UID', 'Heladera Alias']
				properties = [
					['SerialNumber'],				
					['Carrier'],	
					['NumberSimCard'],	
					['GatewayVersion'],
					['HardwareVersion'],
					['FirmwareVersion'],
					['IMEI'],	
					['sensable', 'UID'],
					['sensable', 'Alias'],
				]
				list = 'gateways_list'
				name_file='gateways'
				break;
			case 'servicios_externos':
				header = ['Razón social', 'Teléfono', 'Email', 'Descripción']
				properties = [
					['Name'],				
					['Telephone'],	
					['Email'],	
					['Description']
				]
				list = 'service_list'
				name_file='servicios_externos'
				break;
			case 'instaladores':
				header = ['Razón social', 'Teléfono', 'Email', 'Descripción']
				properties = [
					['Name'],				
					['Telephone'],	
					['Email'],	
					['Description']
				]
				list = 'instaladoras_list'
				name_file='instaladores'
				break;
		}
		
		var list = r.get_values_of_list_generic(vm[list], properties)
		
		if(list[0]["toggleActive"]!== undefined )
			r.changeBooleanToActiveOrInactive(list, "toggleActive")

		const listCSV = r.jsonToCSV(header, list);

		r.downloadCSV(listCSV, name_file)
	}


 /*********************************** FABRICANTES CONTROLLERS *****************************************/

	vm.fabricantes_list = [];
	vm.fabricantes_orig = [];

	vm.getFabricantes = function () {
		logsService.getFabricantes().then(function (data) {
			vm.fabricantes_list = data;
			vm.fabricantes_orig = angular.copy(vm.fabricantes_list);
		});
	}

	vm.newFabricante = function () {
		vm.new = {};
		vm.mode = 'new';
		clearValidation("modalFabricantes");		
		$('#editFabricante').modal();
	};

	vm.editFabricante = function (item) {
		vm.new = angular.copy(item);
		vm.mode = 'edit';
		clearValidation("modalFabricantes");
		$('#editFabricante').modal();
	};

	vm.fabricantesUpdate = function () {
		var inputVacios = validateInput("modalFabricantes");
		if (inputVacios === 0) {
			var obj = angular.copy(vm.new);
			if (obj._id)
				logsService.fabricantesUpdate(obj).then(function () {
					vm.new = {};
					vm.getFabricantes();
					$('.modal').modal('hide');
					growl.success('Se actualizo el fabricante exitosamente.');
				});
			else {
				logsService.newFabricante(obj).then(function () {
					vm.new = {};
					vm.getFabricantes();
					$('.modal').modal('hide');
					growl.success('Se creo el fabricante exitosamente.');
				});
			}
		} else {}
	};

	vm.filtrarTablaFabricantes = function () {
		vm.fabricantes_list = f("filter")(vm.fabricantes_orig, vm.filterText);
	};

	/*********************************** USUARIO CONTROLLERS *****************************************/

	vm.usuarios_list = [];
	vm.usuarios_orig = [];

    vm.export = function() {
		usuariosService.exportCsv(vm.usuarios_list).then(function(data){
			r.downloadCSV(data,'usuarios');
		});
	}
	vm.getInitUser = function() {
		usuariosService.getAllRoles().then(function(data) {
			vm.listRoles = data;
		});

		usuariosService.getAllClients().then(function(data) {
			vm.listClients = data;
		});
	}

	vm.getInitUser();

    vm.getUsuarios = function() {

        if (vm.userClient == "Admin") {
            usuariosService.getAll().then(function(data) {
                vm.usuarios_list = data.map(function(user,i){ 
                                  
                    user.toggleActive = (user.activo == 1);                
                    return user;
                });
                vm.usuarios_orig = angular.copy(vm.usuarios_list);
    
            });
        } else {            
            usuariosService.getAll().then(function(data) {

                var dataFilter = [];

                for (var i = 0; i < data.length; i++) {                    
                    if (data[i].client && data[i].client.Name == vm.userClient) {
                        dataFilter.push(data[i]);
                    } else {}                    
                }

                vm.usuarios_list = dataFilter.map(function(user,i){                                   
                    user.toggleActive = (user.activo == 1);                
                    return user;
                });             

                vm.usuarios_orig = angular.copy(vm.usuarios_list);
    
            });
        }        
    }

    vm.openModal = function(){
        vm.isEdit = false;
        vm.new = {};
        $('#newUser').modal();
    }
    
    vm.revoke = function(item) {
        swal({
            title: 'Seguro que desea revocar los tokens al usuario?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar'
        }).then(function() {
            usuariosService.revoke(vm.new).then(function() {
                vm.getUsuarios();
                swal({
                    title: 'Los tokens han sido revocados!',
                    type: 'success',
                    confirmButtonText: 'Aceptar'
                });
            });
        }).catch(swal.noop);
    }

    vm.remove = function(item) {
        vm.selected = item;
        swal({
            title: 'Seguro que desea eliminar al usuario?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar'
        }).then(function() {
            usuariosService.remove(vm.selected).then(function() {
                vm.getUsuarios();
                swal({
                    title: 'El usuario ha sido eliminado!',
                    type: 'success',
                    confirmButtonText: 'Aceptar'
                });
            });
        }).catch(swal.noop);
    }

    vm.edit = function(item) {
        vm.isEdit = true;
        vm.new = angular.copy(item);
        vm.new.password = ''; 	
        $('#newUser').modal();      
    }

    vm.toggleActive = function(item){
        item.activo = item.toggleActive ? 1:0;
        usuariosService.update(item).then(function() {
            growl.success(item.toggleActive ? 'Se activo el usuario.':'Se desactivo el usuario.');
        })
    }

    vm.save = function() {
        var obj = angular.copy(vm.new);         
                      
            if(obj.rol && obj.rol != ""){
                if (!obj._id) {                   
                    usuariosService.create(obj).then(function() {
                        vm.new = {};
                        vm.getUsuarios();
                        $('#newUser').modal('hide');
                        growl.success('Se creo el usuario exitosamente.');
                    })
                }else{
                    usuariosService.update(obj).then(function() {
                        vm.new = {};
                        vm.getUsuarios();
                        $('.modal').modal('hide');
                        growl.success('Se actualizo el usuario exitosamente.');
                    })
                }
            } else {
                growl.error('Seleccione un rol.');
            }
       
        
    }

  /*********************************** MODELOS CONTROLLERS *****************************************/

	vm.modelos_list = [];
	vm.modelos_orig = [];

	vm.getModelos = function () {
		logsService.getModelos().then(function (data) {
			vm.modelos_list = data;
			vm.modelos_orig = angular.copy(vm.modelos_list);
		});
	}

	vm.newModelo = function () {
		vm.new = {};
		vm.mode = 'new';
		
		$('#select_edit_fabricantes_modelos').empty();

		tachasService.getSensableOptions().then(function(data) {			

			data.manufacturers.forEach(function(el) {
				$('#select_edit_fabricantes_modelos').append("<option value="+el._id+">"+el.name+"</option>");
			});

		});
		clearValidation("modalModelos");
		$('#editModelo').modal();
	};

	vm.editModelo = function (item) {
		vm.new = angular.copy(item);
		vm.mode = 'edit';

		$('#select_edit_fabricantes_modelos').empty();

		tachasService.getSensableOptions().then(function(data) {			

			data.manufacturers.forEach(function(el) {
				$('#select_edit_fabricantes_modelos').append("<option value="+el._id+">"+el.name+"</option>");
			});

		});
		clearValidation("modalModelos");
		$('#editModelo').modal();
	};

	vm.modelosUpdate = function () {
		var inputVacios = validateInput("modalModelos");
		if (inputVacios === 0) {			
			if ($('#select_edit_fabricantes_modelos').val() != null || $('#select_edit_fabricantes_modelos').val() != undefined) {
				var obj = angular.copy(vm.new);
				obj.manufacturer = $('#select_edit_fabricantes_modelos').val();
				if (obj._id)
					logsService.modelosUpdate(obj).then(function () {
						vm.new = {};
						vm.getModelos();
						$('.modal').modal('hide');
						growl.success('Se actualizo el modelo exitosamente.');
					});
				else {
					
					logsService.newModelo(obj).then(function () {
						vm.new = {};
						vm.getModelos();
						$('.modal').modal('hide');
						growl.success('Se creo el modelo exitosamente.');
					});
				}
			} else {				
				$('#select_edit_fabricantes_modelos').addClass('inputVacio').change(function() {
					if ($('#select_edit_fabricantes_modelos').val() == "" ) {
						$('#select_edit_fabricantes_modelos').removeClass('inputLleno').addClass('inputVacio');
						$('#span_marca_modelo').removeClass('hidden');		
					} else {
						$('#select_edit_fabricantes_modelos').removeClass('inputVacio').addClass('inputLleno');
						$('#span_marca_modelo').addClass('hidden');
					}					
				  });
				$('#span_marca_modelo').removeClass('hidden');
			}
		} else {}
	};

	vm.filtrarTablaModelos = function () {
		vm.modelos_list = f("filter")(vm.modelos_orig, vm.filterText);
	};

  /*********************************** GATEWAYS CONTROLLERS *****************************************/

	vm.gateways_list = [];
	vm.gateways_orig = [];

	vm.getGateways = function () {
		logsService.getGateways().then(function (data) {
			vm.gateways_list = data;
			vm.gateways_orig = angular.copy(vm.gateways_list);
		}); 
	}

	vm.newGateway = function () {
		vm.new = {};
		vm.mode = 'new';
		clearValidation("modalGateways");
		$('#editGateway').modal();
	};

	vm.editGateway = function (item) {
		vm.new = angular.copy(item);
		vm.mode = 'edit';
		clearValidation("modalGateways");
		$('#editGateway').modal();
	};

	vm.gatewaysUpdate = function () {
		var inputVacios = validateInput("modalGateways");
		if (inputVacios === 0) {
			var obj = angular.copy(vm.new);
			if (obj._id)
				logsService.gatewaysUpdate(obj).then(function () {
					vm.new = {};
					vm.getGateways();
					$('.modal').modal('hide');
					growl.success('Se actualizo el gateway exitosamente.');	
				});
			else {
				logsService.newGateway(obj).then(function () {
					vm.new = {};
					vm.getGateways();
					$('.modal').modal('hide');
					growl.success('Se creo el gateway exitosamente.');
				}); 
			}
		} else {}
	};

	vm.filtrarTablaGateways = function () {
		vm.gateways_list = f("filter")(vm.gateways_orig, vm.filterText);
	};

  /*********************************** SERVICOS EXTERNOS CONTROLLERS *****************************************/

	vm.service_list = [];
	vm.service_orig = [];

	vm.getServices = function () {
		logsService.getServices().then(function (data) {
			vm.service_list = data;
			vm.service_orig = angular.copy(vm.service_list);
		});
	}

	vm.newService = function () {
		vm.new = {};
		vm.mode = 'new';
		clearValidation("modalServices");
		$('#editService').modal();
	};

	vm.editService = function (item) {
		vm.new = angular.copy(item);
		vm.mode = 'edit';
		clearValidation("modalServices");
		$('#editService').modal();
	};

	vm.servicesUpdate = function () {
		var inputVacios = validateInput("modalServices");
		if (inputVacios === 0) {
			var obj = angular.copy(vm.new);
			if (obj._id)
				logsService.servicesUpdate(obj).then(function () {
					vm.new = {};
					vm.getServices();
					$('.modal').modal('hide');
					growl.success('Se actualizo el servicio externo exitosamente.');
				});
			else {
				logsService.newService(obj).then(function () {
					vm.new = {};
					vm.getServices();
					$('.modal').modal('hide');
					growl.success('Se creo el servicio externo exitosamente.');
				});
			}
		} else {}
	};

	vm.filtrarTablaService = function () {
		vm.service_list = f("filter")(vm.service_orig, vm.filterText);
	};

/*********************************** INSTALADORAS *****************************************/

	vm.instaladoras_list = [];
	vm.instaladoras_orig = [];

	vm.getInstaladoras = function () {
		logsService.getInstaladoras().then(function (data) {
			vm.instaladoras_list = data;
			vm.instaladoras_orig = angular.copy(vm.instaladoras_list);
		});
	}

	vm.newInstaladora = function () {
		vm.new = {};
		vm.mode = 'new';
		clearValidation("modalInstaladoras");
		$('#editInstaladoras').modal();
	};

	vm.editInstaladora = function (item) {
		vm.new = angular.copy(item);
		vm.mode = 'edit';
		clearValidation("modalInstaladoras");
		$('#editInstaladoras').modal();
	};

	vm.InstaladorasUpdate = function () {
		var inputVacios = validateInput("modalInstaladoras");
		if (inputVacios === 0) {
			var obj = angular.copy(vm.new);
			if (obj._id)
				logsService.instaladorasUpdate(obj).then(function () {
					vm.new = {};
					vm.getInstaladoras();
					$('.modal').modal('hide');
					growl.success('Se actualizo el servicio externo exitosamente.');
				});
			else {
				logsService.newInstaladora(obj).then(function () {
					vm.new = {};
					vm.getInstaladoras();
					$('.modal').modal('hide');
					growl.success('Se creo el servicio externo exitosamente.');
				});
			}
		} else {}
	};

	vm.filtrarTablaInstaladora = function () {
		vm.instaladoras_list = f("filter")(vm.instaladoras_orig, vm.filterText);
	};

  /*********************************** CLIENTES CONTROLLERS *****************************************/

	vm.clientes_list = [];
	vm.clientes_orig = [];

	vm.getClientes = function () {
		logsService.getClientes().then(function (data) {
			vm.clientes_list = data;
			vm.clientes_orig = angular.copy(vm.clientes_list);
		});
	}

	vm.newCliente = function () {
		vm.new = {};
		vm.mode = 'new';
		clearValidation("modalClientes");
		$('#editCliente').modal();
	};

	vm.editCliente = function (item) {
		vm.new = angular.copy(item);
		vm.mode = 'edit';
		clearValidation("modalClientes");
		$('#editCliente').modal();
	};

	vm.clientesUpdate = function () {
		var inputVacios = validateInput("modalClientes");
		if (inputVacios === 0) {
			var obj = angular.copy(vm.new);
			if (obj._id)
				logsService.clientesUpdate(obj).then(function () {
					vm.new = {};
					vm.getClientes();
					$('.modal').modal('hide');
					growl.success('Se actualizo el cliente externo exitosamente.');
				});
			else {
				logsService.newCliente(obj).then(function () {
					vm.new = {};
					vm.getClientes();
					$('.modal').modal('hide');
					growl.success('Se creo el cliente externo exitosamente.');
				});
			}
		} else {}
	};

	vm.filtrarTablaCliente = function () {
		vm.clientes_list = f("filter")(vm.clientes_orig, vm.filterText);
	};

	/*********************************** ALARMAS CONTROLLERS *****************************************/

	vm.alarmas_list = [];
	vm.alarmas_orig = [];
	vm.alarmas_obj = {};

	checkEnabledColor = function (state, idSpan) {

		if (state == true) {
			$('#'+ idSpan).css('color','green');
		} else {
			$('#'+ idSpan).css('color','red');
		} 

	}
	
	vm.getAlarmasInit = function () {
		logsService.getAlarmas().then(function (data) {
			vm.alarmas_list = data;
			vm.alarmas_orig = angular.copy(vm.alarmas_list);

		// Advertencias
			$('#span_temperature_min').text(data[0].notifications.Temperature[1].warning);
			$('#span_temperature_max').text(data[0].notifications.Temperature[0].warning);	
			checkEnabledColor(data[0].notifications.Temperature[0].enabled, "span_temperature_enabled");
			checkEnabledColor(data[0].notifications.Temperature[0].enabled_email, "span_temperature_enabled_email");
			checkEnabledColor(data[0].notifications.Temperature[0].enabled_sms, "span_temperature_enabled_sms");
			
			$('#span_current_min').text(data[0].notifications.Current[1].alert);
			$('#span_current_max').text(data[0].notifications.Current[0].alert);			
			checkEnabledColor(data[0].notifications.Current[0].enabled, "span_current_enabled");
			checkEnabledColor(data[0].notifications.Current[0].enabled_email, "span_current_enabled_email");
			checkEnabledColor(data[0].notifications.Current[0].enabled_sms, "span_current_enabled_sms");
			
			checkEnabledColor(data[0].notifications.PowerSupply[0].enabled, "span_ausencia_enabled");
			checkEnabledColor(data[0].notifications.PowerSupply[0].enabled_email, "span_ausencia_enabled_email");
			checkEnabledColor(data[0].notifications.PowerSupply[0].enabled_sms, "span_ausencia_enabled_sms");

			$('#span_potenciaActiva_min').text(data[0].notifications.ActivePower[1].warning);
			$('#span_potenciaActiva_max').text(data[0].notifications.ActivePower[0].warning);			
			checkEnabledColor(data[0].notifications.ActivePower[0].enabled, "span_potenciaActiva_enabled");
			checkEnabledColor(data[0].notifications.ActivePower[0].enabled_email, "span_potenciaActiva_enabled_email");
			checkEnabledColor(data[0].notifications.ActivePower[0].enabled_sms, "span_potenciaActiva_enabled_sms");

			$('#span_potenciaAparente_min').text(data[0].notifications.AparentPower[1].warning);
			$('#span_potenciaAparente_max').text(data[0].notifications.AparentPower[0].warning);			
			checkEnabledColor(data[0].notifications.AparentPower[0].enabled, "span_potenciaAparente_enabled");
			checkEnabledColor(data[0].notifications.AparentPower[0].enabled_email, "span_potenciaAparente_enabled_email");
			checkEnabledColor(data[0].notifications.AparentPower[0].enabled_sms, "span_potenciaAparente_enabled_sms");

			$('#span_potenciaReactiva_min').text(data[0].notifications.ReactivePower[1].warning);
			$('#span_potenciaReactiva_max').text(data[0].notifications.ReactivePower[0].warning);			
			checkEnabledColor(data[0].notifications.ReactivePower[0].enabled, "span_potenciaReactiva_enabled");
			checkEnabledColor(data[0].notifications.ReactivePower[0].enabled_email, "span_potenciaReactiva_enabled_email");
			checkEnabledColor(data[0].notifications.ReactivePower[0].enabled_sms, "span_potenciaReactiva_enabled_sms");
			
			$('#span_GSM_min').text(data[0].notifications.GSM_Signal[1].warning);
			$('#span_GSM_max').text(data[0].notifications.GSM_Signal[0].warning);
			checkEnabledColor(data[0].notifications.GSM_Signal[0].enabled, "span_GSM_enabled");
			checkEnabledColor(data[0].notifications.GSM_Signal[0].enabled_email, "span_GSM_enabled_email");
			checkEnabledColor(data[0].notifications.GSM_Signal[0].enabled_sms, "span_GSM_enabled_sms");

			$('#span_kwAmount_min').text(); // => Ver de donde se sacan estos datos
			$('#span_kwAmount_max').text();
			// $('#span_kwAmount_enabled').text();
			
			$('#span_DoorOpenCount_min').text(data[0].notifications.DoorOpenCount[1].warning);
			$('#span_DoorOpenCount_max').text(data[0].notifications.DoorOpenCount[0].warning);
			checkEnabledColor(data[0].notifications.DoorOpenCount[0].enabled, "span_DoorOpenCount_enabled");
			checkEnabledColor(data[0].notifications.DoorOpenCount[0].enabled_email, "span_DoorOpenCount_enabled_email");
			checkEnabledColor(data[0].notifications.DoorOpenCount[0].enabled_sms, "span_DoorOpenCount_enabled_sms");
			
			$('#span_DoorOpenTime_min').text(data[0].notifications.DoorOpenTime[1].warning);
			$('#span_DoorOpenTime_max').text(data[0].notifications.DoorOpenTime[0].warning);		
			checkEnabledColor(data[0].notifications.DoorOpenTime[0].enabled, "span_DoorOpenTime_enabled");
			checkEnabledColor(data[0].notifications.DoorOpenTime[0].enabled_email, "span_DoorOpenTime_enabled_email");
			checkEnabledColor(data[0].notifications.DoorOpenTime[0].enabled_sms, "span_DoorOpenTime_enabled_sms");
			

		// Alarmas
			
			$('#span_voltage_min').text(data[0].notifications.Voltage[1].alert);
			$('#span_voltage_max').text(data[0].notifications.Voltage[0].alert);
			checkEnabledColor(data[0].notifications.Voltage[0].enabled, "span_voltage_enabled");
			checkEnabledColor(data[0].notifications.Voltage[0].enabled_email, "span_voltage_enabled_email");
			checkEnabledColor(data[0].notifications.Voltage[0].enabled_sms, "span_voltage_enabled_sms");
			
			$('#span_keepAlive').text(data[0].notifications.LastKeepAliveTime[0].alert);
			checkEnabledColor(data[0].notifications.LastKeepAliveTime[0].enabled, "span_keepAlive_enabled");
			checkEnabledColor(data[0].notifications.LastKeepAliveTime[0].enabled_email, "span_keepAlive_enabled_email");
			checkEnabledColor(data[0].notifications.LastKeepAliveTime[0].enabled_sms, "span_keepAlive_enabled_sms");
			
			checkEnabledColor(data[0].notifications.Temperature[2].enabled, "span_sensor_temperatura_enabled");
			checkEnabledColor(data[0].notifications.Temperature[2].enabled_email, "span_sensor_temperatura_enabled_email");
			checkEnabledColor(data[0].notifications.Temperature[2].enabled_sms, "span_sensor_temperatura_enabled_sms");

			checkEnabledColor(data[0].notifications.Movement[0].enabled, "span_robo_enabled");
			checkEnabledColor(data[0].notifications.Movement[0].enabled_email, "span_robo_enabled_email");
			checkEnabledColor(data[0].notifications.Movement[0].enabled_sms, "span_robo_enabled_sms");

			// $('#span_geocerca_min').text(data[0].notifications.GPS[1].alert);
			$('#span_geocerca_max').text(data[0].notifications.GPS[0].alert);		
			checkEnabledColor(data[0].notifications.GPS[0].enabled, "span_geocerca_enabled");
			checkEnabledColor(data[0].notifications.GPS[0].enabled_email, "span_geocerca_enabled_email");
			checkEnabledColor(data[0].notifications.GPS[0].enabled_sms, "span_geocerca_enabled_sms");
				
			checkEnabledColor(data[0].notifications.TampersExt[0].enabled, "span_sabotaje1_enabled");
			checkEnabledColor(data[0].notifications.TampersExt[0].enabled_email, "span_sabotaje1_enabled_email");
			checkEnabledColor(data[0].notifications.TampersExt[0].enabled_sms, "span_sabotaje1_enabled_sms");

			checkEnabledColor(data[0].notifications.TampersInt[0].enabled, "span_sabotaje2_enabled");
			checkEnabledColor(data[0].notifications.TampersInt[0].enabled_email, "span_sabotaje2_enabled_email");
			checkEnabledColor(data[0].notifications.TampersInt[0].enabled_sms, "span_sabotaje2_enabled_sms");
		
			checkEnabledColor(data[0].notifications.TampersController[0].enabled, "span_sabotaje3_enabled");
			checkEnabledColor(data[0].notifications.TampersController[0].enabled_email, "span_sabotaje3_enabled_email");
			checkEnabledColor(data[0].notifications.TampersController[0].enabled_sms, "span_sabotaje3_enabled_sms");
				
			checkEnabledColor(data[0].notifications.Ant_Disconnect[0].enabled, "span_sabotaje4_enabled");
			checkEnabledColor(data[0].notifications.Ant_Disconnect[0].enabled_email, "span_sabotaje4_enabled_email");
			checkEnabledColor(data[0].notifications.Ant_Disconnect[0].enabled_sms, "span_sabotaje4_enabled_sms");

		});
	};

	vm.editAdvertencias = function () {
		// this.checkActivarAdver();
		logsService.getAlarmas().then(function (data) {

			$('#input_edit_temperature_min').val(data[0].notifications.Temperature[1].warning);
			$('#input_edit_temperature_max').val(data[0].notifications.Temperature[0].warning);
			if( data[0].notifications.Temperature[0].enabled == false){
				document.getElementById("check_temperature_enabled_email").disabled=true;
				document.getElementById("check_temperature_enabled_email").checked = false;
				document.getElementById("check_temperature_enabled_sms").disabled=true;
				document.getElementById("check_temperature_enabled_sms").checked = false
			} else {}	
			$('#check_temperature_enabled').attr('checked', data[0].notifications.Temperature[0].enabled);
			$('#check_temperature_enabled_email').attr('checked', data[0].notifications.Temperature[0].enabled_email);
			$('#check_temperature_enabled_sms').attr('checked', data[0].notifications.Temperature[0].enabled_sms);
			
			$('#input_edit_corriente_min').val(data[0].notifications.Current[1].alert);
			$('#input_edit_corriente_max').val(data[0].notifications.Current[0].alert);
			if( data[0].notifications.Current[0].enabled == false){
				document.getElementById("check_corriente_enabled_email").disabled=true;
				document.getElementById("check_corriente_enabled_email").checked = false;
				document.getElementById("check_corriente_enabled_sms").disabled=true;
				document.getElementById("check_corriente_enabled_sms").checked = false
			} else {}
			$('#check_corriente_enabled').attr('checked', data[0].notifications.Current[0].enabled);
			$('#check_corriente_enabled_email').attr('checked', data[0].notifications.Current[0].enabled_email);
			$('#check_corriente_enabled_sms').attr('checked', data[0].notifications.Current[0].enabled_sms);

			if( data[0].notifications.PowerSupply[0].enabled == false){
				document.getElementById("check_powerSupply_enabled_email").disabled=true;
				document.getElementById("check_powerSupply_enabled_email").checked = false;
				document.getElementById("check_powerSupply_enabled_sms").disabled=true;
				document.getElementById("check_powerSupply_enabled_sms").checked = false
			} else {}
			$('#check_powerSupply_enabled').attr('checked', data[0].notifications.PowerSupply[0].enabled);
			$('#check_powerSupply_enabled_email').attr('checked', data[0].notifications.PowerSupply[0].enabled_email);
			$('#check_powerSupply_enabled_sms').attr('checked', data[0].notifications.PowerSupply[0].enabled_sms);
 
			$('#input_edit_potenciaActiva_min').val(data[0].notifications.ActivePower[1].warning);
			$('#input_edit_potenciaActiva_max').val(data[0].notifications.ActivePower[0].warning);
			if( data[0].notifications.ActivePower[0].enabled == false){
				document.getElementById("check_potenciaActiva_enabled_email").disabled=true;
				document.getElementById("check_potenciaActiva_enabled_email").checked = false;
				document.getElementById("check_potenciaActiva_enabled_sms").disabled=true;
				document.getElementById("check_potenciaActiva_enabled_sms").checked = false
			}else {}
			$('#check_potenciaActiva_enabled').attr('checked', data[0].notifications.ActivePower[0].enabled);
			$('#check_potenciaActiva_enabled_email').attr('checked', data[0].notifications.ActivePower[0].enabled_email);
			$('#check_potenciaActiva_enabled_sms').attr('checked', data[0].notifications.ActivePower[0].enabled_sms);

			$('#input_edit_potenciaAparente_min').val(data[0].notifications.AparentPower[1].warning);
			$('#input_edit_potenciaAparente_max').val(data[0].notifications.AparentPower[0].warning);
			if( data[0].notifications.AparentPower[0].enabled == false){
				document.getElementById("check_potenciaAparente_enabled_email").disabled=true;
				document.getElementById("check_potenciaAparente_enabled_email").checked = false;
				document.getElementById("check_potenciaAparente_enabled_sms").disabled=true;
				document.getElementById("check_potenciaAparente_enabled_sms").checked = false
			} else {}
			$('#check_potenciaAparente_enabled').attr('checked', data[0].notifications.AparentPower[0].enabled);
			$('#check_potenciaAparente_enabled_email').attr('checked', data[0].notifications.AparentPower[0].enabled_email);
			$('#check_potenciaAparente_enabled_sms').attr('checked', data[0].notifications.AparentPower[0].enabled_sms);

			$('#input_edit_potenciaReactiva_min').val(data[0].notifications.ReactivePower[1].warning);
			$('#input_edit_potenciaReactiva_max').val(data[0].notifications.ReactivePower[0].warning);
			if( data[0].notifications.ReactivePower[0].enabled == false){
				document.getElementById("check_potenciaReactiva_enabled_email").disabled=true;
				document.getElementById("check_potenciaReactiva_enabled_email").checked = false;
				document.getElementById("check_potenciaReactiva_enabled_sms").disabled=true;
				document.getElementById("check_potenciaReactiva_enabled_sms").checked = false
			} else {}
			$('#check_potenciaReactiva_enabled').attr('checked', data[0].notifications.ReactivePower[0].enabled);
			$('#check_potenciaReactiva_enabled_email').attr('checked', data[0].notifications.ReactivePower[0].enabled_email);
			$('#check_potenciaReactiva_enabled_sms').attr('checked', data[0].notifications.ReactivePower[0].enabled_sms);

			$('#input_edit_GSM_min').val(data[0].notifications.GSM_Signal[1].warning);
			$('#input_edit_GSM_max').val(data[0].notifications.GSM_Signal[0].warning);
			if( data[0].notifications.GSM_Signal[0].enabled == false){
				document.getElementById("check_GSM_enabled_email").disabled=true;
				document.getElementById("check_GSM_enabled_email").checked = false;
				document.getElementById("check_GSM_enabled_sms").disabled=true;
				document.getElementById("check_GSM_enabled_sms").checked = false			
			} else {}
			$('#check_GSM_enabled').attr('checked', data[0].notifications.GSM_Signal[0].enabled);
			$('#check_GSM_enabled_email').attr('checked', data[0].notifications.GSM_Signal[0].enabled_email);
			$('#check_GSM_enabled_sms').attr('checked', data[0].notifications.GSM_Signal[0].enabled_sms);

			$('#input_edit_DoorOpenCount_min').val(data[0].notifications.DoorOpenCount[1].warning);
			$('#input_edit_DoorOpenCount_max').val(data[0].notifications.DoorOpenCount[0].warning);
			if( data[0].notifications.DoorOpenCount[0].enabled == false){
				document.getElementById("check_DoorOpenCount_enabled_email").disabled=true;
				document.getElementById("check_DoorOpenCount_enabled_email").checked = false;
				document.getElementById("check_DoorOpenCount_enabled_sms").disabled=true;
				document.getElementById("check_DoorOpenCount_enabled_sms").checked = false
			} else {}
			$('#check_DoorOpenCount_enabled').attr('checked', data[0].notifications.DoorOpenCount[0].enabled);
			$('#check_DoorOpenCount_enabled_email').attr('checked', data[0].notifications.DoorOpenCount[0].enabled_email);
			$('#check_DoorOpenCount_enabled_sms').attr('checked', data[0].notifications.DoorOpenCount[0].enabled_sms);

			$('#input_edit_DoorOpenTime_min').val(data[0].notifications.DoorOpenTime[1].warning || 0);
			$('#input_edit_DoorOpenTime_max').val(data[0].notifications.DoorOpenTime[0].warning);
			if( data[0].notifications.DoorOpenTime[0].enabled == false){
				document.getElementById("check_DoorOpenTime_enabled_email").disabled=true;
				document.getElementById("check_DoorOpenTime_enabled_email").checked = false;
				document.getElementById("check_DoorOpenTime_enabled_sms").disabled=true;
				document.getElementById("check_DoorOpenTime_enabled_sms").checked = false
			} else {}
			$('#check_DoorOpenTime_enabled').attr('checked', data[0].notifications.DoorOpenTime[0].enabled);
			$('#check_DoorOpenTime_enabled_email').attr('checked', data[0].notifications.DoorOpenTime[0].enabled_email);
			$('#check_DoorOpenTime_enabled_sms').attr('checked', data[0].notifications.DoorOpenTime[0].enabled_sms);

			clearValidation("editAdvertencias");
			
			$('#editAdvertencias').modal();

		}); 

	};

	vm.advertenciasUpdate = function () {
		// var inputVacios = validateInput("editAdvertencias");
		// if (inputVacios === 0) {
			logsService.getAlarmas().then(function (data) { 

				var new_temperature_min = $('#input_edit_temperature_min').val();
				var new_temperature_max = $('#input_edit_temperature_max').val();
				var new_temperature_enabled = $('#check_temperature_enabled').is(':checked');
				var new_temperature_enabled_email = $('#check_temperature_enabled_email').is(':checked');
				var new_temperature_enabled_sms = $('#check_temperature_enabled_sms').is(':checked');
				
				var new_corriente_min = $('#input_edit_corriente_min').val();
				var new_corriente_max = $('#input_edit_corriente_max').val(); 
				var new_corriente_enabled = $('#check_corriente_enabled').is(':checked');
				var new_corriente_enabled_email = $('#check_corriente_enabled_email').is(':checked');
				var new_corriente_enabled_sms = $('#check_corriente_enabled_sms').is(':checked');
				
				var new_powerSupply_enabled = $('#check_powerSupply_enabled').is(':checked');
				var new_powerSupply_enabled_email = $('#check_powerSupply_enabled_email').is(':checked');
				var new_powerSupply_enabled_sms = $('#check_powerSupply_enabled_sms').is(':checked');

				var new_potenciaActiva_min = $('#input_edit_potenciaActiva_min').val();
				var new_potenciaActiva_max = $('#input_edit_potenciaActiva_max').val(); 
				var new_potenciaActiva_enabled = $('#check_potenciaActiva_enabled').is(':checked');
				var new_potenciaActiva_enabled_email = $('#check_potenciaActiva_enabled_email').is(':checked');
				var new_potenciaActiva_enabled_sms = $('#check_potenciaActiva_enabled_sms').is(':checked');

				var new_potenciaAparente_min = $('#input_edit_potenciaAparente_min').val();
				var new_potenciaAparente_max = $('#input_edit_potenciaAparente_max').val(); 
				var new_potenciaAparente_enabled = $('#check_potenciaAparente_enabled').is(':checked');
				var new_potenciaAparente_enabled_email = $('#check_potenciaAparente_enabled_email').is(':checked');
				var new_potenciaAparente_enabled_sms = $('#check_potenciaAparente_enabled_sms').is(':checked');

				var new_potenciaReactiva_min = $('#input_edit_potenciaReactiva_min').val();
				var new_potenciaReactiva_max = $('#input_edit_potenciaReactiva_max').val(); 
				var new_potenciaReactiva_enabled = $('#check_potenciaReactiva_enabled').is(':checked');
				var new_potenciaReactiva_enabled_email = $('#check_potenciaReactiva_enabled_email').is(':checked');
				var new_potenciaReactiva_enabled_sms = $('#check_potenciaReactiva_enabled_sms').is(':checked');
				
				var new_GSM_min = $('#input_edit_GSM_min').val();
				var new_GSM_max = $('#input_edit_GSM_max').val(); 
				var new_GSM_enabled = $('#check_GSM_enabled').is(':checked');
				var new_GSM_enabled_email = $('#check_GSM_enabled_email').is(':checked');
				var new_GSM_enabled_sms = $('#check_GSM_enabled_sms').is(':checked');

				var new_DoorOpenCount_min = $('#input_edit_DoorOpenCount_min').val();
				var new_DoorOpenCount_max = $('#input_edit_DoorOpenCount_max').val(); 
				var new_DoorOpenCount_enabled = $('#check_DoorOpenCount_enabled').is(':checked');
				var new_DoorOpenCount_enabled_email = $('#check_DoorOpenCount_enabled_email').is(':checked');
				var new_DoorOpenCount_enabled_sms = $('#check_DoorOpenCount_enabled_sms').is(':checked');
				
				var new_DoorOpenTime_min = $('#input_edit_DoorOpenTime_min').val();
				var new_DoorOpenTime_max = $('#input_edit_DoorOpenTime_max').val(); 
				var new_DoorOpenTime_enabled = $('#check_DoorOpenTime_enabled').is(':checked');
				var new_DoorOpenTime_enabled_email = $('#check_DoorOpenTime_enabled_email').is(':checked');						
				var new_DoorOpenTime_enabled_sms = $('#check_DoorOpenTime_enabled_sms').is(':checked');				

				var obj = {
					"_id": data[0]._id,
					"name": "fridge",
					"notifications": {						
						"Temperature": [{
								"name": "temperature máximo",
								"warning": new_temperature_max,
								"type": "max",
								"enabled": new_temperature_enabled,
								"enabled_email": new_temperature_enabled_email,
								"enabled_sms": new_temperature_enabled_sms
							},
							{
								"name": "temperature mínimo",
								"warning": new_temperature_min,
								"type": "min",
								"enabled": new_temperature_enabled,
								"enabled_email": new_temperature_enabled_email,
								"enabled_sms": new_temperature_enabled_sms

							},
							{
								"name": "Sensor temperatura activo",
								"alert": "NaN",
								"type": "eq",
								"enabled": data[0].notifications.Temperature[2].enabled,
								"enabled_email": data[0].notifications.Temperature[2].enabled_email,
								"enabled_sms": data[0].notifications.Temperature[2].enabled_sms								
							}
						],
						"Current": [
							{
								"name": "corriente máxima",
								"alert": new_corriente_max,
								"enabled": new_corriente_enabled,
								"enabled_email": new_corriente_enabled_email,
								"enabled_sms": new_corriente_enabled_sms,
								"type": "max"
							},
							{
								"name": "corriente mínima",
								"alert": new_corriente_min,
								"enabled": new_corriente_enabled,
								"enabled_email": new_corriente_enabled_email,
								"enabled_sms": new_corriente_enabled_sms,
								"type": "min"
							}
						],
						"ActivePower": [
							{
								"name": "potencia activa máxima",
								"warning": new_potenciaActiva_max,
								"type": "max",
								"enabled": new_potenciaActiva_enabled,
								"enabled_email": new_potenciaActiva_enabled_email,
								"enabled_sms": new_potenciaActiva_enabled_sms
							},
							{
								"name": "potencia activa mínima",
								"warning": new_potenciaActiva_min,
								"type": "min",
								"enabled": new_potenciaActiva_enabled,
								"enabled_email": new_potenciaActiva_enabled_email,
								"enabled_sms": new_potenciaActiva_enabled_sms
							}
						],
						"ReactivePower": [
							{
								"name": "potencia reactiva máxima",
								"warning": new_potenciaReactiva_max,
								"type": "max",
								"enabled": new_potenciaReactiva_enabled,
								"enabled_email": new_potenciaReactiva_enabled_email,
								"enabled_sms": new_potenciaReactiva_enabled_sms
							},
							{
								"name": "potencia reactiva mínima",
								"warning": new_potenciaReactiva_min,
								"type": "min",
								"enabled": new_potenciaReactiva_enabled,
								"enabled_email": new_potenciaReactiva_enabled_email,
								"enabled_sms": new_potenciaReactiva_enabled_sms
							}
						],
						"AparentPower": [
							{
								"name": "potencia aparente máxima",
								"warning": new_potenciaAparente_max,
								"type": "max",
								"enabled": new_potenciaAparente_enabled,
								"enabled_email": new_potenciaAparente_enabled_email,
								"enabled_sms": new_potenciaAparente_enabled_sms
							},
							{
								"name": "potencia aparente mínima",
								"warning": new_potenciaAparente_min,
								"type": "min",
								"enabled": new_potenciaAparente_enabled,
								"enabled_email": new_potenciaAparente_enabled_email,
								"enabled_sms": new_potenciaAparente_enabled_sms
							}
						],
						"PowerSupply": [
							{
								"name": "power activo",
								"normal": 1,
								"warning": 0,
								"enabled": new_powerSupply_enabled,
								"enabled_email": new_powerSupply_enabled_email,
								"enabled_sms": new_powerSupply_enabled_sms,
								"type": "eq"
							}
						],						
						"GSM_Signal": [
							{
								"name": "señal gsm",
								"warning": new_GSM_max,
								"enabled": new_GSM_enabled,
								"enabled_email": new_GSM_enabled_email,
								"enabled_sms": new_GSM_enabled_sms,
								"type": "max"
							},
							{
								"name": "señal gsm",
								"warning": new_GSM_min,
								"enabled": new_GSM_enabled,
								"enabled_email": new_GSM_enabled_email,
								"enabled_sms": new_GSM_enabled_sms,
								"type": "min"
							}
						],
						"DoorOpenCount": [
							{
								"name": "máximo aperturas diarias",
								"warning": new_DoorOpenCount_max,
								"type": "max",
								"enabled": new_DoorOpenCount_enabled,
								"enabled_email": new_DoorOpenCount_enabled_email,
								"enabled_sms": new_DoorOpenCount_enabled_sms
							},
							{
								"name": "máximo aperturas diarias",
								"warning": new_DoorOpenCount_min,
								"type": "min",
								"enabled": new_DoorOpenCount_enabled,
								"enabled_email": new_DoorOpenCount_enabled_email,
								"enabled_sms": new_DoorOpenCount_enabled_sms
							}
						],
						"DoorOpenTime": [
							{
								"name": "tiempo de apertura",
								"warning": new_DoorOpenTime_max,
								"type": "max",
								"enabled": new_DoorOpenTime_enabled,
								"enabled_email": new_DoorOpenCount_enabled_email,
								"enabled_sms": new_DoorOpenTime_enabled_sms
							},
							{
								"name": "tiempo de apertura",
								"warning": new_DoorOpenTime_min,
								"type": "min",
								"enabled": new_DoorOpenTime_enabled,
								"enabled_email": new_DoorOpenTime_enabled_email,
								"enabled_sms": new_DoorOpenTime_enabled_sms
							}
						]

					}
				}

				logsService.alarmasUpdate(obj).then(function () {
					vm.getAlarmasInit();
					$('.modal').modal('hide');
					growl.success('Se actualizaron las alarmas exitosamente.');
				});
			});
		// } else {}	

	};
	
	vm.checkActivarAlarm = function (){
		if ( document.getElementById("check_voltage_enabled").checked == false && document.getElementById("check_voltage_enabled_email").checked == true || document.getElementById("check_voltage_enabled").checked == false && document.getElementById("check_voltage_enabled_sms").checked == true ){
				document.getElementById("check_voltage_enabled_email").disabled=true;
				document.getElementById("check_voltage_enabled_email").checked = false;
				document.getElementById("check_voltage_enabled_sms").disabled=true;
				document.getElementById("check_voltage_enabled_sms").checked = false
		}else{		
			if (document.getElementById("check_voltage_enabled").checked == false){
				document.getElementById("check_voltage_enabled").disabled=false;
				document.getElementById("check_voltage_enabled_email").disabled=true;
				document.getElementById("check_voltage_enabled_email").checked = false;
				document.getElementById("check_voltage_enabled_sms").disabled=true;
				document.getElementById("check_voltage_enabled_sms").checked = false}
			else{
				document.getElementById("check_voltage_enabled").disabled=false;
				document.getElementById("check_voltage_enabled_email").disabled=false;
				document.getElementById("check_voltage_enabled_sms").disabled=false;}
		}

		if ( document.getElementById("check_keepAlive_enabled").checked == false && document.getElementById("check_keepAlive_enabled_email").checked == true || document.getElementById("check_keepAlive_enabled").checked == false && document.getElementById("check_keepAlive_enabled_sms").checked == true ){
				document.getElementById("check_keepAlive_enabled_email").disabled=true;
				document.getElementById("check_keepAlive_enabled_email").checked = false;
				document.getElementById("check_keepAlive_enabled_sms").disabled=true;
				document.getElementById("check_keepAlive_enabled_sms").checked = false
		}else{
			if (document.getElementById("check_keepAlive_enabled").checked == false){
				document.getElementById("check_keepAlive_enabled").disabled=false;
				document.getElementById("check_keepAlive_enabled_email").disabled=true;
				document.getElementById("check_keepAlive_enabled_email").checked = false;
				document.getElementById("check_keepAlive_enabled_sms").disabled=true;
				document.getElementById("check_keepAlive_enabled_sms").checked = false}
			else{
				document.getElementById("check_keepAlive_enabled").disabled=false;
				document.getElementById("check_keepAlive_enabled_email").disabled=false;
				document.getElementById("check_keepAlive_enabled_sms").disabled=false;}
		}
		if ( document.getElementById("check_Movement_enabled").checked == false && document.getElementById("check_Movement_enabled_email").checked == true || document.getElementById("check_Movement_enabled").checked == false && document.getElementById("check_Movement_enabled_sms").checked == true ){
				document.getElementById("check_Movement_enabled_email").disabled=true;
				document.getElementById("check_Movement_enabled_email").checked = false;
				document.getElementById("check_Movement_enabled_sms").disabled=true;
				document.getElementById("check_Movement_enabled_sms").checked = false
		}else{
			if (document.getElementById("check_Movement_enabled").checked == false){
				document.getElementById("check_Movement_enabled").disabled=false;
				document.getElementById("check_Movement_enabled_email").disabled=true;
				document.getElementById("check_Movement_enabled_email").checked = false;
				document.getElementById("check_Movement_enabled_sms").disabled=true;
				document.getElementById("check_Movement_enabled_sms").checked = false}
			else{
				document.getElementById("check_Movement_enabled").disabled=false;
				document.getElementById("check_Movement_enabled_email").disabled=false;
				document.getElementById("check_Movement_enabled_sms").disabled=false;}
		}

		if ( document.getElementById("check_Sensor_Temperature_enabled").checked == false && document.getElementById("check_Sensor_Temperature_enabled_email").checked == true || document.getElementById("check_Sensor_Temperature_enabled").checked == false && document.getElementById("check_Sensor_Temperature_enabled_sms").checked == true ){
			document.getElementById("check_Sensor_Temperature_enabled_email").disabled=true;
			document.getElementById("check_Sensor_Temperature_enabled_email").checked = false;
			document.getElementById("check_Sensor_Temperature_enabled_sms").disabled=true;
			document.getElementById("check_Sensor_Temperature_enabled_sms").checked = false
	}else{
		if (document.getElementById("check_Sensor_Temperature_enabled").checked == false){
			document.getElementById("check_Sensor_Temperature_enabled").disabled=false;
			document.getElementById("check_Sensor_Temperature_enabled_email").disabled=true;
			document.getElementById("check_Sensor_Temperature_enabled_email").checked = false;
			document.getElementById("check_Sensor_Temperature_enabled_sms").disabled=true;
			document.getElementById("check_Sensor_Temperature_enabled_sms").checked = false}
		else{
			document.getElementById("check_Sensor_Temperature_enabled").disabled=false;
			document.getElementById("check_Sensor_Temperature_enabled_email").disabled=false;
			document.getElementById("check_Sensor_Temperature_enabled_sms").disabled=false;}
	}

		if ( document.getElementById("check_geocerca_enabled").checked == false && document.getElementById("check_geocerca_enabled_email").checked == true || document.getElementById("check_geocerca_enabled").checked == false && document.getElementById("check_geocerca_enabled_sms").checked == true ){
			document.getElementById("check_geocerca_enabled_email").disabled=true;
			document.getElementById("check_geocerca_enabled_email").checked = false;
			document.getElementById("check_geocerca_enabled_sms").disabled=true;
			document.getElementById("check_geocerca_enabled_sms").checked = false
		}else{	
			if (document.getElementById("check_geocerca_enabled").checked == false){
				document.getElementById("check_geocerca_enabled").disabled=false;
				document.getElementById("check_geocerca_enabled_email").disabled=true;
				document.getElementById("check_geocerca_enabled_email").checked = false;
				document.getElementById("check_geocerca_enabled_sms").disabled=true;
				document.getElementById("check_geocerca_enabled_sms").checked = false}
			else{
				document.getElementById("check_geocerca_enabled").disabled=false;
				document.getElementById("check_geocerca_enabled_email").disabled=false;
				document.getElementById("check_geocerca_enabled_sms").disabled=false;}
		}

		if ( document.getElementById("check_TampersInt_enabled").checked == false && document.getElementById("check_TampersInt_enabled_email").checked == true || document.getElementById("check_TampersInt_enabled").checked == false && document.getElementById("check_TampersInt_enabled_sms").checked == true ){
			document.getElementById("check_TampersInt_enabled_email").disabled=true;
			document.getElementById("check_TampersInt_enabled_email").checked = false;
			document.getElementById("check_TampersInt_enabled_sms").disabled=true;
			document.getElementById("check_TampersInt_enabled_sms").checked = false
		}else{
			if (document.getElementById("check_TampersInt_enabled").checked == false){
				document.getElementById("check_TampersInt_enabled").disabled=false;
				document.getElementById("check_TampersInt_enabled_email").disabled=true;
				document.getElementById("check_TampersInt_enabled_email").checked = false;
				document.getElementById("check_TampersInt_enabled_sms").disabled=true;
				document.getElementById("check_TampersInt_enabled_sms").checked = false}
			else{
				document.getElementById("check_TampersInt_enabled").disabled=false;
				document.getElementById("check_TampersInt_enabled_email").disabled=false;
				document.getElementById("check_TampersInt_enabled_sms").disabled=false;}
		}

		if ( document.getElementById("check_TampersExt_enabled").checked == false && document.getElementById("check_TampersExt_enabled_email").checked == true || document.getElementById("check_TampersExt_enabled").checked == false && document.getElementById("check_TampersExt_enabled_sms").checked == true ){
				document.getElementById("check_TampersExt_enabled_email").disabled=true;
				document.getElementById("check_TampersExt_enabled_email").checked = false;
				document.getElementById("check_TampersExt_enabled_sms").disabled=true;
				document.getElementById("check_TampersExt_enabled_sms").checked = false
		}else{
			if (document.getElementById("check_TampersExt_enabled").checked == false){
				document.getElementById("check_TampersExt_enabled").disabled=false;
				document.getElementById("check_TampersExt_enabled_email").disabled=true;
				document.getElementById("check_TampersExt_enabled_email").checked = false;
				document.getElementById("check_TampersExt_enabled_sms").disabled=true;
				document.getElementById("check_TampersExt_enabled_sms").checked = false}
			else{
				document.getElementById("check_TampersExt_enabled").disabled=false;
				document.getElementById("check_TampersExt_enabled_email").disabled=false;
				document.getElementById("check_TampersExt_enabled_sms").disabled=false;}
		}

		if ( document.getElementById("check_TampersController_enabled").checked == false && document.getElementById("check_TampersController_enabled_email").checked == true || document.getElementById("check_TampersController_enabled").checked == false && document.getElementById("check_TampersController_enabled_sms").checked == true ){
				document.getElementById("check_TampersController_enabled_email").disabled=true;
				document.getElementById("check_TampersController_enabled_email").checked = false;
				document.getElementById("check_TampersController_enabled_sms").disabled=true;
				document.getElementById("check_TampersController_enabled_sms").checked = false
		}else{
			if (document.getElementById("check_TampersController_enabled").checked == false){
				document.getElementById("check_TampersController_enabled").disabled=false;
				document.getElementById("check_TampersController_enabled_email").disabled=true;
				document.getElementById("check_TampersController_enabled_email").checked = false;
				document.getElementById("check_TampersController_enabled_sms").disabled=true;
				document.getElementById("check_TampersController_enabled_sms").checked = false}
			else{
				document.getElementById("check_TampersController_enabled").disabled=false;
				document.getElementById("check_TampersController_enabled_email").disabled=false;
				document.getElementById("check_TampersController_enabled_sms").disabled=false;}

		}
		if ( document.getElementById("check_Ant_Disconnect_enabled").checked == false && document.getElementById("check_Ant_Disconnect_enabled_email").checked == true || document.getElementById("check_Ant_Disconnect_enabled").checked == false && document.getElementById("check_Ant_Disconnect_enabled_sms").checked == true ){
				document.getElementById("check_Ant_Disconnect_enabled_email").disabled=true;
				document.getElementById("check_Ant_Disconnect_enabled_email").checked = false;
				document.getElementById("check_Ant_Disconnect_enabled_sms").disabled=true;
				document.getElementById("check_Ant_Disconnect_enabled_sms").checked = false
		}else{		
			if (document.getElementById("check_Ant_Disconnect_enabled").checked == false){
				document.getElementById("check_Ant_Disconnect_enabled").disabled=false;
				document.getElementById("check_Ant_Disconnect_enabled_email").disabled=true;
				document.getElementById("check_Ant_Disconnect_enabled_email").checked = false;
				document.getElementById("check_Ant_Disconnect_enabled_sms").disabled=true;
				document.getElementById("check_Ant_Disconnect_enabled_sms").checked = false}
			else{
				document.getElementById("check_Ant_Disconnect_enabled").disabled=false;
				document.getElementById("check_Ant_Disconnect_enabled_email").disabled=false;
				document.getElementById("check_Ant_Disconnect_enabled_sms").disabled=false;}
			}
		
		};
		
		vm.checkActivarAdver = function (){
		if ( document.getElementById("check_temperature_enabled").checked == false && document.getElementById("check_temperature_enabled_email").checked == true || document.getElementById("check_temperature_enabled").checked == false && document.getElementById("check_temperature_enabled_sms").checked == true ){
			document.getElementById("check_temperature_enabled_email").checked = false;
			document.getElementById("check_temperature_enabled_sms").checked = false;
			document.getElementById("check_temperature_enabled_email").disabled=true;
			document.getElementById("check_temperature_enabled_sms").disabled=true;			
		}else{
			if (document.getElementById("check_temperature_enabled").checked == false){
				document.getElementById("check_temperature_enabled").disabled=false;
				document.getElementById("check_temperature_enabled_email").disabled=true;
				document.getElementById("check_temperature_enabled_email").checked = false;
				document.getElementById("check_temperature_enabled_sms").disabled=true;
				document.getElementById("check_temperature_enabled_sms").checked = false}
			else{
				document.getElementById("check_temperature_enabled").disabled=false;
				document.getElementById("check_temperature_enabled_email").disabled=false;
				document.getElementById("check_temperature_enabled_sms").disabled=false;}
		}

	
		if ( document.getElementById("check_corriente_enabled").checked == false && document.getElementById("check_corriente_enabled_email").checked == true || document.getElementById("check_corriente_enabled").checked == false && document.getElementById("check_corriente_enabled_sms").checked == true ){
				document.getElementById("check_corriente_enabled_email").disabled=true;
				document.getElementById("check_corriente_enabled_email").checked = false;
				document.getElementById("check_corriente_enabled_sms").disabled=true;
				document.getElementById("check_corriente_enabled_sms").checked = false
		}else{		
				if (document.getElementById("check_corriente_enabled").checked == false){
					document.getElementById("check_corriente_enabled").disabled=false;
					document.getElementById("check_corriente_enabled_email").disabled=true;
					document.getElementById("check_corriente_enabled_email").checked = false;
					document.getElementById("check_corriente_enabled_sms").disabled=true;
					document.getElementById("check_corriente_enabled_sms").checked = false}
				else{
					document.getElementById("check_corriente_enabled").disabled=false;
					document.getElementById("check_corriente_enabled_email").disabled=false;
					document.getElementById("check_corriente_enabled_sms").disabled=false;}
				}
	
		if ( document.getElementById("check_powerSupply_enabled").checked == false && document.getElementById("check_powerSupply_enabled_email").checked == true || document.getElementById("check_powerSupply_enabled").checked == false && document.getElementById("check_powerSupply_enabled_sms").checked == true ){
					document.getElementById("check_powerSupply_enabled_email").disabled=true;
					document.getElementById("check_powerSupply_enabled_email").checked = false;
					document.getElementById("check_powerSupply_enabled_sms").disabled=true;
					document.getElementById("check_powerSupply_enabled_sms").checked = false
		}else{					
				if (document.getElementById("check_powerSupply_enabled").checked == false){
					document.getElementById("check_powerSupply_enabled").disabled=false;
					document.getElementById("check_powerSupply_enabled_email").disabled=true;
					document.getElementById("check_powerSupply_enabled_email").checked = false;
					document.getElementById("check_powerSupply_enabled_sms").disabled=true;
					document.getElementById("check_powerSupply_enabled_sms").checked = false}
				else{
					document.getElementById("check_powerSupply_enabled").disabled=false;
					document.getElementById("check_powerSupply_enabled_email").disabled=false;
					document.getElementById("check_powerSupply_enabled_sms").disabled=false;}
		
		}
		if ( document.getElementById("check_potenciaActiva_enabled").checked == false && document.getElementById("check_potenciaActiva_enabled_email").checked == true || document.getElementById("check_potenciaActiva_enabled").checked == false && document.getElementById("check_potenciaActiva_enabled_sms").checked == true ){
					document.getElementById("check_potenciaActiva_enabled_email").disabled=true;
					document.getElementById("check_potenciaActiva_enabled_email").checked = false;
					document.getElementById("check_potenciaActiva_enabled_sms").disabled=true;
					document.getElementById("check_potenciaActiva_enabled_sms").checked = false
		}else{
				if (document.getElementById("check_potenciaActiva_enabled").checked == false){
					document.getElementById("check_potenciaActiva_enabled").disabled=false;
					document.getElementById("check_potenciaActiva_enabled_email").disabled=true;
					document.getElementById("check_potenciaActiva_enabled_email").checked = false;
					document.getElementById("check_potenciaActiva_enabled_sms").disabled=true;
					document.getElementById("check_potenciaActiva_enabled_sms").checked = false}
				else{
					document.getElementById("check_potenciaActiva_enabled").disabled=false;
					document.getElementById("check_potenciaActiva_enabled_email").disabled=false;
					document.getElementById("check_potenciaActiva_enabled_sms").disabled=false;}
			}

		if ( document.getElementById("check_potenciaAparente_enabled").checked == false && document.getElementById("check_potenciaAparente_enabled_email").checked == true || document.getElementById("check_potenciaAparente_enabled").checked == false && document.getElementById("check_potenciaAparente_enabled_sms").checked == true ){
					document.getElementById("check_potenciaAparente_enabled_email").disabled=true;
					document.getElementById("check_potenciaAparente_enabled_email").checked = false;
					document.getElementById("check_potenciaAparente_enabled_sms").disabled=true;
					document.getElementById("check_potenciaAparente_enabled_sms").checked = false
		}else{	
				if (document.getElementById("check_potenciaAparente_enabled").checked == false){
					document.getElementById("check_potenciaAparente_enabled").disabled=false;
					document.getElementById("check_potenciaAparente_enabled_email").disabled=true;
					document.getElementById("check_potenciaAparente_enabled_email").checked = false;
					document.getElementById("check_potenciaAparente_enabled_sms").disabled=true;
					document.getElementById("check_potenciaAparente_enabled_sms").checked = false}
				else{
					document.getElementById("check_potenciaAparente_enabled").disabled=false;
					document.getElementById("check_potenciaAparente_enabled_email").disabled=false;
					document.getElementById("check_potenciaAparente_enabled_sms").disabled=false;}
			}

		if ( document.getElementById("check_potenciaReactiva_enabled").checked == false && document.getElementById("check_potenciaReactiva_enabled_email").checked == true || document.getElementById("check_potenciaReactiva_enabled").checked == false && document.getElementById("check_potenciaReactiva_enabled_sms").checked == true ){
					document.getElementById("check_potenciaReactiva_enabled_email").disabled=true;
					document.getElementById("check_potenciaReactiva_enabled_email").checked = false;
					document.getElementById("check_potenciaReactiva_enabled_sms").disabled=true;
					document.getElementById("check_potenciaReactiva_enabled_sms").checked = false
		}else{
				if (document.getElementById("check_potenciaReactiva_enabled").checked == false){
					document.getElementById("check_potenciaReactiva_enabled").disabled=false;
					document.getElementById("check_potenciaReactiva_enabled_email").disabled=true;
					document.getElementById("check_potenciaReactiva_enabled_email").checked = false;
					document.getElementById("check_potenciaReactiva_enabled_sms").disabled=true;
					document.getElementById("check_potenciaReactiva_enabled_sms").checked = false}
				else{
					document.getElementById("check_potenciaReactiva_enabled").disabled=false;
					document.getElementById("check_potenciaReactiva_enabled_email").disabled=false;
					document.getElementById("check_potenciaReactiva_enabled_sms").disabled=false;}
			}
		

		if ( document.getElementById("check_GSM_enabled").checked == false && document.getElementById("check_GSM_enabled_email").checked == true || document.getElementById("check_GSM_enabled").checked == false && document.getElementById("check_GSM_enabled_sms").checked == true ){
					document.getElementById("check_GSM_enabled_email").disabled=true;
					document.getElementById("check_GSM_enabled_email").checked = false;
					document.getElementById("check_GSM_enabled_sms").disabled=true;
					document.getElementById("check_GSM_enabled_sms").checked = false
		}else{		
				if (document.getElementById("check_GSM_enabled").checked == false){
					document.getElementById("check_GSM_enabled").disabled=false;
					document.getElementById("check_GSM_enabled_email").disabled=true;
					document.getElementById("check_GSM_enabled_email").checked = false;
					document.getElementById("check_GSM_enabled_sms").disabled=true;
					document.getElementById("check_GSM_enabled_sms").checked = false}
				else{
					document.getElementById("check_GSM_enabled").disabled=false;
					document.getElementById("check_GSM_enabled_email").disabled=false;
					document.getElementById("check_GSM_enabled_sms").disabled=false;}
			}

		if ( document.getElementById("check_DoorOpenCount_enabled").checked == false && document.getElementById("check_DoorOpenCount_enabled_email").checked == true || document.getElementById("check_DoorOpenCount_enabled").checked == false && document.getElementById("check_DoorOpenCount_enabled_sms").checked == true ){
					document.getElementById("check_DoorOpenCount_enabled_email").disabled=true;
					document.getElementById("check_DoorOpenCount_enabled_email").checked = false;
					document.getElementById("check_DoorOpenCount_enabled_sms").disabled=true;
					document.getElementById("check_DoorOpenCount_enabled_sms").checked = false
		}else{		
				if (document.getElementById("check_DoorOpenCount_enabled").checked == false){
					document.getElementById("check_DoorOpenCount_enabled").disabled=false;
					document.getElementById("check_DoorOpenCount_enabled_email").disabled=true;
					document.getElementById("check_DoorOpenCount_enabled_email").checked = false;
					document.getElementById("check_DoorOpenCount_enabled_sms").disabled=true;
					document.getElementById("check_DoorOpenCount_enabled_sms").checked = false}
				else{
					document.getElementById("check_DoorOpenCount_enabled").disabled=false;
					document.getElementById("check_DoorOpenCount_enabled_email").disabled=false;
					document.getElementById("check_DoorOpenCount_enabled_sms").disabled=false;}
		
				}

		if ( document.getElementById("check_DoorOpenTime_enabled").checked == false && document.getElementById("check_DoorOpenTime_enabled_email").checked == true || document.getElementById("check_DoorOpenTime_enabled").checked == false && document.getElementById("check_DoorOpenTime_enabled_sms").checked == true ){
					document.getElementById("check_DoorOpenTime_enabled_email").disabled=true;
					document.getElementById("check_DoorOpenTime_enabled_email").checked = false;
					document.getElementById("check_DoorOpenTime_enabled_sms").disabled=true;
					document.getElementById("check_DoorOpenTime_enabled_sms").checked = false
		}else{	
				if (document.getElementById("check_DoorOpenTime_enabled").checked == false){
					document.getElementById("check_DoorOpenTime_enabled").disabled=false;
					document.getElementById("check_DoorOpenTime_enabled_email").disabled=true;
					document.getElementById("check_DoorOpenTime_enabled_email").checked = false;
					document.getElementById("check_DoorOpenTime_enabled_sms").disabled=true;
					document.getElementById("check_DoorOpenTime_enabled_sms").checked = false}
				else{
					document.getElementById("check_DoorOpenTime_enabled").disabled=false;
					document.getElementById("check_DoorOpenTime_enabled_email").disabled=false;
					document.getElementById("check_DoorOpenTime_enabled_sms").disabled=false;}
	
			}
			};


	vm.editAlarmas = function () {
		
		// this.checkActivarAlarm();

		logsService.getAlarmas().then(function (data) {


			$('#input_edit_voltage_min').val(data[0].notifications.Voltage[1].alert);
			$('#input_edit_voltage_max').val(data[0].notifications.Voltage[0].alert);
			if(	data[0].notifications.Voltage[0].enabled == false){
				document.getElementById("check_voltage_enabled_email").disabled=true;
				document.getElementById("check_voltage_enabled_email").checked = false;
				document.getElementById("check_voltage_enabled_sms").disabled=true;
				document.getElementById("check_voltage_enabled_sms").checked = false
			} else {}
			$('#check_voltage_enabled').attr('checked', data[0].notifications.Voltage[0].enabled);
			$('#check_voltage_enabled_email').attr('checked', data[0].notifications.Voltage[0].enabled_email);
			$('#check_voltage_enabled_sms').attr('checked', data[0].notifications.Voltage[0].enabled_sms);

			$('#input_edit_keepAlive_max').val(data[0].notifications.LastKeepAliveTime[0].alert);
			if(	data[0].notifications.LastKeepAliveTime[0].enabled == false){
				document.getElementById("check_keepAlive_enabled_email").disabled=true;
				document.getElementById("check_keepAlive_enabled_email").checked = false;
				document.getElementById("check_keepAlive_enabled_sms").disabled=true;
				document.getElementById("check_keepAlive_enabled_sms").checked = false
			} else {}
			$('#check_keepAlive_enabled').attr('checked', data[0].notifications.LastKeepAliveTime[0].enabled);
			$('#check_keepAlive_enabled_email').attr('checked', data[0].notifications.LastKeepAliveTime[0].enabled_email);
			$('#check_keepAlive_enabled_sms').attr('checked', data[0].notifications.LastKeepAliveTime[0].enabled_sms);
			
			if(	data[0].notifications.Movement[0].enabled == false){
				document.getElementById("check_Movement_enabled_email").disabled=true;
				document.getElementById("check_Movement_enabled_email").checked = false;
				document.getElementById("check_Movement_enabled_sms").disabled=true;
				document.getElementById("check_Movement_enabled_sms").checked = false
			} else {}
			$('#check_Movement_enabled').attr('checked', data[0].notifications.Movement[0].enabled);
			$('#check_Movement_enabled_email').attr('checked', data[0].notifications.Movement[0].enabled_email);
			$('#check_Movement_enabled_sms').attr('checked', data[0].notifications.Movement[0].enabled_sms);

			if(	data[0].notifications.Temperature[2].enabled == false){
				document.getElementById("check_Sensor_Temperature_enabled_email").disabled=true;
				document.getElementById("check_Sensor_Temperature_enabled_email").checked = false;
				document.getElementById("check_Sensor_Temperature_enabled_sms").disabled=true;
				document.getElementById("check_Sensor_Temperature_enabled_sms").checked = false
			} else {}
			$('#check_Sensor_Temperature_enabled').attr('checked', data[0].notifications.Temperature[2].enabled);
			$('#check_Sensor_Temperature_enabled_email').attr('checked', data[0].notifications.Temperature[2].enabled_email);
			$('#check_Sensor_Temperature_enabled_sms').attr('checked', data[0].notifications.Temperature[2].enabled_sms);
			
			// $('#input_edit_geocerca_min').val(data[0].notifications.GPS[1].alert);
			$('#input_edit_geocerca_max').val(data[0].notifications.GPS[0].alert);
			if(	data[0].notifications.GPS[0].enabled == false){
				document.getElementById("check_geocerca_enabled_email").disabled=true;
				document.getElementById("check_geocerca_enabled_email").checked = false;
				document.getElementById("check_geocerca_enabled_sms").disabled=true;
				document.getElementById("check_geocerca_enabled_sms").checked = false
			} else {}
			$('#check_geocerca_enabled').attr('checked', data[0].notifications.GPS[0].enabled);
			$('#check_geocerca_enabled_email').attr('checked', data[0].notifications.GPS[0].enabled_email);
			$('#check_geocerca_enabled_sms').attr('checked', data[0].notifications.GPS[0].enabled_sms);

			if( data[0].notifications.TampersInt[0].enabled == false){
				document.getElementById("check_TampersInt_enabled_email").disabled=true;
				document.getElementById("check_TampersInt_enabled_email").checked = false;
				document.getElementById("check_TampersInt_enabled_sms").disabled=true;
				document.getElementById("check_TampersInt_enabled_sms").checked = false
			} else {}
			$('#check_TampersInt_enabled').attr('checked', data[0].notifications.TampersInt[0].enabled);
			$('#check_TampersInt_enabled_email').attr('checked', data[0].notifications.TampersInt[0].enabled_email);
			$('#check_TampersInt_enabled_sms').attr('checked', data[0].notifications.TampersInt[0].enabled_sms);
			
			if(	data[0].notifications.TampersExt[0].enabled == false){
				document.getElementById("check_TampersExt_enabled_email").disabled=true;
				document.getElementById("check_TampersExt_enabled_email").checked = false;
				document.getElementById("check_TampersExt_enabled_sms").disabled=true;
				document.getElementById("check_TampersExt_enabled_sms").checked = false
			} else {}
			$('#check_TampersExt_enabled').attr('checked', data[0].notifications.TampersExt[0].enabled);
			$('#check_TampersExt_enabled_email').attr('checked', data[0].notifications.TampersExt[0].enabled_email);
			$('#check_TampersExt_enabled_sms').attr('checked', data[0].notifications.TampersExt[0].enabled_sms);

			if(	data[0].notifications.TampersController[0].enabled == false){
				document.getElementById("check_TampersController_enabled_email").disabled=true;
				document.getElementById("check_TampersController_enabled_email").checked = false;
				document.getElementById("check_TampersController_enabled_sms").disabled=true;
				document.getElementById("check_TampersController_enabled_sms").checked = false
			} else {}
			$('#check_TampersController_enabled').attr('checked', data[0].notifications.TampersController[0].enabled);
			$('#check_TampersController_enabled_email').attr('checked', data[0].notifications.TampersController[0].enabled_email);
			$('#check_TampersController_enabled_sms').attr('checked', data[0].notifications.TampersController[0].enabled_sms);

			if(	data[0].notifications.Ant_Disconnect[0].enabled == false){
				document.getElementById("check_Ant_Disconnect_enabled_email").disabled=true;
				document.getElementById("check_Ant_Disconnect_enabled_email").checked = false;
				document.getElementById("check_Ant_Disconnect_enabled_sms").disabled=true;
				document.getElementById("check_Ant_Disconnect_enabled_sms").checked = false
			} else {}
			$('#check_Ant_Disconnect_enabled').attr('checked', data[0].notifications.Ant_Disconnect[0].enabled);
			$('#check_Ant_Disconnect_enabled_email').attr('checked', data[0].notifications.Ant_Disconnect[0].enabled_email);
			$('#check_Ant_Disconnect_enabled_sms').attr('checked', data[0].notifications.Ant_Disconnect[0].enabled_sms);

			clearValidation("editAlarmas");
			
			$('#editAlarmas').modal();

		});

	};

	vm.alarmasUpdate = function () {
		// var inputVacios = validateInput("modalAlarmas");
		// if (inputVacios === 0) {
			logsService.getAlarmas().then(function (data) {

				var new_voltage_min = $('#input_edit_voltage_min').val();
				var new_voltage_max = $('#input_edit_voltage_max').val(); 
				var new_voltage_enabled = $('#check_voltage_enabled').is(':checked');
				var new_voltage_enabled_email = $('#check_voltage_enabled_email').is(':checked');
				var new_voltage_enabled_sms = $('#check_voltage_enabled_sms').is(':checked');

				var new_keepAlive_max = $('#input_edit_keepAlive_max').val(); 
				var new_keepAlive_enabled = $('#check_keepAlive_enabled').is(':checked');
				var new_keepAlive_enabled_email = $('#check_keepAlive_enabled_email').is(':checked');
				var new_keepAlive_enabled_sms = $('#check_keepAlive_enabled_sms').is(':checked');

				var new_Movement_enabled = $('#check_Movement_enabled').is(':checked');
				var new_Movement_enabled_email = $('#check_Movement_enabled_email').is(':checked');
				var new_Movement_enabled_sms = $('#check_Movement_enabled_sms').is(':checked');

				var new_Sensor_Temperature_enabled = $('#check_Sensor_Temperature_enabled').is(':checked');
				var new_Sensor_Temperature_enabled_email = $('#check_Sensor_Temperature_enabled_email').is(':checked');
				var new_Sensor_Temperature_enabled_sms = $('#check_Sensor_Temperature_enabled_sms').is(':checked');

				// var new_geocerca_min = $('#input_edit_geocerca_min').val();
				var new_geocerca_max = $('#input_edit_geocerca_max').val(); 
				var new_geocerca_enabled = $('#check_geocerca_enabled').is(':checked');
				var new_geocerca_enabled_email = $('#check_geocerca_enabled_email').is(':checked');
				var new_geocerca_enabled_sms = $('#check_geocerca_enabled_sms').is(':checked');

				var new_TampersInt_enabled = $('#check_TampersInt_enabled').is(':checked');
				var new_TampersInt_enabled_email = $('#check_TampersInt_enabled_email').is(':checked');
				var new_TampersInt_enabled_sms = $('#check_TampersInt_enabled_sms').is(':checked');

				var new_TampersExt_enabled = $('#check_TampersExt_enabled').is(':checked');
				var new_TampersExt_enabled_email = $('#check_TampersExt_enabled_email').is(':checked');
				var new_TampersExt_enabled_sms = $('#check_TampersExt_enabled_sms').is(':checked');

				var new_TampersController_enabled = $('#check_TampersController_enabled').is(':checked');
				var new_TampersController_enabled_email = $('#check_TampersController_enabled_email').is(':checked');
				var new_TampersController_enabled_sms = $('#check_TampersController_enabled_sms').is(':checked');

				var new_Ant_Disconnect_enabled = $('#check_Ant_Disconnect_enabled').is(':checked');
				var new_Ant_Disconnect_enabled_email = $('#check_Ant_Disconnect_enabled_email').is(':checked');
				var new_Ant_Disconnect_enabled_sms = $('#check_Ant_Disconnect_enabled_sms').is(':checked');


				var obj = {
					"_id": data[0]._id,
					"name": "fridge",
					"notifications": {	
						"Voltage": [
							{
								"name": "voltage máximo",
								"alert": new_voltage_max,
								"type": "max",
								"enabled": new_voltage_enabled,
								"enabled_email": new_voltage_enabled_email,
								"enabled_sms": new_voltage_enabled_sms
							},
							{
								"name": "voltage mínimo",
								"alert": new_voltage_min,
								"type": "min",
								"enabled": new_voltage_enabled,
								"enabled_email": new_voltage_enabled_email,
								"enabled_sms": new_voltage_enabled_sms
							}
						],
						"Temperature": [{
							"name": "temperature máximo",
							"warning": data[0].notifications.Temperature[0].warning,
							"type": "max",
							"enabled": data[0].notifications.Temperature[0].enabled,
							"enabled_email": data[0].notifications.Temperature[0].enabled_email,
							"enabled_sms": data[0].notifications.Temperature[0].enabled_sms
						},
						{
							"name": "temperature mínimo",
							"warning": data[0].notifications.Temperature[1].warning,
							"type": "min",
							"enabled": data[0].notifications.Temperature[1].enabled,
							"enabled_email": data[0].notifications.Temperature[1].enabled_email,
							"enabled_sms": data[0].notifications.Temperature[1].enabled_sms

						},
						{
							"name": "Sensor temperatura activo",
							"alert": "NaN",
							"type": "eq",
							"enabled": new_Sensor_Temperature_enabled,
							"enabled_email": new_Sensor_Temperature_enabled_email,
							"enabled_sms": new_Sensor_Temperature_enabled_sms							
						}
					],
						"Movement": [
							{
								"name": "inclinación",
								"normal": 1,
								"alert": [
									2,
									3
								],
								"enabled": new_Movement_enabled,
								"enabled_email": new_Movement_enabled_email,
								"enabled_sms": new_Movement_enabled_sms,
								"type": "eq"
							}
						],
						"GPS": [
							{
								"name": "geocerca máximo",
								"alert": new_geocerca_max,
								"type": "gps",
								"enabled": new_geocerca_enabled,
								"enabled_email": new_geocerca_enabled_email,
								"enabled_sms": new_geocerca_enabled_sms
							}						
						],
						"TampersInt": [
							{
								"name": "gateway abierto",
								"normal": 1,
								"alert": 0,
								"enabled": new_TampersInt_enabled,
								"enabled_email": new_TampersInt_enabled_email,
								"enabled_sms": new_TampersInt_enabled_sms,							
								"type": "eq"
							}
						],
						"TampersExt": [
							{
								"name": "gateway removido",
								"normal": 1,
								"alert": 0,
								"enabled": new_TampersExt_enabled,
								"enabled_email": new_TampersExt_enabled_email,
								"enabled_sms": new_TampersExt_enabled_sms,
								"type": "eq"
							}
						],
						"TampersController": [
							{
								"name": "cassette removido",
								"normal": 0,
								"alert": 1,
								"enabled": new_TampersController_enabled,
								"enabled_email": new_TampersController_enabled_email,
								"enabled_sms": new_TampersController_enabled_sms,
								"type": "eq"
							}
						],
						"Ant_Disconnect": [
							{
								"name": "gps desconectado",
								"normal": 0,
								"alert": 1,
								"enabled": new_Ant_Disconnect_enabled,
								"enabled_email": new_Ant_Disconnect_enabled_email,
								"enabled_sms": new_Ant_Disconnect_enabled_sms,
								"type": "eq"
							}
						],
						"LastKeepAliveTime": [
							{
								"name": "ultimo keep alive",
								"alert": new_keepAlive_max,
								"enabled": new_keepAlive_enabled,
								"enabled_email": new_keepAlive_enabled_email,
								"enabled_sms": new_keepAlive_enabled_sms,
								"type": "keep_alive"
							}
						]
					}
				}

				logsService.alarmasUpdate(obj).then(function () {
					vm.getAlarmasInit();
					$('.modal').modal('hide');
					growl.success('Se actualizaron las alarmas exitosamente.');
				});
			});
		// } else {}		

	};

	/*************************************** SMTP ******************************************/

	vm.smtp_list = [];
	vm.smtp_orig = [];

	vm.getSmtp = function () {
		logsService.getSmtp().then(function (data) {
			vm.smtp_list = data;			
			vm.smtp_orig = angular.copy(vm.smtp_list);
		});
	}	

	vm.editSmtp = function (item) {
		vm.new = angular.copy(item);
		vm.mode = 'edit';
		clearValidation("modalSmtp");
		$('#editSmtp').modal();
	};

	vm.smtpUpdate = function () {
		var inputVacios = validateInput("modalSmtp");
		if (inputVacios === 0) {
			var obj = angular.copy(vm.new);			
			logsService.smtpUpdate(obj).then(function () {
				vm.new = {};
				vm.getSmtp();
				$('.modal').modal('hide');
				growl.success('Se actualizo el cliente externo exitosamente.');
			});		
		} else {}
	};

/*********************************************************************************************/

	// vm.getAll(); LOGS
	vm.getComercios();
	vm.getFabricantes();
	vm.getServices();
	vm.getClientes();  
	vm.getInstaladoras();
	vm.getAlarmasInit();
	vm.getModelos();
	vm.getGateways();
	vm.getSmtp();

/******************************** Click Pestañas *********************************/

  	clickPestañaComercios = function() {
		$('#divInformesMain').addClass('hidden');
		vm.getComercios();
	}

	clickPestañaClientes = function() {
		$('#divInformesMain').addClass('hidden');
		vm.getClientes();		
	}

	clickPestañaUsuarios = function() {
		$('#divInformesMain').addClass('hidden');
		vm.getUsuarios();		
	}

	clickPestañaServicios = function() {
		$('#divInformesMain').addClass('hidden');
		vm.getServices();		
	}

	clickPestañaFabricantes = function() {
		$('#divInformesMain').addClass('hidden');
		vm.getFabricantes();		
	}

	clickPestañaModelos = function() {
		$('#divInformesMain').addClass('hidden');
		vm.getModelos();		
	}

	clickPestañaGateway = function() {
		$('#divInformesMain').addClass('hidden');
		vm.getGateways();		
	}

	clickPestañaSmtp = function() {
		$('#divInformesMain').addClass('hidden');
		vm.getSmtp();		
	}

	clickPestañaInstaladores = function() {
		$('#divInformesMain').addClass('hidden');
		vm.getInstaladoras();
	}

/************************************************************/

	vm.getBatteryStatusName = function(status){
		switch (status) {
			case "0":
				return "Sin Alimentación";
			case "1":
				return "Cargada";
			case "2":
				return "Cargando";
			case "3":
				return "Ausente";
			case "4":
				return "Error";
			default:
				return "-";
		}
		}
		
	interval.cancel(r.intervalRefresh);
	
}
