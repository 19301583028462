angular.module('app').factory('mapaService', ['$rootScope', '$http', mapaService]);

function mapaService(r, h) {
    var service = {
        getBounds: getBounds
    };
    return service;

    function getBounds() {
        return h.get(apiRoute + '/api/config/mapconfig').then(function(resp) {
            return resp.data;
        });
    }

}