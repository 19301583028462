angular.module('app').controller('tachasCtrl', ['$rootScope', 'mapaService', 'tachasService', 'logsService','$sce', '$state','$timeout','growl', 'Upload','$filter','$interval', tachasCtrl]);

function tachasCtrl(r, mapaService, tachasService, logsService, sce, state,t,growl,fupload,f,interval) {
	var vm = this;
	var markers = L.markerClusterGroup();
	var options;  
	var mymapShorcut;
	var group;
	var circle = new L.circleMarker();
	

	vm.userRol = state.current.data.requiresRole;
	vm.new = {};
	vm.images = [];
	vm.list = [];
	vm.orig = [];
	vm.devList = [];
	vm.devOrig = [];
	vm.selected = {};
	vm.tableConfig = {"itemsPerPage":10,"maxPages":5};
	vm.tableConfigEvents = {"itemsPerPage":10,"maxPages":5};
	vm.filterText = '';
	vm.mode = '';
	vm.geoReference = [];

	vm.userClient = r.user.client ? r.user.client.Name : "Admin";
	if (vm.userClient != "Admin") {
		vm.userClientId = r.user.client._id;
	}

	
	vm.filtrarTabla = function(){
		vm.list = f("filter")(vm.orig, vm.filterText);
	};

	vm.export = function() {
		tachasService.exportCsv(vm.list).then(function(data){
			r.downloadCSV(data,'tachas'); 
		});
	}
	
	vm.getAll = function() {

		if (vm.userClient == "Admin") {
			tachasService.getAll().then(function(data) {
				vm.list = data;
				vm.orig = angular.copy(vm.list);
				vm.tooltip(); 		 
			});
		} else {
			tachasService.getAllByClient(vm.userClientId).then(function(data) {
				vm.list = data;
				vm.orig = angular.copy(vm.list);
				vm.tooltip(); 		
			});
		}

	}
	// export the table from the 'Heladeras' tab
	vm.exportTabToCSV = function(tab_name){
		var filter = filterPropertiesHeladeras()
		var header = filter.header
		var name = 'heladeras';
		var list = r.get_values_of_list_generic(vm.list, filter.properties)

		//changed date ISOSstring a dd-mm-yyyy hh-mm
		if( header.includes('Ultima Actualización')){
			list.forEach(function(element,index){
				if( element['status.LastCommunication.value'] !== ''){
					list[index]['status.LastCommunication.value'] = moment(list[index]['status.LastCommunication.value']).format('DD/MM/YYYY, hh:mm')
				}
			})
		}

		//if the active property is not filtered
		if( header.includes('Estado')) {
			list = r.changeStateToString(list)
		}

		const listCSV = r.jsonToCSV(header, list);

		r.downloadCSV(listCSV, name)
	}

	// export the tables from the 'Alarmas' tab
	vm.exportTabAlarmsToCSV= function(name_table){
		const properties = [
			['alias'],						//element.alias
			['attribute_alias'],			//element.attribute_alias
			['value'],						//element.value
			['value_alias'],				//element.value_alias
			['init'],						//element.init
			['elapsed_time'],				//element.elapsed_time
		]
		const header = ['Heladera','Alarma','Valor','Estado','Inicio', 'Duración']
		var name = '';
		var list = [];

		switch(name_table){
			case 'active_advertencia':
				list = r.get_values_of_list_generic(vm.list_active_advertencia, properties)
				name = 'alarmas_advertencias_activas';
				break;
			case 'finished_advertencia':
				list = r.get_values_of_list_generic(vm.list_finished_advertencia, properties)
				name = 'alarmas_advertencias_finalizadas';
				break;
			case 'active_alarms':
				list = r.get_values_of_list_generic(vm.list_active_alarms, properties)
				name = 'alarmas_alarmas_activas';
				break;
			case 'finished_alarms':
				list = r.get_values_of_list_generic(vm.list_finished_alarms, properties)
				name = 'alarmas_alarmas_finalizadas';
				break;
			default:
				console.log('Invalid table')
				return;
		}
		
		const listCSV = r.jsonToCSV(header, list);

		r.downloadCSV(listCSV, name)
	}
	// export the tables from the 'Eventos' tab
	vm.exportTabEventosToCSV = function(name_table){
		var properties = [
			['uid'],
			['alias'],
			['value'],
			['value_alias'],
			['datetime']
		]
		var name = '';
		var list = [];
		var header = ['UID', 'Cliente', 'Valor', 'Estado', 'Fecha y Hora'];

		switch(name_table){
			case 'tab_general_temperatura':
				header[2] = header[2] + '[°C]'
				list = r.get_values_of_list_generic(vm.list_general_Temperature, properties)
				name = 'eventos_general_temperatura';
				break;
			case 'tab_general_corriente':
				header[2] = header[2] + '[A]'
				list = r.get_values_of_list_generic(vm.list_general_Current, properties)
				name = 'eventos_general_corriente';
				break;
			case 'tab_general_voltage':
				header[2] = header[2] + '[V]';
				list = r.get_values_of_list_generic(vm.list_general_Voltage, properties)
				name = 'general_voltage';
				break;
			case 'tab_general_energia':
				list = r.get_values_of_list_generic(vm.list_general_PowerSupply, properties)
				name = 'eventos_general_energia';
				break;
			case 'tab_general_bateria':
				list = r.get_values_of_list_generic(vm.list_general_Battery, properties)
				name = 'eventos_general_bateria';
				break;
			case 'tab_general_alimentacion':
				list = r.get_values_of_list_generic(vm.list_general_Alim_Disconnect, properties)
				name = 'eventos_general_alimentacion';
				break;
			case 'tab_general_nivel_notificacion':
				list = r.get_values_of_list_generic(vm.list_general_NotificationLevel, properties)
				name = 'eventos_general_nivel_notificacion';
				break;
			case 'tab_general_keep_alive':
				list = r.get_values_of_list_generic(vm.list_general_LastKeepAlive, properties)
				name = 'eventos_general_ultimo_keep_alive';
				break;
			case 'tab_gps_desconectado':
				list = r.get_values_of_list_generic(vm.list_gps_Ant_Disconnect, properties)
				name = 'eventos_gps_desconectado';
				break;
			case 'tab_gps_ubicacion':
				list = r.get_values_of_list_generic(vm.list_gps_GPS, properties)
				name = 'eventos_gps_ubicacion';
				break;
			case 'tab_potencia_activa':
				header[2] = header[2] + '[W]'
				list = r.get_values_of_list_generic(vm.list_potencia_ActivePower, properties)
				name = 'eventos_potencia_activa';
				break;
			case 'tab_potencia_aparente':
				header[2] = header[2] + '[VA]'
				list = r.get_values_of_list_generic(vm.list_potencia_AparentPower, properties)
				name = 'eventos_potencia_aparente';
				break;
			case 'tab_potencia_reactiva':
				header[2] = header[2] + '[VAR]'
				list = r.get_values_of_list_generic(vm.list_potencia_ReactivePower, properties)
				name = 'eventos_potencia_reactiva';
				break;
			case 'tab_potencia_coseno':
				list = r.get_values_of_list_generic(vm.list_potencia_CosPhi, properties)
				name = 'eventos_general_coseno_phi';
				break;
			case 'tab_potencia_kWh':
				header[2] = header[2] + '[kWh]'
				list = r.get_values_of_list_generic(vm.list_potencia_kWh, properties)
				name = 'eventos_consumo_kWh';
				break;
			case 'tab_potencia_kWh_cantidad':
				header[2] = header[2] + '[$]'
				list = r.get_values_of_list_generic(vm.list_potencia_kWhAmount, properties)
				name = 'eventos_consumo_mensual';
				break;
			case 'tab_puerta_estado':
				header[2] = header[2] + '[$]'
				list = r.get_values_of_list_generic(vm.list_puerta_DoorState, properties)
				name = 'eventos_puerta_estado';
				break;
			case 'tab_puerta_tiempo_apertura':
				header[2] = header[2] + '[seg]'
				list = r.get_values_of_list_generic(vm.list_puerta_DoorOpenTime, properties)
				name = 'eventos_puerta_tiempo_apertura';
				break;	
			case 'tab_puerta_apertura_diaria':
				list = r.get_values_of_list_generic(vm.list_puerta_DoorOpenCount, properties)
				name = 'eventos_puerta_apertura_diaria';
				break;
			case 'tab_hardware_inclinacion':
				list = r.get_values_of_list_generic(vm.list_hardware_Movement, properties)
				name = 'eventos_hardware_inclinacion';
				break;
			case 'tab_gateway_abierto':
				list = r.get_values_of_list_generic(vm.list_hardware_TampersInt, properties)
				name = 'eventos_gateway_abierto';
				break;
			case 'tab_gateway_extraido':
				list = r.get_values_of_list_generic(vm.list_hardware_TampersExt, properties)
				name = 'eventos_gateway_extraido';
				break;
			case 'tab_cassete_extraido':
				list = r.get_values_of_list_generic(vm.list_hardware_TampersController, properties)
				name = 'eventos_cassete_extraido';
				break;
			case 'tab_gsm_signal':
				header[2] = header[2] + '[dBm]'
				list = r.get_values_of_list_generic(vm.list_hardware_GSM_Signal, properties)
				name = 'eventos_GSM_signal';
				break;
			case 'tab_firmware_version':
				properties = [['uid'],['alias'],['value'],['datetime']]
				header = ['UID', 'Cliente', 'Valor', 'Fecha y Hora']
				list = r.get_values_of_list_generic(vm.list_hardware_FirmwareVersion, properties)
				name = 'eventos_firmware_version';
				break;
			case 'tab_modo_programacion':
				list = r.get_values_of_list_generic(vm.list_comando_ProgStatus, properties)
				name = 'eventos_modo_programacion';
				break;
			case 'tab_ultimo_comando':
				list = r.get_values_of_list_generic(vm.list_comando_ProgCommand, properties)
				name = 'eventos_ultimo_comando';
				break;
			case 'tab_ultimo_valor':
				list = r.get_values_of_list_generic(vm.list_comando_ProgValue, properties)
				name = 'eventos_ultimo_valor';
				break;
			case 'tab_imagenes':
				header = ['UID', 'Cliente', 'Fecha y hora']
				properties = [['uid'], ['alias'], ['datetime']]
				list = r.get_values_of_list_generic(vm.list_imagenes, properties)
				name = 'eventos_imagenes';
				break;
			default:
				console.log('Parametro, tab_name invalido')
				return;
		}

		const listCSV = r.jsonToCSV(header, list);

		r.downloadCSV(listCSV, name)
	}

	function filterPropertiesHeladeras(){
		properties = []
		header = []
		if(vm.tableHeladeraConfigVM.ALIAS === true) {
			header.push('Nombre')
			properties.push(['Alias'])					//element.Alias
		}
		if(vm.tableHeladeraConfigVM.CLIENT_NAME === true) {
			header.push('Cliente')
			properties.push(['client', 'Name'])			//element.cliente.Name
		}
		if(vm.tableHeladeraConfigVM.GROUP_NAME === true) {
			header.push('Locación')
			properties.push(['group', 'Name'])			//element.group.Name
		}
		if(vm.tableHeladeraConfigVM.ADRESS === true) {
			header.push('Dirección')
			properties.push(['group', 'Address'])		//element.group.Address
		}
		if(vm.tableHeladeraConfigVM.MANUFACTURER_NAME === true) {
			header.push('Marca')
			properties.push(['model','manufacturer','name'])	//element.model.manufacturer.name
		}
		if(vm.tableHeladeraConfigVM.MODEL_ID === true) {
			header.push('Modelo')
			properties.push(['model', 'ID'])			//element.model.ID
		}
		if(vm.tableHeladeraConfigVM.SERIAL_NUMBER === true) {
			header.push('N. de serie')
			properties.push(['SerialNumber'])			//element.SerialNumber
		}
		if(vm.tableHeladeraConfigVM.GATEWAY_VERSION === true) {
			header.push('Gateway')
			properties.push(['sensor', 'GatewayVersion'])	//element.sensor.GatewayVersion
		}
		if(vm.tableHeladeraConfigVM.STATUS === true) {
			header.push('Estado')
			properties.push(['active'])						//element.active
			properties.push(['status', 'mainStatus'])		//element.status.mainStatus
		}
		if(vm.tableHeladeraConfigVM.LAST_COMM === true) {
			header.push('Ultima Actualización')
			properties.push(['status', 'LastCommunication', 'value'])	//element.status.LastCommunication.value
		}	

		return {header: header, properties: properties};
	}


	function optionsSetup (origin ,item) {   

		$('#select_edit_cliente').empty();
		$('#select_edit_comercio').empty();		
		$('#select_edit_modelo').empty();
		$('#select_edit_servicios').empty();
		$('#select_edit_gateway').empty();


		if (origin == "edit") {
			tachasService.getSensableOptions().then(function(data) {
				
				data.clients.forEach(function(el) {
					if (item.client._id != undefined) {
						$('#select_edit_cliente').append("<option value="+el._id+">"+el.Name+"</option>").val(item.client._id);
					} else {
						$('#select_edit_cliente').append("<option value="+el._id+">"+el.Name+"</option>");
					}
				});
	
				data.groups.forEach(function(el) {
					if (item.group._id != undefined) {
						$('#select_edit_comercio').append("<option value="+el._id+">"+el.Name+"</option>").val(item.group._id);
					} else {
						$('#select_edit_comercio').append("<option value="+el._id+">"+el.Name+"</option>");
					}
				});
	
				data.services.forEach(function(el) {					
					if (item.service._id != undefined) {
						$('#select_edit_servicios').append("<option value="+el._id+">"+el.Name+"</option>").val(item.service._id);
					} else {
						$('#select_edit_servicios').append("<option value="+el._id+">"+el.Name+"</option>");
					}
				});
	
				data.models.forEach(function(el) {
					if (item.model._id != undefined) {
						$('#select_edit_modelo').append("<option value="+el._id+">"+el.ID+"</option>").val(item.model._id);
					} else {
						$('#select_edit_modelo').append("<option value="+el._id+">"+el.ID+"</option>");
					}
				});
			
				$('#label_edit_gateway').empty();
				if (item.sensor != undefined) {
					$('#label_edit_gateway').append("Gateway Actual ("+item.sensor.SerialNumber+")");
					$('#select_edit_gateway').append("<option value="+item.sensor._id+">"+item.sensor.SerialNumber+" ("+ item.sensor.GatewayVersion  +")</option>");

				} else {
					$('#label_edit_gateway').append("Gateway Actual (Sin Gateway)");
				}
				
				$('#select_edit_gateway').append("<option value="+null+">Sin Gateway</option>");

				data.sensors.forEach(function(el) {
					$('#select_edit_gateway').append("<option value="+el._id+">"+el.SerialNumber+" ("+ el.GatewayVersion  +")</option>");
					// if (item.sensor != undefined) {
					// 	$('#select_edit_gateway').append("<option value="+el._id+">"+el.SerialNumber+" ("+ el.GatewayVersion  +")</option>").val(item.sensor._id);
					// } else {
					// 	$('#select_edit_gateway').append("<option value="+el._id+">"+el.SerialNumber+" ("+ el.GatewayVersion  +")</option>");
					// }
					
				});
			});
				
		} else {
			tachasService.getSensableOptions().then(function(data) {

				data.clients.forEach(function(el) {
					$('#select_edit_cliente').append("<option value="+el._id+">"+el.Name+"</option>")
				});
	
				data.groups.forEach(function(el) {
					$('#select_edit_comercio').append("<option value="+el._id+">"+el.Name+"</option>")
				});
	
				data.services.forEach(function(el) {
					$('#select_edit_servicios').append("<option value="+el._id+">"+el.Name+"</option>")
				});
	
				data.models.forEach(function(el) {
					$('#select_edit_modelo').append("<option value="+el._id+">"+el.ID+"</option>")
				});

				$('#select_edit_gateway').append("<option value="+null+">Sin Gateway</option>");
				$('#label_edit_gateway').empty().append("Gateway Nº de serie");				

				data.sensors.forEach(function(el) {
					$('#select_edit_gateway').append("<option value="+el._id+">"+el.SerialNumber+" ("+ el.GatewayVersion  +")</option>")
				});
				
			}); 
		}		

	}	

	function clearValidation() {
	  // Alias	
		$('#spanAlias').addClass('hidden');
		$('#inputAlias').removeClass('inputVacio inputLleno');
	  // Nº de serie
		$('#spanNSerie').addClass('hidden');
		$('#inputNSerie').removeClass('inputVacio inputLleno');
	  // UID
		$('#spanUID').addClass('hidden');
		$('#inputUID').removeClass('inputVacio inputLleno');
	  // Gateway	
		$('#spanGateway').addClass('hidden');
		$('#select_edit_gateway').removeClass('inputVacio inputLleno');
	  // Cliente
		$('#spanCliente').addClass('hidden');
		$('#select_edit_cliente').removeClass('inputVacio inputLleno');
	  // Comercio
		$('#spanComercio').addClass('hidden');
		$('#select_edit_comercio').removeClass('inputVacio inputLleno');	  
	  // Modelo
		$('#spanModelo').addClass('hidden');
		$('#select_edit_modelo').removeClass('inputVacio inputLleno');
	  // Services
		$('#spanServicios').addClass('hidden');
		$('#select_edit_servicios').removeClass('inputVacio inputLleno');
	  // Fabricante
		$('#spanManufacturingDate').addClass('hidden');
		$('#inputManufacturingDate').removeClass('inputVacio inputLleno');
	  // Instalador
		$('#spanInstalationDate').addClass('hidden');
		$('#inputInstalationDate').removeClass('inputVacio inputLleno');
	}
		
	function validateSelect(userClient) {
		var selectVacios = 0;		

			if ($('#select_edit_comercio').val() == null || $('#select_edit_comercio').val() == undefined) {
				$('#select_edit_comercio').addClass('inputVacio').change(function() {
					if ($('#select_edit_comercio').val() == "" ) {
						$('#select_edit_comercio').removeClass('inputLleno').addClass('inputVacio');
						$('#spanComercio').removeClass('hidden');		
					} else {
						$('#select_edit_comercio').removeClass('inputVacio').addClass('inputLleno');
						$('#spanComercio').addClass('hidden');
					}					
				});
				$('#spanComercio').removeClass('hidden');
				selectVacios++
			} else {}

			if( userClient == "Admin" ){
				if ($('#select_edit_cliente').val() == null || $('#select_edit_cliente').val() == undefined) {
					$('#select_edit_cliente').addClass('inputVacio').change(function() {
						if ($('#select_edit_cliente').val() == "" ) {
							$('#select_edit_cliente').removeClass('inputLleno').addClass('inputVacio');
							$('#spanCliente').removeClass('hidden');		
						} else {
							$('#select_edit_cliente').removeClass('inputVacio').addClass('inputLleno');
							$('#spanCliente').addClass('hidden');
						}					
					});
					$('#spanCliente').removeClass('hidden');
					selectVacios++
				} else {}
			}

			if ($('#select_edit_gateway').val() == null || $('#select_edit_gateway').val() == undefined) {
				$('#select_edit_gateway').addClass('inputVacio').change(function() {
					if ($('#select_edit_gateway').val() == "" ) {
						$('#select_edit_gateway').removeClass('inputLleno').addClass('inputVacio');
						$('#spanGateway').removeClass('hidden');		
					} else {
						$('#select_edit_gateway').removeClass('inputVacio').addClass('inputLleno');
						$('#spanGateway').addClass('hidden');
					}					
				});
				$('#spanGateway').removeClass('hidden');
				selectVacios++
			} else {}
		
			if ($('#select_edit_modelo').val() == null || $('#select_edit_modelo').val() == undefined) {
				$('#select_edit_modelo').addClass('inputVacio').change(function() {
					if ($('#select_edit_modelo').val() == "" ) {
						$('#select_edit_modelo').removeClass('inputLleno').addClass('inputVacio');
						$('#spanModelo').removeClass('hidden');		
					} else {
						$('#select_edit_modelo').removeClass('inputVacio').addClass('inputLleno');
						$('#spanModelo').addClass('hidden');
					}					
				});
				$('#spanModelo').removeClass('hidden');
				selectVacios++
			} else {}

			if ($('#select_edit_servicios').val() == null || $('#select_edit_servicios').val() == undefined) {
				$('#select_edit_servicios').addClass('inputVacio').change(function() {
					if ($('#select_edit_servicios').val() == "" ) {
						$('#select_edit_servicios').removeClass('inputLleno').addClass('inputVacio');
						$('#spanServicios').removeClass('hidden');		
					} else {
						$('#select_edit_servicios').removeClass('inputVacio').addClass('inputLleno');
						$('#spanServicios').addClass('hidden');
					}					
				});
				$('#spanServicios').removeClass('hidden');
				selectVacios++
			} else {}

		return selectVacios;
	}

	function cssColorNotificationStatus(nameAlarm, status) {
		
			if ( status == 0) {
				$('#span_'+nameAlarm).css('color','#28a745');
			} else if (status == 1) {
				$('#span_'+nameAlarm).css('color','#ffc107');
			} else if (status == 2) {
				$('#span_'+nameAlarm).css('color','#dc3545');
			} else {}
		
		
	}
	
  /************************************************************************************************/

	vm.newTacha = function(){
		vm.new = {};
		vm.mode = 'new';

		optionsSetup("new");	
		clearValidation();

		$('#edit').modal();
	}
	
	vm.viewCmt = function(item) {
		vm.new = angular.copy(item);
		$('#comments').modal();
	};
	
  // LUPA VER ESTADO	
	vm.view = function(item) { 		
		vm.new = angular.copy(item);		
		vm.mode = 'view';	
		
	  // Main status

		$('#span_mainStatus').css('color','&#x25C9');
		$('#span_PowerSupply').css('color','&#x25C9');
		$('#span_Battery').css('color','&#x25C9');
		$('#span_Temperature').css('color','&#x25C9');
		$('#span_Current').css('color','&#x25C9');
		$('#span_Voltage').css('color','&#x25C9');
		$('#span_ActivePower').css('color','&#x25C9');
		$('#span_AparentPower').css('color','&#x25C9');
		$('#span_ReactivePower').css('color','&#x25C9');
		$('#span_CosPhi').css('color','&#x25C9');
		$('#span_Kwh').css('color','&#x25C9');
		$('#span_KwhAmount').css('color','&#x25C9');
		$('#span_DoorState').css('color','&#x25C9');
		$('#span_DoorOpenCount').css('color','&#x25C9');
		$('#span_DoorOpenTime').css('color','&#x25C9');
		$('#span_GSM_Signal').css('color','&#x25C9');
		$('#span_Ant_Disconnect').css('color','&#x25C9');
		$('#span_Geocerca').css('color','&#x25C9');
		$('#span_Movement').css('color','&#x25C9');
		$('#span_ProgCommand').css('color','&#x25C9');
		$('#span_TampersInt').css('color','&#x25C9');
		$('#span_TampersExt').css('color','&#x25C9');
		$('#span_TampersController').css('color','&#x25C9');


	    if (vm.new.status.LastCommunication.value.length > 0 ) {
			cssColorNotificationStatus("mainStatus", vm.new.status.mainStatus);	     
			cssColorNotificationStatus("PowerSupply", vm.new.status.PowerSupply.notificationStatus);
			cssColorNotificationStatus("Battery", vm.new.status.Battery.notificationStatus);
			cssColorNotificationStatus("Temperature", vm.new.status.Temperature.notificationStatus);
			cssColorNotificationStatus("Current", vm.new.status.Current.notificationStatus);
			cssColorNotificationStatus("Voltage", vm.new.status.Voltage.notificationStatus);
			cssColorNotificationStatus("ActivePower", vm.new.status.ActivePower.notificationStatus);
			cssColorNotificationStatus("AparentPower", vm.new.status.AparentPower.notificationStatus);
			cssColorNotificationStatus("ReactivePower", vm.new.status.ReactivePower.notificationStatus);
			cssColorNotificationStatus("CosPhi", vm.new.status.CosPhi.notificationStatus);
			cssColorNotificationStatus("Kwh", vm.new.status.Kwh.notificationStatus);
			cssColorNotificationStatus("KwhAmount", vm.new.status.KwhAmount.notificationStatus);
			cssColorNotificationStatus("DoorState", vm.new.status.DoorState.notificationStatus);
			cssColorNotificationStatus("DoorOpenCount", vm.new.status.DoorOpenCount.notificationStatus);
			cssColorNotificationStatus("DoorOpenTime", vm.new.status.DoorOpenTime.notificationStatus);
			cssColorNotificationStatus("GSM_Signal", vm.new.status.GSM_Signal.notificationStatus);
			cssColorNotificationStatus("Ant_Disconnect", vm.new.status.Ant_Disconnect.notificationStatus);
			cssColorNotificationStatus("Geocerca", vm.new.status.GPS.notificationStatus);
			cssColorNotificationStatus("Movement", vm.new.status.Movement.notificationStatus);
			cssColorNotificationStatus("ProgCommand", vm.new.status.ProgCommand.notificationStatus);
			cssColorNotificationStatus("TampersInt", vm.new.status.TampersInt.notificationStatus);
			cssColorNotificationStatus("TampersExt", vm.new.status.TampersExt.notificationStatus);
			cssColorNotificationStatus("TampersController", vm.new.status.TampersController.notificationStatus);
		} else {}

	  // HELADERA DESACTIVADA
		if ( vm.new.active == false) {
			$('#span_mainStatus').css('color','#ccc');
			$('#span_estado').removeClass('hidden');
		} else {
			$('#span_estado').addClass('hidden');
		}
		
		$('#general_status').modal();

	};
	
	vm.edit = function(item) {
		vm.new = angular.copy(item); 
		vm.mode = 'edit';
		vm.item = item;		
		optionsSetup("edit",item);	
		clearValidation();
		
		$('#inputManufacturingDate').val(item.ManufacturingDate);
		$('#inputInstalationDate').val(item.InstalationDate);		

		$('#edit').modal();
	};	
	
	vm.history = function(item) {
		vm.new = angular.copy(item);
		vm.mode = 'history'; 
		$('#history').modal();
	};	 
	
	vm.graph = function(item) {
		vm.new = angular.copy(item);
		vm.mode = 'graph';
		$('#graph').modal();
	}; 

 /**************************************************** SHORCUT GEOLOCATION *************************************************/
	  
 	vm.initMap = function() {
		
		mapaService.getBounds().then(function(mapconfig){

			if (vm.userClient != "Admin") {
				vm.userClientId = r.user.client._id;
				vm.geoReference.push(parseFloat(r.user.client.geoReference.split(',')[0]));
				vm.geoReference.push(parseFloat(r.user.client.geoReference.split(',')[1]));
			
			} else {
				vm.geoReference.push(-34.63525);
				vm.geoReference.push(-58.37621333333333);    
			}

			options = {
			  attributionControl: false,
			  layers: [
				L.tileLayer(mapconfig.tile_server, { maxZoom: 17, minZoom: 11, attribution: '...' }),
			],
			setView: true,
			zoom: 12,
			zoomControl: true,
			// bounds: mapconfig.bounds[0],
			// maxBounds : mapconfig.bounds[0],
			center: L.latLng(vm.geoReference)
		  };
		  
		  mymapShorcut = L.map('mapIdModalGeolocation', options);

		 }); 
	}

	vm.geolocation = function(item) {
		
		logsService.getAlarmas().then(function(data) {

			vm.new = angular.copy(item);
			vm.mode = 'geolocation';	
			var radioAlert = parseInt(data[0].notifications.GPS[0].alert);	
					
			mymapShorcut.removeLayer(circle);
			markers.clearLayers();

			if (item.status.mainStatus == 0) {
				$('#divIconActualState').empty().append('<img src="/css/images/marker-icon-verde.png" alt="" class="iconoGeoActual"></img>');				
			} else if (item.status.mainStatus == 1) {
				$('#divIconActualState').empty().append('<img src="/css/images/marker-icon-amarillo.png" alt="" class="iconoGeoActual"></img>');
			} else if (item.status.mainStatus == 2) {
				$('#divIconActualState').empty().append('<img src="/css/images/marker-icon-rojo.png" alt="" class="iconoGeoActual"></img>');
			} else {}
			
		
			if (item.status.InitGPS.value.coordinates[0] != undefined) {

				var marker_init = L.marker(L.latLng(
					item.status.InitGPS.value.coordinates[0], item.status.InitGPS.value.coordinates[1]),{
					title: "Ubicación inicial"
				});

				circle = L.circle([item.status.InitGPS.value.coordinates[0], item.status.InitGPS.value.coordinates[1]], {
					color: '#20AB56',
					fillColor: '#28a745',
					fillOpacity: 0.3,
					radius: radioAlert  
				});

				markers.addLayer(marker_init);
				mymapShorcut.addLayer(circle); 

				mymapShorcut.setView([item.status.InitGPS.value.coordinates[0], item.status.InitGPS.value.coordinates[1]], 14);

			} else {} 

			if (item.status.GPS.value.coordinates[0] != undefined) {

				var LeafIcon = L.Icon.extend({
					options: {
						shadowUrl: '../css/images/leaf-shadow.png',
						shadowSize:   [50, 64],
						shadowAnchor: [4, 62]
					}
				}); 

				var greenIcon = new LeafIcon({iconUrl: '../css/images/marker-icon-verde.png'}),
					yellowIcon = new LeafIcon({iconUrl: '../css/images/marker-icon-amarillo.png'}),
					redIcon = new LeafIcon({iconUrl: '../css/images/marker-icon-rojo.png'});
				
				if (item.status.mainStatus == 0) {
					var marker_actual = L.marker(L.latLng(
						item.status.GPS.value.coordinates[0], item.status.GPS.value.coordinates[1]),{
						title: "Ubicación actual",
						icon: greenIcon      
					});			
				} else if (item.status.mainStatus == 1) {
					var marker_actual = L.marker(L.latLng(
						item.status.GPS.value.coordinates[0], item.status.GPS.value.coordinates[1]),{
						title: "Ubicación actual",
						icon: yellowIcon       
					});
				} else if (item.status.mainStatus == 2) {
					var marker_actual = L.marker(L.latLng(
						item.status.GPS.value.coordinates[0], item.status.GPS.value.coordinates[1]),{
						title: "Ubicación actual",
						icon: redIcon    
					});
				} else {}				

				markers.addLayer(marker_actual);

			} else {}
			
			mymapShorcut.addLayer(markers);	

			$('#geolocation').modal();

			setInterval(function (){
				mymapShorcut.invalidateSize();
			}, 100);
		});
	};
	
 /***********************************************************************************************************************/

	vm.upload = function(evt) {
		vm.file = evt.target.files[0];
		fupload.upload({
			url: apiRoute + '/api/tachas/import/',
			data: {
				file: vm.file
			},
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('id_token')
			},
			withCredentials: true
		}).then(function(resp) {
			growl.success('Se cargo exitosamente el archivo ' + vm.file.name);
			$('#uploadBtn').val('');
			vm.getAll();
		}, function(resp) {
			$('#uploadBtn').val('');
		}); 
	};
	
	vm.update = function(userClient) {
		clearValidation();
		var inputVacios = 0;
		var selectVacios = validateSelect(userClient);	
		var aliasAvailable = true;
		var UID = {};	
	

		if ($('#inputAlias').val() == "") {
			$('#inputAlias').addClass('inputVacio').change(function() {
				if ($('#inputAlias').val() == "" ) {
					$('#inputAlias').removeClass('inputLleno').addClass('inputVacio');
					$('#spanAlias').removeClass('hidden');		
				} else {
					$('#inputAlias').removeClass('inputVacio').addClass('inputLleno');
					$('#spanAlias').addClass('hidden');
				}				
			  });
			$('#spanAlias').removeClass('hidden');
			inputVacios++
		} else {
			var obj = angular.copy(vm.new);
			for (var i = 0; i < vm.list.length; i++) {
				if(obj._id) {
					if (vm.list[i].Alias == $('#inputAlias').val() && vm.list[i]._id != obj._id) {
						var aliasAvailable = false;
						growl.error('El Nombre ingresado ya esta en uso por otra Heladera.');
						break;
					} else {}
				} else {
					if (vm.list[i].Alias == $('#inputAlias').val()) {
						var aliasAvailable = false;
						growl.error('El Nombre ingresado ya esta en uso por otra Heladera.');
						break;
					} else {}
				}
								
			} 
		}

		if ($('#inputNSerie').val() == "") {
			$('#inputNSerie').addClass('inputVacio').change(function() {
				if ($('#inputNSerie').val() == "" ) {
					$('#inputNSerie').removeClass('inputLleno').addClass('inputVacio');
					$('#spanNSerie').removeClass('hidden');		
				} else {
					$('#inputNSerie').removeClass('inputVacio').addClass('inputLleno');
					$('#spanNSerie').addClass('hidden');
				}					
			  });
			$('#spanNSerie').removeClass('hidden');
			inputVacios++
		} else {}

		if ($('#select_edit_gateway option:selected').text() != "Sin Gateway") {
			UID.value = $('#select_edit_gateway option:selected').text().split(" ")[0]
		} else {
			UID.value = "Sin Gateway";
		}

		if (inputVacios === 0 && selectVacios === 0 && aliasAvailable === true) {
			var obj = angular.copy(vm.new);	
			if(obj._id) {

				var dataUpdate = {
					'_id': obj._id,
					'Alias': obj.Alias,	
					'UID': UID.value,
					'ManufacturingDate': $('#inputManufacturingDate').val(),	
					'InstalationDate': $('#inputInstalationDate').val(),
					'Instalador': $('#inputInstalador').val(),													
					'group': $('#select_edit_comercio').val(),
					'client': $('#select_edit_cliente').val(),
					'sensor': $('#select_edit_gateway').val(),
					'model': $('#select_edit_modelo').val(),					
					'GPS': {
						'lat': $('#input_edit_latitud').val(),
						'lon': $('#input_edit_longitud').val()
						},
					'active': obj.active,
					'service': $('#select_edit_servicios').val()			
				}

				tachasService.update(dataUpdate).then(function() {		
					vm.new = {};
					vm.getAll();
					$('.modal').modal('hide');
					growl.success('Se actualizo heladera exitosamente.');
				});

			} else {
				
				var dataCreate = {
					'Alias': obj.Alias,	
					'ManufacturingDate': $('#inputManufacturingDate').val(),	
					'InstalationDate': $('#inputInstalationDate').val(),
					'Instalador': $('#inputInstalador').val(),					
					'SerialNumber': obj.SerialNumber,
					'UID': UID.value,					
					'group': $('#select_edit_comercio').val(),
					'client': $('#select_edit_cliente').val(),
					'sensor': $('#select_edit_gateway').val(),									
					'model': $('#select_edit_modelo').val(),
					'GPS': {
						'lat': $('#input_edit_latitud').val(),
						'lon': $('#input_edit_longitud').val()
						},
					'active': obj.active,
					'service': $('#select_edit_servicios').val()					
				}
				
				if (dataCreate.sensor == "null") {
					delete dataCreate.sensor;
				}
				
				tachasService.create(dataCreate).then(function() {
					vm.new = {};
					vm.getAll();
					$('.modal').modal('hide');
					growl.success('Se creo heladera exitosamente.');
				}); 	
			}
		} else {
			console.log("============== ELSEEEE");
		}
	} 
 /********************************* SHORTCUT IMAGES **************************************/
 

 vm.imagenes = function(item) { 

	$('#spanAliasImg').empty().text(item.Alias);

	tachasService.getImages(item).then(function(images){		

		$('#divMainImagenes').empty();
		
		if (images.length != 0) {			
			for(var j = 0; j < images.length; j++){			
				if (images[j].data.length != 0) {
					var imageParsed = "data:image/jpg;base64," + images[j].data;
					var dateCreated = images[j].dt_create;
					$('#divMainImagenes').append('<div class="cardImage" >' + 
													'<figure><img src="'+ imageParsed +'" class="imageCamera" id="image'+ j + '"><figcaption class="figure-caption fecha-image"><b>'+ dateCreated +'</b></figcaption></figure>' +
												 '</div>');
				} else {}
			}	
		} else  {
			$('#divMainImagenes').append('<h4>No se encontraron imágenes.<h4>');
		}
			
	});

	$('#modalImagenes').modal();

}

/*********************************** ACTIVE ALARMS ***********************************/
	
	vm.list_active_alarms = [];
	vm.list_active_advertencia = [];
	vm.list_finished_alarms = [];
	vm.list_finished_advertencia = [];

	vm.getAlarmasActivas = function() {

		// Btn's	
		  $('#btnAlarmasActivas').css('opacity','0.5');
		  $('#btnAlarmasFinalizadas').css('opacity','1');		
			  
		// Pestañas
		  $('#tab_alarm_actives').css('display','block');
		  $('#tab_alarm_finished').css('display','none');

		// Pestaña Activas Init
		  $('#li_advertencia_activa').addClass('active');
		  $('#tab_advertencia_activa').addClass('active');
		  $('#tab_advertencia_activa').addClass('in');

		  $('#li_alarm_activa').removeClass('active');
		  $('#tab_alarm_activa').removeClass('active');
		  $('#li_alarm_finished').removeClass('active');
		  $('#tab_alarm_finished_p').removeClass('active');
		  $('#li_advertencia_finished').removeClass('active');
		  $('#tab_advertencia_finished').removeClass('active');
		 
		if (vm.userClient == "Admin") {
			tachasService.getAllActiveAlarms().then(function(data) {

				if (data.Advertencia) {
					vm.list_active_advertencia.lenght = 0;
					vm.list_active_advertencia = data.Advertencia;				
				} else {}
	
				if (data.Alerta) {
					vm.list_active_alarms.lenght = 0;
					vm.list_active_alarms = data.Alerta;
				} else {}
				
			});
		} else {
			tachasService.getAllActiveAlarmsByClient(vm.userClientId).then(function(data) {

				if (data.Advertencia) {
					vm.list_active_advertencia.lenght = 0;
					vm.list_active_advertencia = data.Advertencia;				
				} else {}
	
				if (data.Alerta) {
					vm.list_active_alarms.lenght = 0;
					vm.list_active_alarms = data.Alerta;
				} else {}
				
			});
		}  
		  
	  }	
	
/********************************** FINISHED ALARMS **********************************/
  
	vm.getAlarmasFinalizadas = function() {
  
		// Btn's	
		  $('#btnAlarmasFinalizadas').css('opacity','0.5');
		  $('#btnAlarmasActivas').css('opacity','1');	 	
			  
		// Pestañas
		  $('#tab_alarm_finished').css('display','block');
		  $('#tab_alarm_actives').css('display','none');

		// Pestaña Finalizadas Init
		  $('#li_advertencia_finished').addClass('active');
		  $('#tab_advertencia_finished').addClass('active');
		  $('#tab_advertencia_finished').addClass('in');

		  $('#li_alarm_activa').removeClass('active');
          $('#tab_alarm_activa').removeClass('active');	
		  $('#li_advertencia_activa').removeClass('active');
          $('#tab_advertencia_activa').removeClass('active');
		  $('#li_alarm_finished').removeClass('active');
		  $('#tab_alarm_finished_p').removeClass('active');

		if (vm.userClient == "Admin") {
			tachasService.getAllFinishedAlarms().then(function(data) {
	
				if (data.Advertencia) {
					vm.list_finished_advertencia.lenght = 0;
					vm.list_finished_advertencia = data.Advertencia;				
				} else {}
	
				if (data.Alerta) {
					vm.list_finished_alarms.lenght = 0;
					vm.list_finished_alarms = data.Alerta;
				} else {}
	
			});	
		} else {
			tachasService.getAllFinishedAlarmsByClient(vm.userClientId).then(function(data) {
	
				if (data.Advertencia) {
					vm.list_finished_advertencia.lenght = 0;
					vm.list_finished_advertencia = data.Advertencia;				
				} else {}
	
				if (data.Alerta) {
					vm.list_finished_alarms.lenght = 0;
					vm.list_finished_alarms = data.Alerta;
				} else {}
	
			});	
		}		

	} 

	vm.getAlarmasFinalizadasDateRange = function (type) {

		if (type == "Alarm") {
			var dateFromAlarms = $('#inputFromFinishedAlarm').val() 
			var dateToAlarms = $('#inputToFinishedAlarm').val();

			tachasService.getAllFinishedAlarmsDateRange(dateFromAlarms, dateToAlarms).then(function(data) {
   
			   if (data.Alerta) {
				   vm.list_finished_alarms.lenght = 0;
				   vm.list_finished_alarms = data.Alerta;
			   } else {}
   
		   });

		} else {
			var dateFromAlarms = $('#inputFromFinishedAdvertencia').val() 
			var dateToAlarms = $('#inputToFinishedAdvertencia').val();

			tachasService.getAllFinishedAlarmsDateRange(dateFromAlarms, dateToAlarms).then(function(data) {
			
				if (data.Advertencia) {
					vm.list_finished_advertencia.lenght = 0;
				   vm.list_finished_advertencia = data.Advertencia;				
			   } else {}
			  
		   });
			
		}	
		
	}

 /********************************** EVENTS ALL ************************************/
   
   // General	
	vm.list_general_Temperature = [];
	vm.list_general_Voltage = [];
	vm.list_general_Current = [];
	vm.list_general_PowerSupply = [];
	vm.list_general_Battery = [];
	vm.list_general_Alim_Disconnect = [];
	vm.list_general_NotificationLevel = []; 	
	vm.list_general_LastKeepAlive = [];
	
   // GPS
   	vm.list_gps_Ant_Disconnect= [];
	vm.list_gps_GPS= [];
	
   // Potencia
    vm.list_potencia_ActivePower= [];
	vm.list_potencia_AparentPower= [];	
	vm.list_potencia_ReactivePower= [];
	vm.list_potencia_CosPhi= [];
	vm.list_potencia_kWh= [];
	vm.list_potencia_kWhAmount= [];

   // Puerta
	vm.list_puerta_DoorState = [];
	vm.list_puerta_DoorOpenTime = [];
	vm.list_puerta_DoorOpenCount = [];

   // Hardware
	vm.list_hardware_Movement = []; // Inclinacion
	vm.list_hardware_TampersInt = [];
	vm.list_hardware_TampersExt = [];
	vm.list_hardware_TampersController = [];
	vm.list_hardware_Per_Disconnect = [];
	vm.list_hardware_GSM_Signal = [];
	vm.list_hardware_FirmwareVersion = [];
 
   // Comandos
	vm.list_comando_ProgStatus = [];
	vm.list_comando_ProgCommand = [];
	vm.list_comando_ProgValue = [];
	
   // Imagenes
	vm.list_imagenes = [];

	vm.getAllEvents = function() {		

		if (vm.userClient == "Admin") {
		
			tachasService.getAllEvents().then(function(data) {		

				// General	
				vm.list_general_Temperature = [];
				vm.list_general_Voltage = [];
				vm.list_general_Current = [];
				vm.list_general_PowerSupply = [];
				vm.list_general_Battery = [];
				vm.list_general_Alim_Disconnect = [];
				vm.list_general_NotificationLevel = []; 	
				vm.list_general_LastKeepAlive = [];
				
			// GPS
				vm.list_gps_Ant_Disconnect= [];
				vm.list_gps_GPS= [];
				
			// Potencia
				vm.list_potencia_ActivePower= [];
				vm.list_potencia_AparentPower= [];	
				vm.list_potencia_ReactivePower= [];
				vm.list_potencia_CosPhi= [];
				vm.list_potencia_kWh= [];
				vm.list_potencia_kWhAmount= [];
	
			// Puerta
				vm.list_puerta_DoorState = [];
				vm.list_puerta_DoorOpenTime = [];
				vm.list_puerta_DoorOpenCount = [];
	
			// Hardware
				vm.list_hardware_Movement = []; // Inclinacion
				vm.list_hardware_TampersInt = [];
				vm.list_hardware_TampersExt = [];
				vm.list_hardware_TampersController = [];
				vm.list_hardware_Per_Disconnect = [];
				vm.list_hardware_GSM_Signal = [];
				vm.list_hardware_FirmwareVersion = [];
			
			// Comandos
				vm.list_comando_ProgStatus = [];
				vm.list_comando_ProgCommand = [];
				vm.list_comando_ProgValue = [];

			// Imagenes
				vm.list_imagenes = [];
				
			/////////////////////////////////////////////////////////////////

			// General
				if (data.General != undefined && data.General.Temperatura != undefined) {
					vm.list_general_Temperature.lenght = 0;
					vm.list_general_Temperature = data.General.Temperatura;
				} else {}

				if (data.General != undefined && data.General.Corriente != undefined) {
					vm.list_general_Current.lenght = 0;
					vm.list_general_Current = data.General.Corriente;				
				} else {}

				if (data.General != undefined && data.General.Voltage != undefined) {
					vm.list_general_Voltage.lenght = 0;
					vm.list_general_Voltage = data.General.Voltage;
				} else {}

				if (data.General != undefined && data.General.Energía != undefined) { 
					vm.list_general_PowerSupply.lenght = 0;
					vm.list_general_PowerSupply = data.General.Energía;
				} else {}

				if (data.General != undefined && data.General.Batería != undefined) {
					vm.list_general_Battery.lenght = 0;
					vm.list_general_Battery = data.General.Batería;		
				} else {}

				if (data.General != undefined && data.General["Alimentación Desconectada"] != undefined) { 
					vm.list_general_Alim_Disconnect.lenght = 0;
					vm.list_general_Alim_Disconnect = data.General["Alimentación Desconectada"];				
				} else {}

				if (data.General != undefined && data.General["Nivel de Notificación"] != undefined) {
					vm.list_general_NotificationLevel.lenght = 0;
					vm.list_general_NotificationLevel = data.General["Nivel de Notificación"];			
				} else {}
		
				if (data.General != undefined && data.General["Ultimo KeepAlive"] != undefined) {
					vm.list_general_LastKeepAlive.lenght = 0;
					vm.list_general_LastKeepAlive = data.General["Ultimo KeepAlive"];			
				} else {}
				
			// GPS		
				if (data.GPS != undefined && data.GPS["GPS Desconectado"] != undefined) { 
					vm.list_gps_Ant_Disconnect.lenght = 0;
					vm.list_gps_Ant_Disconnect = data.GPS["GPS Desconectado"];
				} else {}

				if (data.GPS != undefined && data.GPS.Ubicación != undefined) {
					vm.list_gps_GPS.lenght = 0;
					vm.list_gps_GPS = data.GPS.Ubicación;		
				} else {}
				
			// Potencias
				if (data.Potencias != undefined && data.Potencias["Potencia Activa"] != undefined) {				
					vm.list_potencia_ActivePower.lenght = 0;
					vm.list_potencia_ActivePower = data.Potencias["Potencia Activa"];
				} else {}

				if (data.Potencias != undefined && data.Potencias["Potencia Aparente"] != undefined) {
					vm.list_potencia_AparentPower.lenght = 0;
					vm.list_potencia_AparentPower = data.Potencias["Potencia Aparente"];
				} else {}

				if (data.Potencias != undefined && data.Potencias["Potencia Reactiva"] != undefined) {
					vm.list_potencia_ReactivePower.lenght = 0;
					vm.list_potencia_ReactivePower = data.Potencias["Potencia Reactiva"];
				} else {}

				if (data.Potencias != undefined && data.Potencias["Coseno Phi"] != undefined) {
					vm.list_potencia_CosPhi.lenght = 0;
					vm.list_potencia_CosPhi = data.Potencias["Coseno Phi"];
				} else {}

				if (data.Potencias != undefined && data.Potencias["Consumo eléctrico [kWh]"] != undefined) {
					vm.list_potencia_kWh.lenght = 0;
					vm.list_potencia_kWh = data.Potencias["Consumo eléctrico [kWh]"];
				} else {}

				if (data.Potencias != undefined && data.Potencias["Consumo eléctrico [$]"] != undefined) {
					vm.list_potencia_kWhAmount.lenght = 0;
					vm.list_potencia_kWhAmount = data.Potencias["Consumo eléctrico [$]"];
				} else {}
			
			// Puerta
				if (data.Puerta != undefined && data.Puerta["Estado Puerta"] != undefined) { 
					vm.list_puerta_DoorState.lenght = 0;
					vm.list_puerta_DoorState = data.Puerta["Estado Puerta"];
				} else {}

				if (data.Puerta != undefined && data.Puerta["Tiempo de Apertura"] != undefined) { 
					vm.list_puerta_DoorOpenTime.lenght = 0;
					vm.list_puerta_DoorOpenTime = data.Puerta["Tiempo de Apertura"];
				} else {}

				if (data.Puerta != undefined && data.Puerta["Aperturas Diarias"] != undefined) { 
					vm.list_puerta_DoorOpenCount.lenght = 0;
					vm.list_puerta_DoorOpenCount = data.Puerta["Aperturas Diarias"];
				} else {}
			
			// Hardware
				if (data.Hardware != undefined && data.Hardware["Inclinación"] != undefined) { 
					vm.list_hardware_Movement.lenght = 0;
					vm.list_hardware_Movement = data.Hardware["Inclinación"];
				} else {}

				if (data.Hardware != undefined && data.Hardware["Tiempo de Apertura"] != undefined ) { 
					vm.list_hardware_TampersInt.lenght = 0;
					vm.list_hardware_TampersInt = data.Hardware["Tiempo de Apertura"];
				} else {}

				if (data.Hardware != undefined && data.Hardware["Aperturas Diarias"] != undefined) { 
					vm.list_hardware_TampersExt.lenght = 0;
					vm.list_hardware_TampersExt = data.Hardware["Aperturas Diarias"];
				} else {}

				if (data.Hardware != undefined && data.Hardware["Cassette Extraído"] != undefined) { 
					vm.list_hardware_TampersController.lenght = 0;
					vm.list_hardware_TampersController = data.Hardware["Cassette Extraído"];
				} else {}

				if (data.Hardware != undefined && data.Hardware["Señal GSM"] != undefined) { 
					vm.list_hardware_GSM_Signal.lenght = 0;
					vm.list_hardware_GSM_Signal = data.Hardware["Señal GSM"];
				} else {}

				if (data.Hardware != undefined && data.Hardware["Firmware version"] != undefined) { 
					vm.list_hardware_FirmwareVersion.lenght = 0;
					vm.list_hardware_FirmwareVersion = data.Hardware["Firmware version"];
				} else {}				
			
			// Comandos
				if (data.Comandos != undefined && data.Comandos["Modo Programación Activado"] != undefined) { 
					vm.list_comando_ProgStatus.lenght = 0;
					vm.list_comando_ProgStatus = data.Comandos["Modo Programación Activado"];
				} else {}

				if (data.Comandos != undefined && data.Comandos["Ultimo Comando Recibido"] != undefined) { 
					vm.list_comando_ProgCommand.lenght = 0;
					vm.list_comando_ProgCommand = data.Comandos["Ultimo Comando Recibido"];
				} else {}

				if (data.Comandos != undefined && data.Comandos["Ultimo Valor de Comando"] != undefined) { 
					vm.list_comando_ProgValue.lenght = 0;
					vm.list_comando_ProgValue = data.Comandos["Ultimo Valor de Comando"];
				} else {}		

			// Imagenes
				if (data.Photo != undefined && data.Photo["Imagen"] != undefined) { 
					vm.list_imagenes.lenght = 0;
					vm.list_imagenes = data.Photo["Imagen"];
				} else {}
			
			}); 

			vm.getGeneralTab();

		} else {

			tachasService.getAllEventsByClient(vm.userClientId).then(function(data) {		
			
				// General	
				   vm.list_general_Temperature = [];
				   vm.list_general_Voltage = [];
				   vm.list_general_Current = [];
				   vm.list_general_PowerSupply = [];
				   vm.list_general_Battery = [];
				   vm.list_general_Alim_Disconnect = [];
				   vm.list_general_NotificationLevel = []; 	
				   vm.list_general_LastKeepAlive = [];
				   
			   // GPS
				   vm.list_gps_Ant_Disconnect= [];
				   vm.list_gps_GPS= [];
				   
			   // Potencia
				   vm.list_potencia_ActivePower= [];
				   vm.list_potencia_AparentPower= [];	
				   vm.list_potencia_ReactivePower= [];
				   vm.list_potencia_CosPhi= [];
				   vm.list_potencia_kWh= [];
				   vm.list_potencia_kWhAmount= [];
	   
			   // Puerta
				   vm.list_puerta_DoorState = [];
				   vm.list_puerta_DoorOpenTime = [];
				   vm.list_puerta_DoorOpenCount = [];
	   
			   // Hardware
				   vm.list_hardware_Movement = []; // Inclinacion
				   vm.list_hardware_TampersInt = [];
				   vm.list_hardware_TampersExt = [];
				   vm.list_hardware_TampersController = [];
				   vm.list_hardware_Per_Disconnect = [];
				   vm.list_hardware_GSM_Signal = [];
				   vm.list_hardware_FirmwareVersion = [];				   
			   
			   // Comandos
				   vm.list_comando_ProgStatus = [];
				   vm.list_comando_ProgCommand = [];
				   vm.list_comando_ProgValue = [];
	  
			   // Imagenes
				   vm.list_imagenes = [];
				  
				/////////////////////////////////////////////////////////////////
	  
				// General
				  if (data.General != undefined && data.General.Temperatura != undefined) {
					  vm.list_general_Temperature.lenght = 0;
					  vm.list_general_Temperature = data.General.Temperatura;
				  } else {}
	  
				  if (data.General != undefined && data.General.Corriente != undefined) {
					  vm.list_general_Current.lenght = 0;
					  vm.list_general_Current = data.General.Corriente;				
				  } else {}
	  
				  if (data.General != undefined && data.General.Voltage != undefined) {
					  vm.list_general_Voltage.lenght = 0;
					  vm.list_general_Voltage = data.General.Voltage;
				  } else {}
	  
				  if (data.General != undefined && data.General.Energía != undefined) { 
					  vm.list_general_PowerSupply.lenght = 0;
					  vm.list_general_PowerSupply = data.General.Energía;
				  } else {}
	  
				  if (data.General != undefined && data.General.Batería != undefined) {
					  vm.list_general_Battery.lenght = 0;
					  vm.list_general_Battery = data.General.Batería;		
				  } else {}
	  
				  if (data.General != undefined && data.General["Alimentación Desconectada"] != undefined) { 
					  vm.list_general_Alim_Disconnect.lenght = 0;
					  vm.list_general_Alim_Disconnect = data.General["Alimentación Desconectada"];				
				  } else {}
	  
				  if (data.General != undefined && data.General["Nivel de Notificación"] != undefined) {
					  vm.list_general_NotificationLevel.lenght = 0;
					  vm.list_general_NotificationLevel = data.General["Nivel de Notificación"];			
				  } else {}
		  
				  if (data.General != undefined && data.General["Ultimo KeepAlive"] != undefined) {
					  vm.list_general_LastKeepAlive.lenght = 0;
					  vm.list_general_LastKeepAlive = data.General["Ultimo KeepAlive"];			
				  } else {}
				  
			  // GPS		
				  if (data.GPS != undefined && data.GPS["GPS Desconectado"] != undefined) { 
					  vm.list_gps_Ant_Disconnect.lenght = 0;
					  vm.list_gps_Ant_Disconnect = data.GPS["GPS Desconectado"];
				  } else {}
	  
				  if (data.GPS != undefined && data.GPS.Ubicación != undefined) {
					  vm.list_gps_GPS.lenght = 0;
					  vm.list_gps_GPS = data.GPS.Ubicación;		
				  } else {}
				  
			  // Potencias
				  if (data.Potencias != undefined && data.Potencias["Potencia Activa"] != undefined) {				
					  vm.list_potencia_ActivePower.lenght = 0;
					  vm.list_potencia_ActivePower = data.Potencias["Potencia Activa"];
				  } else {}
	  
				  if (data.Potencias != undefined && data.Potencias["Potencia Aparente"] != undefined) {
					  vm.list_potencia_AparentPower.lenght = 0;
					  vm.list_potencia_AparentPower = data.Potencias["Potencia Aparente"];
				  } else {}
	  
				  if (data.Potencias != undefined && data.Potencias["Potencia Reactiva"] != undefined) {
					  vm.list_potencia_ReactivePower.lenght = 0;
					  vm.list_potencia_ReactivePower = data.Potencias["Potencia Reactiva"];
				  } else {}
	  
				  if (data.Potencias != undefined && data.Potencias["Coseno Phi"] != undefined) {
					  vm.list_potencia_CosPhi.lenght = 0;
					  vm.list_potencia_CosPhi = data.Potencias["Coseno Phi"];
				  } else {}
	  
				  if (data.Potencias != undefined && data.Potencias["Consumo eléctrico [kWh]"] != undefined) {
					  vm.list_potencia_kWh.lenght = 0;
					  vm.list_potencia_kWh = data.Potencias["Consumo eléctrico [kWh]"];
				  } else {}
	  
				  if (data.Potencias != undefined && data.Potencias["Consumo eléctrico [$]"] != undefined) {
					  vm.list_potencia_kWhAmount.lenght = 0;
					  vm.list_potencia_kWhAmount = data.Potencias["Consumo eléctrico [$]"];
				  } else {}
			  
			  // Puerta
				  if (data.Puerta != undefined && data.Puerta["Estado Puerta"] != undefined) { 
					  vm.list_puerta_DoorState.lenght = 0;
					  vm.list_puerta_DoorState = data.Puerta["Estado Puerta"];
				  } else {}
	  
				  if (data.Puerta != undefined && data.Puerta["Tiempo de Apertura"] != undefined) { 
					  vm.list_puerta_DoorOpenTime.lenght = 0;
					  vm.list_puerta_DoorOpenTime = data.Puerta["Tiempo de Apertura"];
				  } else {}
	  
				  if (data.Puerta != undefined && data.Puerta["Aperturas Diarias"] != undefined) { 
					  vm.list_puerta_DoorOpenCount.lenght = 0;
					  vm.list_puerta_DoorOpenCount = data.Puerta["Aperturas Diarias"];
				  } else {}
			  
			  // Hardware
				  if (data.Hardware != undefined && data.Hardware["Inclinación"] != undefined) { 
					  vm.list_hardware_Movement.lenght = 0;
					  vm.list_hardware_Movement = data.Hardware["Inclinación"];
				  } else {}
	  
				  if (data.Hardware != undefined && data.Hardware["Tiempo de Apertura"] != undefined ) { 
					  vm.list_hardware_TampersInt.lenght = 0;
					  vm.list_hardware_TampersInt = data.Hardware["Tiempo de Apertura"];
				  } else {}
	  
				  if (data.Hardware != undefined && data.Hardware["Aperturas Diarias"] != undefined) { 
					  vm.list_hardware_TampersExt.lenght = 0;
					  vm.list_hardware_TampersExt = data.Hardware["Aperturas Diarias"];
				  } else {}
	  
				  if (data.Hardware != undefined && data.Hardware["Cassette Extraído"] != undefined) { 
					  vm.list_hardware_TampersController.lenght = 0;
					  vm.list_hardware_TampersController = data.Hardware["Cassette Extraído"];
				  } else {}
	  
				  if (data.Hardware != undefined && data.Hardware["Señal GSM"] != undefined) { 
					  vm.list_hardware_GSM_Signal.lenght = 0;
					  vm.list_hardware_GSM_Signal = data.Hardware["Señal GSM"];
				  } else {}

				  if (data.Hardware != undefined && data.Hardware["Firmware version"] != undefined) { 
					vm.list_hardware_FirmwareVersion.lenght = 0;
					vm.list_hardware_FirmwareVersion = data.Hardware["Firmware version"];
				} else {}
				 
			  // Comandos
				  if (data.Comandos != undefined && data.Comandos["Modo Programación Activado"] != undefined) { 
					  vm.list_comando_ProgStatus.lenght = 0;
					  vm.list_comando_ProgStatus = data.Comandos["Modo Programación Activado"];
				  } else {}
	  
				  if (data.Comandos != undefined && data.Comandos["Ultimo Comando Recibido"] != undefined) { 
					  vm.list_comando_ProgCommand.lenght = 0;
					  vm.list_comando_ProgCommand = data.Comandos["Ultimo Comando Recibido"];
				  } else {}
	  
				  if (data.Comandos != undefined && data.Comandos["Ultimo Valor de Comando"] != undefined) { 
					  vm.list_comando_ProgValue.lenght = 0;
					  vm.list_comando_ProgValue = data.Comandos["Ultimo Valor de Comando"];
				  } else {}		
	  
			  // Imagenes
				  if (data.Photo != undefined && data.Photo["Imagen"] != undefined) { 
					  vm.list_imagenes.lenght = 0;
					  vm.list_imagenes = data.Photo["Imagen"];
				  } else {}
			  
			  }); 
	  
			  vm.getGeneralTab();
			}
	}

	vm.getAllEventsDateRange = function() {

		var inputUIDEvents = $('#inputUIDEvents').val();
		var dateFromEvents = $('#inputFromEvents').val();
		var dateToEvents = $('#inputToEvents').val();		

			tachasService.getAllEventsDateRange(inputUIDEvents, dateFromEvents, dateToEvents).then(function(data) {		
	
				// General	
				vm.list_general_Temperature = [];
				vm.list_general_Voltage = [];
				vm.list_general_Current = [];
				vm.list_general_PowerSupply = [];
				vm.list_general_Battery = [];
				vm.list_general_Alim_Disconnect = [];
				vm.list_general_NotificationLevel = []; 	
				vm.list_general_LastKeepAlive = [];
				
			// GPS
				vm.list_gps_Ant_Disconnect= [];
				vm.list_gps_GPS= [];
				
			// Potencia
				vm.list_potencia_ActivePower= [];
				vm.list_potencia_AparentPower= [];	
				vm.list_potencia_ReactivePower= [];
				vm.list_potencia_CosPhi= [];
				vm.list_potencia_kWh= [];
				vm.list_potencia_kWhAmount= [];
	
			// Puerta
				vm.list_puerta_DoorState = [];
				vm.list_puerta_DoorOpenTime = [];
				vm.list_puerta_DoorOpenCount = [];
	
			// Hardware
				vm.list_hardware_Movement = []; // Inclinacion
				vm.list_hardware_TampersInt = [];
				vm.list_hardware_TampersExt = [];
				vm.list_hardware_TampersController = [];
				vm.list_hardware_Per_Disconnect = [];
				vm.list_hardware_GSM_Signal = [];
				vm.list_hardware_FirmwareVersion = [];				
			
			// Comandos
				vm.list_comando_ProgStatus = [];
				vm.list_comando_ProgCommand = [];
				vm.list_comando_ProgValue = [];
				
			// Imagenes
				vm.list_imagenes = [];

			// General
				if (data.General != undefined && data.General.Temperatura != undefined) {
					vm.list_general_Temperature.lenght = 0;
					vm.list_general_Temperature = data.General.Temperatura;
				} else {}

				if (data.General != undefined && data.General.Corriente != undefined) {
					vm.list_general_Current.lenght = 0;
					vm.list_general_Current = data.General.Corriente;				
				} else {}

				if (data.General != undefined && data.General.Voltage != undefined) {
					vm.list_general_Voltage.lenght = 0;
					vm.list_general_Voltage = data.General.Voltage;
				} else {}

				if (data.General != undefined && data.General.Energía != undefined) { 
					vm.list_general_PowerSupply.lenght = 0;
					vm.list_general_PowerSupply = data.General.Energía;
				} else {}

				if (data.General != undefined && data.General.Batería != undefined) {
					vm.list_general_Battery.lenght = 0;
					vm.list_general_Battery = data.General.Batería;		
				} else {}

				if (data.General != undefined && data.General["Alimentación Desconectada"] != undefined) { 
					vm.list_general_Alim_Disconnect.lenght = 0;
					vm.list_general_Alim_Disconnect = data.General["Alimentación Desconectada"];				
				} else {}

				if (data.General != undefined && data.General["Nivel de Notificación"] != undefined) {
					vm.list_general_NotificationLevel.lenght = 0;
					vm.list_general_NotificationLevel = data.General["Nivel de Notificación"];			
				} else {}
		
				if (data.General != undefined && data.General["Ultimo KeepAlive"] != undefined) {
					vm.list_general_LastKeepAlive.lenght = 0;
					vm.list_general_LastKeepAlive = data.General["Ultimo KeepAlive"];			
				} else {}
				
			// GPS		
				if (data.GPS != undefined && data.GPS["GPS Desconectado"] != undefined) { 
					vm.list_gps_Ant_Disconnect.lenght = 0;
					vm.list_gps_Ant_Disconnect = data.GPS["GPS Desconectado"];
				} else {}

				if (data.GPS != undefined && data.GPS.Ubicación != undefined) {
					vm.list_gps_GPS.lenght = 0;
					vm.list_gps_GPS = data.GPS.Ubicación;		
				} else {}
				
			// Potencias
				if (data.Potencias != undefined && data.Potencias["Potencia Activa"] != undefined) {				
					vm.list_potencia_ActivePower.lenght = 0;
					vm.list_potencia_ActivePower = data.Potencias["Potencia Activa"];
				} else {}

				if (data.Potencias != undefined && data.Potencias["Potencia Aparente"] != undefined) {
					vm.list_potencia_AparentPower.lenght = 0;
					vm.list_potencia_AparentPower = data.Potencias["Potencia Aparente"];
				} else {}

				if (data.Potencias != undefined && data.Potencias["Potencia Reactiva"] != undefined) {
					vm.list_potencia_ReactivePower.lenght = 0;
					vm.list_potencia_ReactivePower = data.Potencias["Potencia Reactiva"];
				} else {}

				if (data.Potencias != undefined && data.Potencias["Coseno Phi"] != undefined) {
					vm.list_potencia_CosPhi.lenght = 0;
					vm.list_potencia_CosPhi = data.Potencias["Coseno Phi"];
				} else {}

				if (data.Potencias != undefined && data.Potencias["Consumo eléctrico [kWh]"] != undefined) {
					vm.list_potencia_kWh.lenght = 0;
					vm.list_potencia_kWh = data.Potencias["Consumo eléctrico [kWh]"];
				} else {}

				if (data.Potencias != undefined && data.Potencias["Consumo eléctrico [$]"] != undefined) {
					vm.list_potencia_kWhAmount.lenght = 0;
					vm.list_potencia_kWhAmount = data.Potencias["Consumo eléctrico [$]"];
				} else {}
			
			// Puerta
				if (data.Puerta != undefined && data.Puerta["Estado Puerta"] != undefined) { 
					vm.list_puerta_DoorState.lenght = 0;
					vm.list_puerta_DoorState = data.Puerta["Estado Puerta"];
				} else {}

				if (data.Puerta != undefined && data.Puerta["Tiempo de Apertura"] != undefined) { 
					vm.list_puerta_DoorOpenTime.lenght = 0;
					vm.list_puerta_DoorOpenTime = data.Puerta["Tiempo de Apertura"];
				} else {}

				if (data.Puerta != undefined && data.Puerta["Aperturas Diarias"] != undefined) { 
					vm.list_puerta_DoorOpenCount.lenght = 0;
					vm.list_puerta_DoorOpenCount = data.Puerta["Aperturas Diarias"];
				} else {}
			
			// Hardware
				if (data.Hardware != undefined && data.Hardware["Inclinación"] != undefined) { 
					vm.list_hardware_Movement.lenght = 0;
					vm.list_hardware_Movement = data.Hardware["Inclinación"];
				} else {}

				if (data.Hardware != undefined && data.Hardware["Tiempo de Apertura"] != undefined ) { 
					vm.list_hardware_TampersInt.lenght = 0;
					vm.list_hardware_TampersInt = data.Hardware["Tiempo de Apertura"];
				} else {}

				if (data.Hardware != undefined && data.Hardware["Aperturas Diarias"] != undefined) { 
					vm.list_hardware_TampersExt.lenght = 0;
					vm.list_hardware_TampersExt = data.Hardware["Aperturas Diarias"];
				} else {}

				if (data.Hardware != undefined && data.Hardware["Cassette Extraído"] != undefined) { 
					vm.list_hardware_TampersController.lenght = 0;
					vm.list_hardware_TampersController = data.Hardware["Cassette Extraído"];
				} else {}

				if (data.Hardware != undefined && data.Hardware["Señal GSM"] != undefined) { 
					vm.list_hardware_GSM_Signal.lenght = 0;
					vm.list_hardware_GSM_Signal = data.Hardware["Señal GSM"];
				} else {}

				if (data.Hardware != undefined && data.Hardware["Firmware version"] != undefined) { 
					vm.list_hardware_FirmwareVersion.lenght = 0;
					vm.list_hardware_FirmwareVersion = data.Hardware["Firmware version"];
				} else {}				
			
			// Comandos
				if (data.Comandos != undefined && data.Comandos["Modo Programación Activado"] != undefined) { 
					vm.list_comando_ProgStatus.lenght = 0;
					vm.list_comando_ProgStatus = data.Comandos["Modo Programación Activado"];
				} else {}

				if (data.Comandos != undefined && data.Comandos["Ultimo Comando Recibido"] != undefined) { 
					vm.list_comando_ProgCommand.lenght = 0;
					vm.list_comando_ProgCommand = data.Comandos["Ultimo Comando Recibido"];
				} else {}

				if (data.Comandos != undefined && data.Comandos["Ultimo Valor de Comando"] != undefined) { 
					vm.list_comando_ProgValue.lenght = 0;
					vm.list_comando_ProgValue = data.Comandos["Ultimo Valor de Comando"];
				} else {}		

			// Imagenes
				if (data.Photo != undefined && data.Photo["Imagen"] != undefined) { 
					vm.list_imagenes.lenght = 0;
					vm.list_imagenes = data.Photo["Imagen"];
				} else {}

			});

			vm.getGeneralTab();		

	}

	vm.filtrarTablaEvents = function(){
		vm.list_events = f("filter")(vm.orig_events, vm.filterText);
	};

/*************************************** INFORMES ******************************************/

	vm.list_heladeras = [];
	vm.orig_heladeras = []; 

	vm.getInformes = function () {
		if (vm.userClient == "Admin") {
			tachasService.getAll().then(function(data) {
				vm.list_heladeras = data;
				vm.orig_heladeras = angular.copy(vm.list_heladeras); 		
			});
		} else {
			tachasService.getAllByClient(vm.userClientId).then(function(data) {
				vm.list_heladeras = data;
				vm.orig_heladeras = angular.copy(vm.list_heladeras);		
			});
		}
		
	}
	
	vm.filtrarTablaInformes = function(){
		vm.list_heladeras = f("filter")(vm.orig_heladeras, vm.filterText);
	};

	vm.generateInformeGraficos = function (item) {
		
		vm.new = angular.copy(item);		
		vm.mode = 'view';	
		
	  // Pagina 1 LUPA
	  // Main status

		$('#span_mainStatusI').css('color','&#x25C9');
		$('#span_PowerSupplyI').css('color','&#x25C9');
		$('#span_BatteryI').css('color','&#x25C9');
		$('#span_TemperatureI').css('color','&#x25C9');
		$('#span_CurrentI').css('color','&#x25C9');
		$('#span_VoltageI').css('color','&#x25C9');
		$('#span_ActivePowerI').css('color','&#x25C9');
		$('#span_AparentPowerI').css('color','&#x25C9');
		$('#span_ReactivePowerI').css('color','&#x25C9');
		$('#span_CosPhiI').css('color','&#x25C9');
		$('#span_KwhI').css('color','&#x25C9');
		$('#span_KwhAmountI').css('color','&#x25C9');
		$('#span_DoorStateI').css('color','&#x25C9');
		$('#span_DoorOpenCountI').css('color','&#x25C9');
		$('#span_DoorOpenTimeI').css('color','&#x25C9');
		$('#span_GSM_SignalI').css('color','&#x25C9');
		$('#span_Ant_DisconnectI').css('color','&#x25C9');
		$('#span_GeocercaI').css('color','&#x25C9');
		$('#span_MovementI').css('color','&#x25C9');
		$('#span_ProgCommandI').css('color','&#x25C9');
		$('#span_TampersIntI').css('color','&#x25C9');
		$('#span_TampersExtI').css('color','&#x25C9');
		$('#span_TampersControllerI').css('color','&#x25C9');


	    if (vm.new.status.LastCommunication.value.length > 0 ) {
			cssColorNotificationStatus("mainStatusI", vm.new.status.mainStatus);	     
			cssColorNotificationStatus("PowerSupplyI", vm.new.status.PowerSupply.notificationStatus);
			cssColorNotificationStatus("BatteryI", vm.new.status.Battery.notificationStatus);
			cssColorNotificationStatus("TemperatureI", vm.new.status.Temperature.notificationStatus);
			cssColorNotificationStatus("CurrentI", vm.new.status.Current.notificationStatus);
			cssColorNotificationStatus("VoltageI", vm.new.status.Voltage.notificationStatus);
			cssColorNotificationStatus("ActivePowerI", vm.new.status.ActivePower.notificationStatus);
			cssColorNotificationStatus("AparentPowerI", vm.new.status.AparentPower.notificationStatus);
			cssColorNotificationStatus("ReactivePowerI", vm.new.status.ReactivePower.notificationStatus);
			cssColorNotificationStatus("CosPhiI", vm.new.status.CosPhi.notificationStatus);
			cssColorNotificationStatus("KwhI", vm.new.status.Kwh.notificationStatus);
			cssColorNotificationStatus("KwhAmountI", vm.new.status.KwhAmount.notificationStatus);
			cssColorNotificationStatus("DoorStateI", vm.new.status.DoorState.notificationStatus);
			cssColorNotificationStatus("DoorOpenCountI", vm.new.status.DoorOpenCount.notificationStatus);
			cssColorNotificationStatus("DoorOpenTimeI", vm.new.status.DoorOpenTime.notificationStatus);
			cssColorNotificationStatus("GSM_SignalI", vm.new.status.GSM_Signal.notificationStatus);
			cssColorNotificationStatus("Ant_DisconnectI", vm.new.status.Ant_Disconnect.notificationStatus);
			cssColorNotificationStatus("GeocercaI", vm.new.status.GPS.notificationStatus);
			cssColorNotificationStatus("MovementI", vm.new.status.Movement.notificationStatus);
			cssColorNotificationStatus("ProgCommandI", vm.new.status.ProgCommand.notificationStatus);
			cssColorNotificationStatus("TampersIntI", vm.new.status.TampersInt.notificationStatus);
			cssColorNotificationStatus("TampersExtI", vm.new.status.TampersExt.notificationStatus);
			cssColorNotificationStatus("TampersControllerI", vm.new.status.TampersController.notificationStatus);
		} else {}

		
	  // HELADERA DESACTIVADA
			if ( vm.new.active == false) {
				$('#span_mainStatusI').css('color','#ccc');
				$('#span_estadoI').removeClass('hidden');
			} else {
				$('#span_estadoI').addClass('hidden');
			}		

	  // GRAFICOS
	 	// vm.temperature_url = '';  
		// vm.current_url = '';
		// vm.voltage_url = '';
		// vm.currents_url = '';
		// vm.power_url = '';
		// vm.cosenoPhi_url = '';
		// vm.kwh_url = '';
		// vm.consumoKwh_url = '';
		// vm.cantidadAperturas_url = '';

		vm.GatewayVersionBasic = item.sensor.GatewayVersion == 'Basic'  ? true : false;

		vm.getGraficos = function(from, to) {
			logsService.getCharts(from, to).then(function(data) {

				vm.temperature_url = sce.trustAsResourceUrl( data.Temperature.url + item.UID);      
				vm.voltage_url = sce.trustAsResourceUrl( data.Voltage.url + item.UID);
				vm.currents_url = sce.trustAsResourceUrl( data.Current.url + item.UID);
				vm.active_power_url = sce.trustAsResourceUrl( data.ActivePower.url + item.UID);      
				vm.aparent_power_url = sce.trustAsResourceUrl( data.AparentPower.url + item.UID);      
				vm.reactive_power_url = sce.trustAsResourceUrl( data.ReactivePower.url + item.UID);      
				vm.cosenoPhi_url = sce.trustAsResourceUrl( data.CosPhi.url + item.UID);
				vm.kwh_url = sce.trustAsResourceUrl( data.kWh.url + item.UID);
				vm.consumoKwh_url = sce.trustAsResourceUrl( data.kWhAmount.url + item.UID);      
				vm.cantidadAperturas_url = sce.trustAsResourceUrl( data.DoorOpenCount.url + item.UID);	
				
			});
		}
		vm.graph_dateFrom = $('#inputFromEvent').val();
		vm.graph_dateTo = $('#inputToEvent').val();
		// console.log("datefrom", vm.graph_dateFrom)
		// console.log("dateto", vm.graph_dateTo)
		vm.graph_item = item;
		vm.getGraficos(vm.graph_dateFrom, vm.graph_dateTo);
		$('#tab_informes').addClass('hidden');
		$('#divInformesMain').removeClass('hidden');
	}

	vm.generateInformeEventos = function (item) {	

		var dateFrom = $('#inputFromEvent').val();
		var dateTo = $('#inputToEvent').val();		

		if ($('#inputFromEvent').val() != 0 && $('#inputToEvent').val()) {

			logsService.getEventsByDateRange(dateFrom, dateTo, item.UID).then(function(data) {	

				if (data.lenght != 0) {
	 
					var today = new Date(); 
					var fecha = today.getDate() + "-"+ today.getMonth()+ "-" + today.getFullYear();
	
					var anchor = document.createElement('a');
						anchor.href = 'data:attachment/csv;charset=utf-8,' + encodeURI(data);
						anchor.target = '_blank';
						anchor.download = 'Eventos_' + item.UID + '_' + fecha + '.csv';
						document.body.appendChild(anchor);
						anchor.click();
						$timeout(function () {
							anchor.remove();
						}, 50);					
					
				} else {}
			});

		} else {

			logsService.getEventsByUID(item.UID).then(function(data) {
			
				if (data.lenght != 0) {
						
					var today = new Date(); 
					var fecha = today.getDate() + "-"+ today.getMonth()+ "-" +today.getFullYear();
	
					var anchor = document.createElement('a');
						anchor.href = 'data:attachment/csv;charset=utf-8,' + encodeURI(data);
						anchor.target = '_blank';
						anchor.download = 'Eventos_' + item.UID + '_' + fecha + '.csv';
						document.body.appendChild(anchor);
						anchor.click();
						$timeout(function () {
							anchor.remove();
						}, 50);  
					
				} else {}  
	
			}); 

		}

	}

	vm.exportToPdf = function() {
		html2canvas($('#divStatusInformes')[0]).then(function(canvas) {
			var imgState = canvas.toDataURL('image/png');
			console.log("canvas", imgState);
			var info = {
				from: vm.graph_dateFrom,
				to: vm.graph_dateTo,
				uid: vm.graph_item.UID,
				gatewayVersion: vm.graph_item.sensor.GatewayVersion,
				canvas: imgState
			}
			logsService.generateReport(info).then(function(data){
				// var data = 'http://localhost:9000/test/1571937556916.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=minio%2F20191025%2F%2Fs3%2Faws4_request&X-Amz-Date=20191025T185933Z&X-Amz-Expires=432000&X-Amz-SignedHeaders=host&X-Amz-Signature=4b356b879a58f3325cdc6d93da67337da9b6a0f0fcb224120b31f59a55637a5e';
				r.downloadPDF(data,'document');
			});

		});
		// /api/report/generate?uid=b5700002dd1f8&from=2019-10-02&to=2019-10-12
		// eventsService.exportEventsCsv(vm.list).then(function(data){
		// 	r.downloadCSV(data,'Eventos');
		// });
		// var doc = new jsPDF('l'); 

		// html2canvas($('#divStatusInformes')[0], doc).then(function(canvas) {

		// 	var imgState = canvas.toDataURL('image/png');
		// 	console.log("ficha", imgState);

		// });	
	}

	vm.backToInformes = function() {

		$('#divInformesMain').addClass('hidden');
		$('#tab_informes').removeClass('hidden');

	}

/******************************** Click Pestañas *********************************/

  	clickPestañaEventos = function() {	

		$('#li_eventos').addClass('active');
		$('#tab_eventos').addClass('active');
		$('#tab_eventos').addClass('in');

		$('#li_heladeras').removeClass('active');
		$('#tab_heladeras').removeClass('active');
		
		$('#li_alarmas').removeClass('active');
		$('#tab_alarmas').removeClass('active');

		$('#li_informes').removeClass('active');
		$('#tab_informes').removeClass('active');

		vm.getAllEvents();	
		sessionStorage.setItem('fromEvents', '0');	
		$('#divInformesMain').addClass('hidden');

	}

	clickPestañaAlarmas = function() {
		vm.getAlarmasActivas();	
		$('#divInformesMain').addClass('hidden');	 
	}

	clickPestañaInformes = function() {
		$('#tab_informes').removeClass('hidden');
		vm.getInformes();		
		sessionStorage.setItem('fromInformes', '0');	 
	}

	clickPestañaHeladeras = function() {
		$('#divInformesMain').addClass('hidden');	 
	}

	vm.getGeneralTab = function () {

	  // Btn's
		if(vm.GatewayVersionBasic =='basic'){
			$('#btnAdminGeneral').css('opacity','0.5');
			$('#btnAdminGPS').css('opacity','0.5');
			$('#btnAdminPotencias').css('opacity','0.5');
			$('#btnAdminPuerta').css('opacity','0.5');
			$('#btnAdminHardware').css('opacity','0.5');
			$('#btnAdminComandos').css('opacity','0.5');
			$('#btnAdminImagenes').css('opacity','0.5');	
		}else{
		$('#btnAdminGeneral').css('opacity','0.5');
		$('#btnAdminGPS').css('opacity','1');
		$('#btnAdminPotencias').css('opacity','1');
		$('#btnAdminPuerta').css('opacity','1');
		$('#btnAdminHardware').css('opacity','1');
		$('#btnAdminComandos').css('opacity','1');
		$('#btnAdminImagenes').css('opacity','1');
	}


	// Pestañas
		$('#tab_puerta').css('display','none');
		$('#tab_potencias').css('display','none');
		$('#tab_gps').css('display','none');
		$('#tab_puerta').css('display','none');
		$('#tab_hardware').css('display','none');
		$('#tab_comandos').css('display','none');
		$('#tab_general').css('display','block');	
		$('#tab_main_imagenes').css('display','none');	

		
	// Pestaña General Init
		$('#li_general_temperatura').addClass('active');
		$('#tab_general_temperatura').addClass('active');
		$('#tab_general_temperatura').addClass('in');

		$('#li_general_corriente').removeClass('active');
		$('#tab_general_corriente').removeClass('active');
		$('#li_general_voltage').removeClass('active');
		$('#tab_general_voltage').removeClass('active');
		$('#li_general_energia').removeClass('active');
		$('#tab_general_energia').removeClass('active');
		$('#li_general_bateria').removeClass('active');
		$('#tab_general_bateria').removeClass('active');
		$('#li_general_alimentacion').removeClass('active');
		$('#tab_general_alimentacion').removeClass('active');
		$('#li_general_nivel_notificacion').removeClass('active');
		$('#tab_general_nivel_notificacion').removeClass('active');
		$('#li_general_keepalive').removeClass('active');
		$('#tab_general_keepalive').removeClass('active');

		$('#li_gps_desconectado').removeClass('active');
		$('#tab_gps_desconectado').removeClass('active');
		$('#li_gps_ubicacion').removeClass('active');
		$('#tab_gps_ubicacion').removeClass('active');

		$('#li_potencia_activa').removeClass('active');
		$('#tab_potencia_activa').removeClass('active');
		$('#li_potencia_aparente').removeClass('active');
		$('#tab_potencia_aparente').removeClass('active');
		$('#li_potencia_reactiva').removeClass('active');
		$('#tab_potencia_reactiva').removeClass('active');
		$('#li_potencia_cosenoPhi').removeClass('active');
		$('#tab_potencia_cosenoPhi').removeClass('active');
		$('#li_potencia_consumo_KW').removeClass('active');
		$('#tab_potencia_consumo_KW').removeClass('active');
		$('#li_potencia_consumo_mensual').removeClass('active');
		$('#tab_potencia_consumo_mensual').removeClass('active');

		$('#li_estado_puerta').removeClass('active');
		$('#tab_estado_puerta').removeClass('active');
		$('#li_tiempo_apertura').removeClass('active');
		$('#tab_tiempo_apertura').removeClass('active');
		$('#li_apertura_diaria').removeClass('active');
		$('#tab_apertura_diaria').removeClass('active');

		$('#li_inclinacion').removeClass('active');
		$('#tab_inclinacion').removeClass('active');
		$('#li_gateway_abierto').removeClass('active');
		$('#tab_gateway_abierto').removeClass('active');
		$('#li_gateway_extraido').removeClass('active');
		$('#tab_gateway_extraido').removeClass('active');
		$('#li_cassete_extraido').removeClass('active');
		$('#tab_cassete_extraido').removeClass('active');

		$('#li_GSM').removeClass('active');
		$('#tab_GSM').removeClass('active');
		$('#li_FirmwareVersion').removeClass('active');
		$('#tab_FirmwareVersion').removeClass('active');
		$('#li_modo_programacion').removeClass('active');
		$('#tab_modo_programacion').removeClass('active');
		$('#li_ultimo_comando').removeClass('active');
		$('#tab_ultimo_comando').removeClass('active');
		$('#li_ultimo_valor').removeClass('active');
		$('#tab_ultimo_valor').removeClass('active');

		$('#li_imagenes').removeClass('active');
		$('#tab_imagenes').removeClass('active');
	}

	vm.getGPSTab = function () {

	  // Btn's	
		$('#btnAdminGeneral').css('opacity','1');
		$('#btnAdminGPS').css('opacity','0.5');
		$('#btnAdminPotencias').css('opacity','1');
		$('#btnAdminPuerta').css('opacity','1');
		$('#btnAdminHardware').css('opacity','1');
		$('#btnAdminComandos').css('opacity','1');
		$('#btnImagenes').css('opacity','1');
		
	// Pestañas
		$('#tab_puerta').css('display','none');
		$('#tab_potencias').css('display','none');
		$('#tab_gps').css('display','block');
		$('#tab_puerta').css('display','none');
		$('#tab_hardware').css('display','none');
		$('#tab_comandos').css('display','none');
		$('#tab_general').css('display','none');
		$('#tab_main_imagenes').css('display','none');

	// Pestaña GPS Init
		$('#li_gps_desconectado').addClass('active');
		$('#tab_gps_desconectado').addClass('active');
		$('#tab_gps_desconectado').addClass('in');

		$('#li_general_temperatura').removeClass('active');
		$('#tab_general_temperatura').removeClass('active');
		$('#li_general_corriente').removeClass('active');
		$('#tab_general_corriente').removeClass('active');
		$('#li_general_voltage').removeClass('active');
		$('#tab_general_voltage').removeClass('active');
		$('#li_general_energia').removeClass('active');
		$('#tab_general_energia').removeClass('active');
		$('#li_general_bateria').removeClass('active');
		$('#tab_general_bateria').removeClass('active');
		$('#li_general_alimentacion').removeClass('active');
		$('#tab_general_alimentacion').removeClass('active');
		$('#li_general_nivel_notificacion').removeClass('active');
		$('#tab_general_nivel_notificacion').removeClass('active');
		$('#li_general_keepalive').removeClass('active');
		$('#tab_general_keepalive').removeClass('active');

		$('#li_gps_ubicacion').removeClass('active');
		$('#tab_gps_ubicacion').removeClass('active');

		$('#li_potencia_activa').removeClass('active');
		$('#tab_potencia_activa').removeClass('active');
		$('#li_potencia_aparente').removeClass('active');
		$('#tab_potencia_aparente').removeClass('active');
		$('#li_potencia_reactiva').removeClass('active');
		$('#tab_potencia_reactiva').removeClass('active');
		$('#li_potencia_cosenoPhi').removeClass('active');
		$('#tab_potencia_cosenoPhi').removeClass('active');
		$('#li_potencia_consumo_KW').removeClass('active');
		$('#tab_potencia_consumo_KW').removeClass('active');
		$('#li_potencia_consumo_mensual').removeClass('active');
		$('#tab_potencia_consumo_mensual').removeClass('active');

		$('#li_estado_puerta').removeClass('active');
		$('#tab_estado_puerta').removeClass('active');
		$('#li_tiempo_apertura').removeClass('active');
		$('#tab_tiempo_apertura').removeClass('active');
		$('#li_apertura_diaria').removeClass('active');
		$('#tab_apertura_diaria').removeClass('active');

		$('#li_inclinacion').removeClass('active');
		$('#tab_inclinacion').removeClass('active');
		$('#li_gateway_abierto').removeClass('active');
		$('#tab_gateway_abierto').removeClass('active');
		$('#li_gateway_extraido').removeClass('active');
		$('#tab_gateway_extraido').removeClass('active');
		$('#li_cassete_extraido').removeClass('active');
		$('#tab_cassete_extraido').removeClass('active');

		$('#li_GSM').removeClass('active');
		$('#tab_GSM').removeClass('active');
		$('#li_FirmwareVersion').removeClass('active');
		$('#tab_FirmwareVersion').removeClass('active');
		$('#li_modo_programacion').removeClass('active');
		$('#tab_modo_programacion').removeClass('active');
		$('#li_ultimo_comando').removeClass('active');
		$('#tab_ultimo_comando').removeClass('active');
		$('#li_ultimo_valor').removeClass('active');
		$('#tab_ultimo_valor').removeClass('active');

		$('#li_imagenes').removeClass('active');
		$('#tab_imagenes').removeClass('active');

	}

	vm.getPotenciasTab = function () {

	  // Btn's	
		$('#btnAdminGeneral').css('opacity','1');
		$('#btnAdminGPS').css('opacity','1');
		$('#btnAdminPotencias').css('opacity','0.5');
		$('#btnAdminPuerta').css('opacity','1');
		$('#btnAdminHardware').css('opacity','1');
		$('#btnAdminComandos').css('opacity','1');
		$('#btnImagenes').css('opacity','1');
		
	// Pestañas
		$('#tab_puerta').css('display','none');
		$('#tab_potencias').css('display','block');
		$('#tab_gps').css('display','none');
		$('#tab_puerta').css('display','none');
		$('#tab_hardware').css('display','none');
		$('#tab_comandos').css('display','none');
		$('#tab_general').css('display','none');
		$('#tab_main_imagenes').css('display','none');

	// Pestaña Potencias Init
		$('#li_potencia_activa').addClass('active');
		$('#tab_potencia_activa').addClass('active');
		$('#tab_potencia_activa').addClass('in');	

		$('#li_general_temperatura').removeClass('active');
		$('#tab_general_temperatura').removeClass('active');
		$('#li_general_corriente').removeClass('active');
		$('#tab_general_corriente').removeClass('active');
		$('#li_general_voltage').removeClass('active');
		$('#tab_general_voltage').removeClass('active');
		$('#li_general_energia').removeClass('active');
		$('#tab_general_energia').removeClass('active');
		$('#li_general_bateria').removeClass('active');
		$('#tab_general_bateria').removeClass('active');
		$('#li_general_alimentacion').removeClass('active');
		$('#tab_general_alimentacion').removeClass('active');
		$('#li_general_nivel_notificacion').removeClass('active');
		$('#tab_general_nivel_notificacion').removeClass('active');
		$('#li_general_keepalive').removeClass('active');
		$('#tab_general_keepalive').removeClass('active');

		$('#li_gps_desconectado').removeClass('active');
		$('#tab_gps_desconectado').removeClass('active');
		$('#li_gps_ubicacion').removeClass('active');
		$('#tab_gps_ubicacion').removeClass('active');
		
		$('#li_potencia_aparente').removeClass('active');
		$('#tab_potencia_aparente').removeClass('active');
		$('#li_potencia_reactiva').removeClass('active');
		$('#tab_potencia_reactiva').removeClass('active');
		$('#li_potencia_cosenoPhi').removeClass('active');
		$('#tab_potencia_cosenoPhi').removeClass('active');
		$('#li_potencia_consumo_KW').removeClass('active');
		$('#tab_potencia_consumo_KW').removeClass('active');
		$('#li_potencia_consumo_mensual').removeClass('active');
		$('#tab_potencia_consumo_mensual').removeClass('active');

		$('#li_estado_puerta').removeClass('active');
		$('#tab_estado_puerta').removeClass('active');
		$('#li_tiempo_apertura').removeClass('active');
		$('#tab_tiempo_apertura').removeClass('active');
		$('#li_apertura_diaria').removeClass('active');
		$('#tab_apertura_diaria').removeClass('active');

		$('#li_inclinacion').removeClass('active');
		$('#tab_inclinacion').removeClass('active');
		$('#li_gateway_abierto').removeClass('active');
		$('#tab_gateway_abierto').removeClass('active');
		$('#li_gateway_extraido').removeClass('active');
		$('#tab_gateway_extraido').removeClass('active');
		$('#li_cassete_extraido').removeClass('active');
		$('#tab_cassete_extraido').removeClass('active');

		$('#li_GSM').removeClass('active');
		$('#tab_GSM').removeClass('active');
		$('#li_FirmwareVersion').removeClass('active');
		$('#tab_FirmwareVersion').removeClass('active');
		$('#li_modo_programacion').removeClass('active');
		$('#tab_modo_programacion').removeClass('active');
		$('#li_ultimo_comando').removeClass('active');
		$('#tab_ultimo_comando').removeClass('active');
		$('#li_ultimo_valor').removeClass('active');
		$('#tab_ultimo_valor').removeClass('active');

		$('#li_imagenes').removeClass('active');
		$('#tab_imagenes').removeClass('active');

	}

	vm.getPuertaTab = function () {

	   // Btn's	
		$('#btnAdminGeneral').css('opacity','1');
		$('#btnAdminGPS').css('opacity','1');
		$('#btnAdminPotencias').css('opacity','1');
		$('#btnAdminPuerta').css('opacity','0.5');
		$('#btnAdminHardware').css('opacity','1');
		$('#btnAdminComandos').css('opacity','1');
		$('#btnImagenes').css('opacity','1');
		
	// Pestañas
		$('#tab_puerta').css('display','none');
		$('#tab_potencias').css('display','none');
		$('#tab_gps').css('display','none');
		$('#tab_puerta').css('display','block');
		$('#tab_hardware').css('display','none');
		$('#tab_comandos').css('display','none');
		$('#tab_general').css('display','none');
		$('#tab_main_imagenes').css('display','none');

	// Pestaña Puerta Init
		$('#li_estado_puerta').addClass('active');
		$('#tab_estado_puerta').addClass('active');
		$('#tab_estado_puerta').addClass('in');

		$('#li_general_temperatura').removeClass('active');
		$('#tab_general_temperatura').removeClass('active');
		$('#li_general_corriente').removeClass('active');
		$('#tab_general_corriente').removeClass('active');
		$('#li_general_voltage').removeClass('active');
		$('#tab_general_voltage').removeClass('active');
		$('#li_general_energia').removeClass('active');
		$('#tab_general_energia').removeClass('active');
		$('#li_general_bateria').removeClass('active');
		$('#tab_general_bateria').removeClass('active');
		$('#li_general_alimentacion').removeClass('active');
		$('#tab_general_alimentacion').removeClass('active');
		$('#li_general_nivel_notificacion').removeClass('active');
		$('#tab_general_nivel_notificacion').removeClass('active');
		$('#li_general_keepalive').removeClass('active');
		$('#tab_general_keepalive').removeClass('active');

		$('#li_gps_desconectado').removeClass('active');
		$('#tab_gps_desconectado').removeClass('active');
		$('#li_gps_ubicacion').removeClass('active');
		$('#tab_gps_ubicacion').removeClass('active');

		$('#li_potencia_activa').removeClass('active');
		$('#tab_potencia_activa').removeClass('active');
		$('#li_potencia_aparente').removeClass('active');
		$('#tab_potencia_aparente').removeClass('active');
		$('#li_potencia_reactiva').removeClass('active');
		$('#tab_potencia_reactiva').removeClass('active');
		$('#li_potencia_cosenoPhi').removeClass('active');
		$('#tab_potencia_cosenoPhi').removeClass('active');
		$('#li_potencia_consumo_KW').removeClass('active');
		$('#tab_potencia_consumo_KW').removeClass('active');
		$('#li_potencia_consumo_mensual').removeClass('active');
		$('#tab_potencia_consumo_mensual').removeClass('active');

		
		$('#li_tiempo_apertura').removeClass('active');
		$('#tab_tiempo_apertura').removeClass('active');
		$('#li_apertura_diaria').removeClass('active');
		$('#tab_apertura_diaria').removeClass('active');

		$('#li_inclinacion').removeClass('active');
		$('#tab_inclinacion').removeClass('active');
		$('#li_gateway_abierto').removeClass('active');
		$('#tab_gateway_abierto').removeClass('active');
		$('#li_gateway_extraido').removeClass('active');
		$('#tab_gateway_extraido').removeClass('active');
		$('#li_cassete_extraido').removeClass('active');
		$('#tab_cassete_extraido').removeClass('active');

		$('#li_GSM').removeClass('active');
		$('#tab_GSM').removeClass('active');
		$('#li_FirmwareVersion').removeClass('active');
		$('#tab_FirmwareVersion').removeClass('active');
		$('#li_modo_programacion').removeClass('active');
		$('#tab_modo_programacion').removeClass('active');
		$('#li_ultimo_comando').removeClass('active');
		$('#tab_ultimo_comando').removeClass('active');
		$('#li_ultimo_valor').removeClass('active');
		$('#tab_ultimo_valor').removeClass('active');

		$('#li_imagenes').removeClass('active');
		$('#tab_imagenes').removeClass('active');

	}

	vm.getHardwareTab = function () {
		
	  // Btn's	
		$('#btnAdminGeneral').css('opacity','1');
		$('#btnAdminGPS').css('opacity','1');
		$('#btnAdminPotencias').css('opacity','1');
		$('#btnAdminPuerta').css('opacity','1');
		$('#btnAdminHardware').css('opacity','0.5');
		$('#btnAdminComandos').css('opacity','1');
		$('#btnImagenes').css('opacity','1');
			
	// Pestañas
		$('#tab_puerta').css('display','none');
		$('#tab_potencias').css('display','none');
		$('#tab_gps').css('display','none');
		$('#tab_puerta').css('display','none');
		$('#tab_hardware').css('display','block');
		$('#tab_comandos').css('display','none');
		$('#tab_general').css('display','none');
		$('#tab_main_imagenes').css('display','none');

	// Pestaña Hardware Init
		$('#li_inclinacion').addClass('active');
		$('#tab_inclinacion').addClass('active');
		$('#tab_inclinacion').addClass('in');

		$('#li_general_temperatura').removeClass('active');
		$('#tab_general_temperatura').removeClass('active');
		$('#li_general_corriente').removeClass('active');
		$('#tab_general_corriente').removeClass('active');
		$('#li_general_voltage').removeClass('active');
		$('#tab_general_voltage').removeClass('active');
		$('#li_general_energia').removeClass('active');
		$('#tab_general_energia').removeClass('active');
		$('#li_general_bateria').removeClass('active');
		$('#tab_general_bateria').removeClass('active');
		$('#li_general_alimentacion').removeClass('active');
		$('#tab_general_alimentacion').removeClass('active');
		$('#li_general_nivel_notificacion').removeClass('active');
		$('#tab_general_nivel_notificacion').removeClass('active');
		$('#li_general_keepalive').removeClass('active');
		$('#tab_general_keepalive').removeClass('active');

		$('#li_gps_desconectado').removeClass('active');
		$('#tab_gps_desconectado').removeClass('active');
		$('#li_gps_ubicacion').removeClass('active');
		$('#tab_gps_ubicacion').removeClass('active');

		$('#li_potencia_activa').removeClass('active');
		$('#tab_potencia_activa').removeClass('active');
		$('#li_potencia_aparente').removeClass('active');
		$('#tab_potencia_aparente').removeClass('active');
		$('#li_potencia_reactiva').removeClass('active');
		$('#tab_potencia_reactiva').removeClass('active');
		$('#li_potencia_cosenoPhi').removeClass('active');
		$('#tab_potencia_cosenoPhi').removeClass('active');
		$('#li_potencia_consumo_KW').removeClass('active');
		$('#tab_potencia_consumo_KW').removeClass('active');
		$('#li_potencia_consumo_mensual').removeClass('active');
		$('#tab_potencia_consumo_mensual').removeClass('active');

		$('#li_estado_puerta').removeClass('active');
		$('#tab_estado_puerta').removeClass('active');
		$('#li_tiempo_apertura').removeClass('active');
		$('#tab_tiempo_apertura').removeClass('active');
		$('#li_apertura_diaria').removeClass('active');
		$('#tab_apertura_diaria').removeClass('active');
		
		$('#li_gateway_abierto').removeClass('active');
		$('#tab_gateway_abierto').removeClass('active');
		$('#li_gateway_extraido').removeClass('active');
		$('#tab_gateway_extraido').removeClass('active');
		$('#li_cassete_extraido').removeClass('active');
		$('#tab_cassete_extraido').removeClass('active');
		$('#li_GSM').removeClass('active');
		$('#tab_GSM').removeClass('active');
		$('#li_FirmwareVersion').removeClass('active');
		$('#tab_FirmwareVersion').removeClass('active');

		$('#li_modo_programacion').removeClass('active');
		$('#tab_modo_programacion').removeClass('active');
		$('#li_ultimo_comando').removeClass('active');
		$('#tab_ultimo_comando').removeClass('active');
		$('#li_ultimo_valor').removeClass('active');
		$('#tab_ultimo_valor').removeClass('active');

		$('#li_imagenes').removeClass('active');
		$('#tab_imagenes').removeClass('active');

	}
  
	vm.getComandosTab = function () {

	  // Btn's	
		$('#btnAdminGeneral').css('opacity','1');
		$('#btnAdminGPS').css('opacity','1');
		$('#btnAdminPotencias').css('opacity','1');
		$('#btnAdminPuerta').css('opacity','1');
		$('#btnAdminHardware').css('opacity','1');
		$('#btnAdminComandos').css('opacity','0.5');
		$('#btnImagenes').css('opacity','1');
			
	// Pestañas
		$('#tab_puerta').css('display','none');
		$('#tab_potencias').css('display','none');
		$('#tab_gps').css('display','none');
		$('#tab_puerta').css('display','none');
		$('#tab_hardware').css('display','none');
		$('#tab_comandos').css('display','block');
		$('#tab_general').css('display','none');
		$('#tab_main_imagenes').css('display','none');

	// Pestaña Comandos Init
		$('#li_modo_programacion').addClass('active');
		$('#tab_modo_programacion').addClass('active');
		$('#tab_modo_programacion').addClass('in');

		$('#li_general_temperatura').removeClass('active');
		$('#tab_general_temperatura').removeClass('active');
		$('#li_general_corriente').removeClass('active');
		$('#tab_general_corriente').removeClass('active');
		$('#li_general_voltage').removeClass('active');
		$('#tab_general_voltage').removeClass('active');
		$('#li_general_energia').removeClass('active');
		$('#tab_general_energia').removeClass('active');
		$('#li_general_bateria').removeClass('active');
		$('#tab_general_bateria').removeClass('active');
		$('#li_general_alimentacion').removeClass('active');
		$('#tab_general_alimentacion').removeClass('active');
		$('#li_general_nivel_notificacion').removeClass('active');
		$('#tab_general_nivel_notificacion').removeClass('active');
		$('#li_general_keepalive').removeClass('active');
		$('#tab_general_keepalive').removeClass('active');

		$('#li_gps_desconectado').removeClass('active');
		$('#tab_gps_desconectado').removeClass('active');
		$('#li_gps_ubicacion').removeClass('active');
		$('#tab_gps_ubicacion').removeClass('active');

		$('#li_potencia_activa').removeClass('active');
		$('#tab_potencia_activa').removeClass('active');
		$('#li_potencia_aparente').removeClass('active');
		$('#tab_potencia_aparente').removeClass('active');
		$('#li_potencia_reactiva').removeClass('active');
		$('#tab_potencia_reactiva').removeClass('active');
		$('#li_potencia_cosenoPhi').removeClass('active');
		$('#tab_potencia_cosenoPhi').removeClass('active');
		$('#li_potencia_consumo_KW').removeClass('active');
		$('#tab_potencia_consumo_KW').removeClass('active');
		$('#li_potencia_consumo_mensual').removeClass('active');
		$('#tab_potencia_consumo_mensual').removeClass('active');

		$('#li_estado_puerta').removeClass('active');
		$('#tab_estado_puerta').removeClass('active');
		$('#li_tiempo_apertura').removeClass('active');
		$('#tab_tiempo_apertura').removeClass('active');
		$('#li_apertura_diaria').removeClass('active');
		$('#tab_apertura_diaria').removeClass('active');

		$('#li_inclinacion').removeClass('active');
		$('#tab_inclinacion').removeClass('active');
		$('#li_gateway_abierto').removeClass('active');
		$('#tab_gateway_abierto').removeClass('active');
		$('#li_gateway_extraido').removeClass('active');
		$('#tab_gateway_extraido').removeClass('active');
		$('#li_cassete_extraido').removeClass('active');
		$('#tab_cassete_extraido').removeClass('active');
		$('#li_GSM').removeClass('active');
		$('#tab_GSM').removeClass('active');
		$('#li_FirmwareVersion').removeClass('active');
		$('#tab_FirmwareVersion').removeClass('active');

		$('#li_ultimo_comando').removeClass('active');
		$('#tab_ultimo_comando').removeClass('active');
		$('#li_ultimo_valor').removeClass('active');
		$('#tab_ultimo_valor').removeClass('active');

		$('#li_imagenes').removeClass('active');
		$('#tab_imagenes').removeClass('active');

	}

	vm.getImagenesTab = function () {

		// Btn's	
		  $('#btnAdminGeneral').css('opacity','1');
		  $('#btnAdminGPS').css('opacity','1');
		  $('#btnAdminPotencias').css('opacity','1');
		  $('#btnAdminPuerta').css('opacity','1');
		  $('#btnAdminHardware').css('opacity','1');
		  $('#btnAdminComandos').css('opacity','1');
		  $('#btnImagenes').css('opacity','0.5');
			  
		// Pestañas
		  $('#tab_main_imagenes').css('display','block');
		  $('#tab_puerta').css('display','none');
		  $('#tab_potencias').css('display','none');
		  $('#tab_gps').css('display','none');
		  $('#tab_puerta').css('display','none');
		  $('#tab_hardware').css('display','none');
		  $('#tab_comandos').css('display','none');
		  $('#tab_general').css('display','none');
	  
		// Pestaña Comandos Init
		  $('#li_imagenes').addClass('active');
		  $('#tab_imagenes').addClass('active');
		  $('#tab_imagenes').addClass('in');	  
	  
		  $('#li_general_temperatura').removeClass('active');
		  $('#tab_general_temperatura').removeClass('active');
		  $('#li_general_corriente').removeClass('active');
		  $('#tab_general_corriente').removeClass('active');
		  $('#li_general_voltage').removeClass('active');
		  $('#tab_general_voltage').removeClass('active');
		  $('#li_general_energia').removeClass('active');
		  $('#tab_general_energia').removeClass('active');
		  $('#li_general_bateria').removeClass('active');
		  $('#tab_general_bateria').removeClass('active');
		  $('#li_general_alimentacion').removeClass('active');
		  $('#tab_general_alimentacion').removeClass('active');
		  $('#li_general_nivel_notificacion').removeClass('active');
		  $('#tab_general_nivel_notificacion').removeClass('active');
		  $('#li_general_keepalive').removeClass('active');
		  $('#tab_general_keepalive').removeClass('active');
	  
		  $('#li_gps_desconectado').removeClass('active');
		  $('#tab_gps_desconectado').removeClass('active');
		  $('#li_gps_ubicacion').removeClass('active');
		  $('#tab_gps_ubicacion').removeClass('active');
	  
		  $('#li_potencia_activa').removeClass('active');
		  $('#tab_potencia_activa').removeClass('active');
		  $('#li_potencia_aparente').removeClass('active');
		  $('#tab_potencia_aparente').removeClass('active');
		  $('#li_potencia_reactiva').removeClass('active');
		  $('#tab_potencia_reactiva').removeClass('active');
		  $('#li_potencia_cosenoPhi').removeClass('active');
		  $('#tab_potencia_cosenoPhi').removeClass('active');
		  $('#li_potencia_consumo_KW').removeClass('active');
		  $('#tab_potencia_consumo_KW').removeClass('active');
		  $('#li_potencia_consumo_mensual').removeClass('active');
		  $('#tab_potencia_consumo_mensual').removeClass('active');
	  
		  $('#li_estado_puerta').removeClass('active');
		  $('#tab_estado_puerta').removeClass('active');
		  $('#li_tiempo_apertura').removeClass('active');
		  $('#tab_tiempo_apertura').removeClass('active');
		  $('#li_apertura_diaria').removeClass('active');
		  $('#tab_apertura_diaria').removeClass('active');
	  
		  $('#li_inclinacion').removeClass('active');
		  $('#tab_inclinacion').removeClass('active');
		  $('#li_gateway_abierto').removeClass('active');
		  $('#tab_gateway_abierto').removeClass('active');
		  $('#li_gateway_extraido').removeClass('active');
		  $('#tab_gateway_extraido').removeClass('active');
		  $('#li_cassete_extraido').removeClass('active');
		  $('#tab_cassete_extraido').removeClass('active');
		  $('#li_GSM').removeClass('active');
		  $('#tab_GSM').removeClass('active');
		  $('#li_FirmwareVersion').removeClass('active');
		  $('#tab_FirmwareVersion').removeClass('active');
	  
		  $('#li_ultimo_comando').removeClass('active');
		  $('#tab_ultimo_comando').removeClass('active');
		  $('#li_ultimo_valor').removeClass('active');
		  $('#tab_ultimo_valor').removeClass('active');
		  $('#li_modo_programacion').removeClass('active');
		  $('#tab_modo_programacion').removeClass('active');
	  	  
	  }

 /*********************************** RESET KWH ***********************************/

	vm.resetKwh = function(item) {
		vm.selected = item;
        swal({
            title: 'Seguro que desea resetear el consumo mensual de energia?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar'
        }).then(function() {
            tachasService.resetKwh(vm.selected).then(function() {               
                swal({
                    title: 'El consumo mensual de energia esta en 0!',
                    type: 'success',
                    confirmButtonText: 'Aceptar'
				});
				vm.getAll(); 
			});
		}).catch(swal.noop); 
	}

 /***********************************************************************************/

	vm.showImage = function(item) {

		var idImage = item.value
		vm.uidSensableImage = item.uid;
		vm.aliasSensableImage = item.alias ;
		vm.fechaSensableImage = item.datetime;

		tachasService.getImageById(idImage).then(function(data) {
			
			var imageParsed = "data:image/jpg;base64," + data.data;
		
			$('#divImageById').empty().append('<div>' +
												'<figure><img src="'+ imageParsed +'"></figure>' +
									  		  '</div>');
			$('#modalImageById').modal();

		}); 	

	}

 /***********************************************************************************/

	vm.getGeneralTab();
	vm.getAll();
	vm.initMap();

	appendModal();
	
	vm.tooltip = function(){
		t(function(){
			$('[data-toggle="tooltip"]').tooltip();
		}, 300);
	}
	
	$('.modal').on('shown.bs.modal', function (e) {
		vm.tooltip();
	});	

	var fromEvents = sessionStorage.getItem('fromEvents');	

		if (fromEvents == 1) {					
			clickPestañaEventos();			
		} else { }		
	 /***********************************************************************************/
	vm.getBatteryStatusName = function(status){
		switch (status) {
			case "0":
				return "Sin Alimentación";
			case "1":
				return "Cargada";
			case "2":
				return "Cargando";
			case "3":
				return "Ausente";
			case "4":
				return "Error";
			default:
				return "-";
		}
	}

	interval.cancel(r.intervalRefresh);
	r.intervalRefresh = interval(vm.getAll,60000);

	/* USER PREFERENCE CONFIG DEVICE TABLE **********************************************************************************/

	vm.tableHeladeraConfigVM = {};

	const tableHeladeraConfig = { 										
									'ALIAS': true,								
									'CLIENT_NAME': true,
									'GROUP_NAME': true,
									'ADRESS': true,								
									'MANUFACTURER_NAME': true,
									'MODEL_ID': true,
									'SERIAL_NUMBER': true,
									'GATEWAY_VERSION': true,
									'STATUS': true,
									'LAST_COMM': true,									
								};

	function modifyTitleTable() {
		if(vm.tableHeladeraConfigVM.ALIAS === false) {
			$("#sensorsTable thead th:eq(0)").hide();
		} else { $("#sensorsTable thead th:eq(0)").show(); }
		if(vm.tableHeladeraConfigVM.CLIENT_NAME === false) {
			$("#sensorsTable thead th:eq(1)").hide();
		} else { $("#sensorsTable thead th:eq(1)").show(); }
		if(vm.tableHeladeraConfigVM.GROUP_NAME === false) {
			$("#sensorsTable thead th:eq(2)").hide();
		} else { $("#sensorsTable thead th:eq(2)").show(); }
		if(vm.tableHeladeraConfigVM.ADRESS === false) {
			$("#sensorsTable thead th:eq(3)").hide();
		} else { $("#sensorsTable thead th:eq(3)").show(); }
		if(vm.tableHeladeraConfigVM.MANUFACTURER_NAME === false) {
			$("#sensorsTable thead th:eq(4)").hide();
		} else { $("#sensorsTable thead th:eq(4)").show(); }
		if(vm.tableHeladeraConfigVM.MODEL_ID === false) {
			$("#sensorsTable thead th:eq(5)").hide();
		} else { $("#sensorsTable thead th:eq(5)").show(); }
		if(vm.tableHeladeraConfigVM.SERIAL_NUMBER === false) {
			$("#sensorsTable thead th:eq(6)").hide();
		} else { $("#sensorsTable thead th:eq(6)").show(); }
		if(vm.tableHeladeraConfigVM.GATEWAY_VERSION === false) {
			$("#sensorsTable thead th:eq(7)").hide();
		} else { $("#sensorsTable thead th:eq(7)").show(); }
		if(vm.tableHeladeraConfigVM.STATUS === false) {
			$("#sensorsTable thead th:eq(8)").hide();
		} else { $("#sensorsTable thead th:eq(8)").show(); }
		if(vm.tableHeladeraConfigVM.LAST_COMM === false) {
			$("#sensorsTable thead th:eq(9)").hide();
		} else { $("#sensorsTable thead th:eq(9)").show(); }		
	}


	function initConfigTable() {
		if(!localStorage.getItem('tableHeladeraConfigLS')) {
			localStorage.setItem("tableHeladeraConfigLS", JSON.stringify(tableHeladeraConfig));
			vm.tableHeladeraConfigVM = tableHeladeraConfig;

		} else {		
			vm.tableHeladeraConfigVM = JSON.parse(localStorage.getItem('tableHeladeraConfigLS'));						
		}	
		modifyTitleTable();
	};

	vm.editTable = function() {	
		initConfigTable();
		$('#editTable').modal();		
	};

	vm.updateTableConfig = function() {	

		var newConfig = {   'ALIAS': $('#check_ALIAS').is(':checked'),
							'CLIENT_NAME': $('#check_CLIENT_NAME').is(':checked'),
							'GROUP_NAME': $('#check_GROUP_NAME').is(':checked'),
							'ADRESS': $('#check_ADRESS').is(':checked'),
							'MANUFACTURER_NAME': $('#check_MANUFACTURER_NAME').is(':checked'),
							'MODEL_ID': $('#check_MODEL_ID').is(':checked'),
							'SERIAL_NUMBER': $('#check_SERIAL_NUMBER').is(':checked'),
							'GATEWAY_VERSION': $('#check_GATEWAY_VERSION').is(':checked'),
							'STATUS': $('#check_STATUS').is(':checked'),
							'LAST_COMM': $('#check_LAST_COMM').is(':checked'),
						};		
		
		localStorage.setItem("tableHeladeraConfigLS", JSON.stringify(newConfig));
		vm.tableHeladeraConfigVM = newConfig; 
		modifyTitleTable();

		$('.modal').modal('hide');
		
	};

	vm.btnVisible = function() {

		if ($('#modal-addcoment').hasClass('modal-comment-hidden')) {
			$('#modal-addcoment').removeClass('modal-comment-hidden');
			$('#modal-addcoment').addClass('modal-comment-visible');
			$('#comment').val('').change();
		} else {
			$('#modal-addcoment').removeClass('modal-comment-visible');
			$('#modal-addcoment').addClass('modal-comment-hidden');
			$('#comment').val('').change();
		}	

	}
	
	/* END */
		
		vm.getAll();
		initConfigTable();
	
		// appendModal();  => 02/03/2020 Se comenta por conflictos para guardar config *****
		
		vm.tooltip = function(){
			t(function(){
				$('[data-toggle="tooltip"]').tooltip();
			}, 300);
		}
		
		$('.modal').on('shown.bs.modal', function (e) {
			vm.tooltip();
		});
	
		interval.cancel(r.intervalRefresh);
		r.intervalRefresh = interval(vm.getAll,60000);
		
	
}




