angular.module('app').controller('logsCmdCtrl', ['$rootScope', 'logsCmdService', '$state','$filter', logsCmdCtrl]);

function logsCmdCtrl(r, logsCmdService, state,f) {
	var vm = this;
	vm.new = {};
	vm.list = [];
	vm.orig = [];
	vm.selected = {};
	vm.tableConfig = {"itemsPerPage":10,"maxPages":5};
	vm.filterText = '';

	vm.filtrarTabla = function(){
		vm.list = f("filter")(vm.orig, vm.filterText);
	};
	
	vm.export = function() {
		logsCmdService.exportCsv(vm.list).then(function(data){
			r.downloadCSV(data,'logs');
		});
	}

	vm.getAll = function() {
		logsCmdService.getAll().then(function(data) {
			vm.list = data;
			vm.orig = angular.copy(vm.list);
		});
	}

	vm.getAll();
}