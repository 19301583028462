angular.module('app').factory('usuariosService', ['$rootScope', '$http', usuariosService]);

function usuariosService(r, h) {
    var service = {
        getAll: getAll,
        create: create,
        revoke: revoke,
		remove: remove,
        update: update,
        getAllRoles: getRoles,
        exportCsv: exportCsv,
        getAllClients: getAllClients
    };
    return service;

    function getRoles() {
        return h.get(apiRoute + '/api/rol/').then(function(resp) {
            return resp.data;
        });
    }

    function getAllClients() {
        return h.get(apiRoute + '/api/client/').then(function(resp) {
            return resp.data;
        });
    }    

    function exportCsv(o){
        return h.post(apiRoute + '/api/users/csv/',o).then(function(resp) {
            return resp.data;
        });
    }

    function getAll() {
        return h.get(apiRoute + '/api/users/').then(function(resp) {
            return resp.data;
        });
    }
    function create(o) {
        return h.post(apiRoute + '/api/users/',o).then(function(resp) {
            return resp.data;
        });
    }
    function revoke(o) {
        return h.post(apiRoute + '/api/users/revokeToken',o).then(function(resp) {
            return resp.data;
        });
    }
    function remove(o) {
        return h.delete(apiRoute + '/api/users/'+o._id).then(function(resp) {
            return resp.data;
        });
    }
    function update(o) {
        return h.put(apiRoute + '/api/users/'+o._id,o).then(function(resp) {
            return resp.data;
        });
    }

}