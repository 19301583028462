angular.module('app').controller('usuariosCtrl', ['$rootScope', 'usuariosService', '$state','$timeout','$filter','growl', usuariosCtrl]);

function usuariosCtrl(r, usuariosService, state, t, f,growl) {
	var vm = this;
	vm.new = {};
    vm.list = [];
    vm.orig = [];
    vm.devList = [];
    vm.devOrig = [];
    vm.selected = {};
    vm.tableConfig = {"itemsPerPage":10,"maxPages":5};
    vm.filterText = '';   
    
    vm.userClient = r.user.client ? r.user.client.Name : "Admin";

	vm.filtrarTabla = function(){
    	vm.list = f("filter")(vm.orig, vm.filterText);
    }; 

    vm.export = function() {
		usuariosService.exportCsv(vm.list).then(function(data){
			r.downloadCSV(data,'usuarios');
		});
	}
 
    usuariosService.getAllRoles().then(function(data) {
        vm.listRoles = data;
    });

    usuariosService.getAllClients().then(function(data) {
        vm.listClients = data;
    });


    vm.getAll = function() {

        if (vm.userClient == "Admin") {
            usuariosService.getAll().then(function(data) {
                vm.list = data.map(function(user,i){ 
                                  
                    user.toggleActive = (user.activo == 1);                
                    return user;
                });
                vm.orig = angular.copy(vm.list);
                vm.tooltip();
    
            });
        } else {            
            usuariosService.getAll().then(function(data) {

                var dataFilter = [];

                for (var i = 0; i < data.length; i++) {                    
                    if (data[i].client && data[i].client.Name == vm.userClient) {
                        dataFilter.push(data[i]);
                    } else {}                    
                }

                vm.list = dataFilter.map(function(user,i){                                   
                    user.toggleActive = (user.activo == 1);                
                    return user;
                });             

                vm.orig = angular.copy(vm.list);
                vm.tooltip();
    
            });
        }        
    }

    vm.openModal = function(){
        vm.isEdit = false;
        vm.new = {};
        $('#newUser').modal();
    }
    
    vm.revoke = function(item) {
        swal({
            title: 'Seguro que desea revocar los tokens al usuario?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar'
        }).then(function() {
            usuariosService.revoke(vm.new).then(function() {
                vm.getAll();
                swal({
                    title: 'Los tokens han sido revocados!',
                    type: 'success',
                    confirmButtonText: 'Aceptar'
                });
            });
        }).catch(swal.noop);
    }

    vm.remove = function(item) {
        vm.selected = item;
        swal({
            title: 'Seguro que desea eliminar al usuario?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar'
        }).then(function() {
            usuariosService.remove(vm.selected).then(function() {
                vm.getAll();
                swal({
                    title: 'El usuario ha sido eliminado!',
                    type: 'success',
                    confirmButtonText: 'Aceptar'
                });
            });
        }).catch(swal.noop);
    }

    vm.edit = function(item) {
        vm.isEdit = true;
        vm.new = angular.copy(item);
        vm.new.password = ''; 	
        $('#newUser').modal();      
    }

    vm.toggleActive = function(item){
        item.activo = item.toggleActive ? 1:0;
        usuariosService.update(item).then(function() {
            growl.success(item.toggleActive ? 'Se activo el usuario.':'Se desactivo el usuario.');
        })
    }

    vm.save = function() {
        var obj = angular.copy(vm.new);    
      
            obj.client = obj.client == undefined ? r.user.client._id : obj.client;           

            if(obj.rol && obj.rol != ""){
                if (!obj._id) {                   
                    usuariosService.create(obj).then(function() {
                        vm.new = {};
                        vm.getAll();
                        $('#newUser').modal('hide');
                        growl.success('Se creo el usuario exitosamente.');
                    })
                }else{
                    usuariosService.update(obj).then(function() {
                        vm.new = {};
                        vm.getAll();
                        $('.modal').modal('hide');
                        growl.success('Se actualizo el usuario exitosamente.');
                    })
                }
            } else {
                growl.error('Seleccione un rol.');
            }
       
        
    }
/********************* COMMON FUNCTIONS **********************/


/*************************** INIT ***************************/
 
    vm.getAll();

    appendModal();

    vm.tooltip = function(){
        t(function(){
        $('[data-toggle="tooltip"]').tooltip();
        }, 300);
    }

    $('.modal').on('shown.bs.modal', function (e) {
        vm.tooltip();
    });
    
}