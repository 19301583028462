angular.module('app').controller('eventsCtrl', ['$rootScope', 'eventsService', '$state','$timeout','growl', 'Upload','$filter','$interval','$stateParams', eventsCtrl]);

function eventsCtrl(r, eventsService, state,t,growl,fupload,f,interval,$stateParams) {
	var vm = this;
	vm.sensor = $stateParams.sensor;
	vm.alias;
	vm.cliente;
	vm.comercio;
	vm.address;

	vm.new = {};
	vm.list = [];
	vm.orig = [];
	vm.devList = [];
	vm.devOrig = [];
	vm.selected = {};
	vm.tableConfigEvents = {"itemsPerPage":10,"maxPages":5};
	vm.filterText = '';
	vm.mode = '';

	vm.filtrarTabla = function(){
		vm.list = f("filter")(vm.orig, vm.filterText);
	};
	vm.export = function() {
		eventsService.exportCsv(vm.list).then(function(data){
			r.downloadCSV(data,'tachas');
		});
	}
	
	vm.getAll = function() {
		
	 // Se agrega consulta para traer info de la heladera y sumarla al Header	
		eventsService.getInfo(vm.sensor).then(function(data) {
			for (var i=0; i < data.length; i++) {
				var el = data[i];
				if (el.UID && el.UID == vm.sensor) {
					vm.alias = el.Alias;
					vm.cliente = el.client.Name;
					vm.group = el.group.Name;
					vm.address = el.group.Address; 
					vm.GatewayVersion = el.sensor.GatewayVersion;
				} else {}
			}
		});

		eventsService.getOne(vm.sensor).then(function(data) {
			vm.list = data;
			vm.orig = angular.copy(vm.list);
			vm.tooltip();
			$('#viewEvents').removeClass('hidden');
		});
		
	}
	
	vm.newTacha = function(){
		vm.new = {};
		vm.mode = 'new';
		$('#edit').modal();
	}
	
	vm.viewCmt = function(item) {
		vm.new = angular.copy(item);
		$('#comments').modal();
	};
	
	vm.view = function(item) {
		vm.new = angular.copy(item);
		vm.mode = 'view';
		$('#edit').modal();
	};
	
	vm.edit = function(item) {
		vm.new = angular.copy(item);
		vm.mode = 'edit';
		$('#edit').modal();
	};	
	
	vm.history = function(item) {
		vm.new = angular.copy(item);
		vm.mode = 'history';
		$('#history').modal();
	};	
	
	vm.graph = function(item) {
		vm.new = angular.copy(item);
		vm.mode = 'graph';
		$('#graph').modal();
	};
	
	vm.upload = function(evt) {
		vm.file = evt.target.files[0];
		fupload.upload({
			url: apiRoute + '/api/tachas/import/',
			data: {
				file: vm.file
			},
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('id_token')
			},
			withCredentials: true
		}).then(function(resp) {
			growl.success('Se cargo exitosamente el archivo ' + vm.file.name);
			$('#uploadBtn').val('');
			vm.getAll();
		}, function(resp) {
			$('#uploadBtn').val('');
		});
	};
	
	vm.update = function() {
		var obj = angular.copy(vm.new);
		if(obj._id)
		eventsService.update(obj).then(function() {
			vm.new = {};
			vm.getAll();
			$('.modal').modal('hide');
			growl.success('Se actualizo la tacha exitosamente.');
		});
		else{
			if(obj._geolocation)
				obj._geolocation.coordinates = [obj._geolocation.coordinates[0],obj._geolocation.coordinates[1]];
			eventsService.create(obj).then(function() {
				vm.new = {};
				vm.getAll();
				$('.modal').modal('hide');
				growl.success('Se creo la tacha exitosamente.');
			});
		}
	}
	vm.exportTempOfHeladeraToCsv = function(nameHeladera){
		var properties = [
			['alias'],
			['datetime'],
			['value']
		]
		var list = r.get_values_of_list_generic(vm.list_general_Temperature, properties)

		list = r.sortArrayByDate(list, "datetime")

		list = r.addIndexToTheList(list, "index")

		var header = ['Number', 'Name', 'Time', 'Temperature°C'];

		const listCSV = r.jsonToCSV(header, list);

		r.downloadCSV(listCSV, nameHeladera)
	}

/********************************** EVENTS ARRAYS ************************************/
   
  // General	
   vm.list_general_Temperature = [];
   vm.list_general_Voltage = [];
   vm.list_general_Current = []; 
   vm.list_general_PowerSupply = [];     
   vm.list_general_Battery = []; 
   vm.list_general_Alim_Disconnect = [];
   vm.list_general_NotificationLevel = []; 	
   vm.list_general_LastKeepAlive = [];
   
  // GPS
   vm.list_gps_Ant_Disconnect= [];
   vm.list_gps_GPS= [];
   
  // Potencia
   vm.list_potencia_ActivePower= [];
   vm.list_potencia_AparentPower= [];	
   vm.list_potencia_ReactivePower= [];
   vm.list_potencia_CosPhi= [];
   vm.list_potencia_kWh= [];
   vm.list_potencia_kWhAmount= [];

  // Puerta
   vm.list_puerta_DoorState = [];
   vm.list_puerta_DoorOpenTime = [];
   vm.list_puerta_DoorOpenCount = [];

  // Hardware
   vm.list_hardware_Movement = []; // Inclinacion
   vm.list_hardware_TampersInt = [];
   vm.list_hardware_TampersExt = [];
   vm.list_hardware_TampersController = [];
   vm.list_hardware_Per_Disconnect = [];
   vm.list_hardware_GSM_Signal = [];
   vm.list_hardware_FirmwareVersion = [];

  // Comandos
   vm.list_comando_ProgStatus = [];
   vm.list_comando_ProgCommand = [];
   vm.list_comando_ProgValue = [];

  // Imagenes
	vm.list_imagenes = [];
	
	
/******************************** Click Pestañas *********************************/

clickPestañaEventos = function() {
	vm.getGeneralTab();
}

/*********************************************************************************/

vm.getGeneralTab = function () {

  // Btn's	
  if (vm.GatewayVersion == 'Basic'){
	$('#btnAdminGeneral').css('opacity','0.5');
	$('#btnAdminGPS').css('opacity','0.5');
	$('#btnAdminPotencias').css('opacity','0.5');
	$('#btnAdminPuerta').css('opacity','0.5');
	$('#btnAdminHardware').css('opacity','1');
	$('#btnAdminComandos').css('opacity','0.5');
	$('#btnAdminImagenes').css('opacity','0.5');
  } else {
	$('#btnAdminGeneral').css('opacity','0.5');
	$('#btnAdminGPS').css('opacity','1');
	$('#btnAdminPotencias').css('opacity','1');
	$('#btnAdminPuerta').css('opacity','1');
	$('#btnAdminHardware').css('opacity','1');
	$('#btnAdminComandos').css('opacity','1');
	$('#btnAdminImagenes').css('opacity','1');
  }



  // Pestañas
	$('#tab_puerta').css('display','none');
	$('#tab_potencias').css('display','none');
	$('#tab_gps').css('display','none');
	$('#tab_puerta').css('display','none');
	$('#tab_hardware').css('display','none');
	$('#tab_comandos').css('display','none');
	$('#tab_general').css('display','block');	
	$('#tab_main_imagenes').css('display','none');	

	
  // Pestaña General Init
	$('#li_general_temperatura').addClass('active');
	$('#tab_general_temperatura').addClass('active');
	$('#tab_general_temperatura').addClass('in');

	$('#li_general_corriente').removeClass('active');
	$('#tab_general_corriente').removeClass('active');
	$('#li_general_voltage').removeClass('active');
	$('#tab_general_voltage').removeClass('active');
	$('#li_general_energia').removeClass('active');
	$('#tab_general_energia').removeClass('active');
	$('#li_general_bateria').removeClass('active');
	$('#tab_general_bateria').removeClass('active');
	$('#li_general_alimentacion').removeClass('active');
	$('#tab_general_alimentacion').removeClass('active');
	$('#li_general_nivel_notificacion').removeClass('active');
	$('#tab_general_nivel_notificacion').removeClass('active');
	$('#li_general_keepalive').removeClass('active');
	$('#tab_general_keepalive').removeClass('active');

	$('#li_gps_desconectado').removeClass('active');
	$('#tab_gps_desconectado').removeClass('active');
	$('#li_gps_ubicacion').removeClass('active');
	$('#tab_gps_ubicacion').removeClass('active');

	$('#li_potencia_activa').removeClass('active');
	$('#tab_potencia_activa').removeClass('active');
	$('#li_potencia_aparente').removeClass('active');
	$('#tab_potencia_aparente').removeClass('active');
	$('#li_potencia_reactiva').removeClass('active');
	$('#tab_potencia_reactiva').removeClass('active');
	$('#li_potencia_cosenoPhi').removeClass('active');
	$('#tab_potencia_cosenoPhi').removeClass('active');
	$('#li_potencia_consumo_KW').removeClass('active');
	$('#tab_potencia_consumo_KW').removeClass('active');
	$('#li_potencia_consumo_mensual').removeClass('active');
	$('#tab_potencia_consumo_mensual').removeClass('active');

	$('#li_estado_puerta').removeClass('active');
	$('#tab_estado_puerta').removeClass('active');
	$('#li_tiempo_apertura').removeClass('active');
	$('#tab_tiempo_apertura').removeClass('active');
	$('#li_apertura_diaria').removeClass('active');
	$('#tab_apertura_diaria').removeClass('active');

	$('#li_inclinacion').removeClass('active');
	$('#tab_inclinacion').removeClass('active');
	$('#li_gateway_abierto').removeClass('active');
	$('#tab_gateway_abierto').removeClass('active');
	$('#li_gateway_extraido').removeClass('active');
	$('#tab_gateway_extraido').removeClass('active');
	$('#li_cassete_extraido').removeClass('active');
	$('#tab_cassete_extraido').removeClass('active');

	$('#li_GSM').removeClass('active');
	$('#tab_GSM').removeClass('active');
	$('#li_modo_programacion').removeClass('active');
	$('#tab_modo_programacion').removeClass('active');
	$('#li_ultimo_comando').removeClass('active');
	$('#tab_ultimo_comando').removeClass('active');
	$('#li_FirmwareVersion').removeClass('active');
	$('#tab_FirmwareVersion').removeClass('active');
	$('#li_ultimo_valor').removeClass('active');
	$('#tab_ultimo_valor').removeClass('active');
	

	$('#li_imagenes').removeClass('active');
	$('#tab_imagenes').removeClass('active');


	eventsService.getOne(vm.sensor).then(function(data) {

		if (data.General != undefined && data.General.Temperatura != undefined) {
			vm.list_general_Temperature.lenght = 0;
			vm.list_general_Temperature = data.General.Temperatura;
		} else {}

		if (data.General != undefined && data.General.Corriente != undefined) {
			vm.list_general_Current.lenght = 0;
			vm.list_general_Current = data.General.Corriente;				
		} else {}

		if (data.General != undefined && data.General.Voltage != undefined) {
			vm.list_general_Voltage.lenght = 0;
			vm.list_general_Voltage = data.General.Voltage;
		} else {}

		if (data.General != undefined && data.General.Energía != undefined) { 
			vm.list_general_PowerSupply.lenght = 0;
			vm.list_general_PowerSupply = data.General.Energía;
		} else {}

		if (data.General != undefined && data.General.Batería != undefined) {
			vm.list_general_Battery.lenght = 0;
			vm.list_general_Battery = data.General.Batería;		
		} else {}

		if (data.General != undefined && data.General["Alimentación Desconectada"] != undefined) { 
			vm.list_general_Alim_Disconnect.lenght = 0;
			vm.list_general_Alim_Disconnect = data.General["Alimentación Desconectada"];				
		} else {}

		if (data.General != undefined && data.General["Nivel de Notificación"] != undefined) {
			vm.list_general_NotificationLevel.lenght = 0;
			vm.list_general_NotificationLevel = data.General["Nivel de Notificación"];			
		} else {}

		if (data.General != undefined && data.General["Ultimo KeepAlive"] != undefined) {
			vm.list_general_LastKeepAlive.lenght = 0;
			vm.list_general_LastKeepAlive = data.General["Ultimo KeepAlive"];			
		} else {}

	});

}

vm.getGPSTab = function () {

  // Btn's	
	$('#btnAdminGeneral').css('opacity','1');
	$('#btnAdminGPS').css('opacity','0.5');
	$('#btnAdminPotencias').css('opacity','1');
	$('#btnAdminPuerta').css('opacity','1');
	$('#btnAdminHardware').css('opacity','1');
	$('#btnAdminComandos').css('opacity','1');
	$('#btnImagenes').css('opacity','1');
	  
  // Pestañas
	$('#tab_puerta').css('display','none');
	$('#tab_potencias').css('display','none');
	$('#tab_gps').css('display','block');
	$('#tab_puerta').css('display','none');
	$('#tab_hardware').css('display','none');
	$('#tab_comandos').css('display','none');
	$('#tab_general').css('display','none');
	$('#tab_main_imagenes').css('display','none');

  // Pestaña GPS Init
	$('#li_gps_desconectado').addClass('active');
	$('#tab_gps_desconectado').addClass('active');
	$('#tab_gps_desconectado').addClass('in');

	$('#li_general_temperatura').removeClass('active');
	$('#tab_general_temperatura').removeClass('active');
	$('#li_general_corriente').removeClass('active');
	$('#tab_general_corriente').removeClass('active');
	$('#li_general_voltage').removeClass('active');
	$('#tab_general_voltage').removeClass('active');
	$('#li_general_energia').removeClass('active');
	$('#tab_general_energia').removeClass('active');
	$('#li_general_bateria').removeClass('active');
	$('#tab_general_bateria').removeClass('active');
	$('#li_general_alimentacion').removeClass('active');
	$('#tab_general_alimentacion').removeClass('active');
	$('#li_general_nivel_notificacion').removeClass('active');
	$('#tab_general_nivel_notificacion').removeClass('active');
	$('#li_general_keepalive').removeClass('active');
	$('#tab_general_keepalive').removeClass('active');

	$('#li_gps_ubicacion').removeClass('active');
	$('#tab_gps_ubicacion').removeClass('active');

	$('#li_potencia_activa').removeClass('active');
	$('#tab_potencia_activa').removeClass('active');
	$('#li_potencia_aparente').removeClass('active');
	$('#tab_potencia_aparente').removeClass('active');
	$('#li_potencia_reactiva').removeClass('active');
	$('#tab_potencia_reactiva').removeClass('active');
	$('#li_potencia_cosenoPhi').removeClass('active');
	$('#tab_potencia_cosenoPhi').removeClass('active');
	$('#li_potencia_consumo_KW').removeClass('active');
	$('#tab_potencia_consumo_KW').removeClass('active');
	$('#li_potencia_consumo_mensual').removeClass('active');
	$('#tab_potencia_consumo_mensual').removeClass('active');

	$('#li_estado_puerta').removeClass('active');
	$('#tab_estado_puerta').removeClass('active');
	$('#li_tiempo_apertura').removeClass('active');
	$('#tab_tiempo_apertura').removeClass('active');
	$('#li_apertura_diaria').removeClass('active');
	$('#tab_apertura_diaria').removeClass('active');

	$('#li_inclinacion').removeClass('active');
	$('#tab_inclinacion').removeClass('active');
	$('#li_gateway_abierto').removeClass('active');
	$('#tab_gateway_abierto').removeClass('active');
	$('#li_gateway_extraido').removeClass('active');
	$('#tab_gateway_extraido').removeClass('active');
	$('#li_cassete_extraido').removeClass('active');
	$('#tab_cassete_extraido').removeClass('active');

	$('#li_GSM').removeClass('active');
	$('#tab_GSM').removeClass('active');
	$('#li_modo_programacion').removeClass('active');
	$('#tab_modo_programacion').removeClass('active');
	$('#li_ultimo_comando').removeClass('active');
	$('#tab_ultimo_comando').removeClass('active');
	$('#li_ultimo_valor').removeClass('active');
	$('#tab_ultimo_valor').removeClass('active');

	$('#li_imagenes').removeClass('active');
	$('#tab_imagenes').removeClass('active');

	eventsService.getOne(vm.sensor).then(function(data) { 

		if (data.GPS != undefined && data.GPS["GPS Desconectado"] != undefined) { 
			vm.list_gps_Ant_Disconnect.lenght = 0;
			vm.list_gps_Ant_Disconnect = data.GPS["GPS Desconectado"];
		} else {}

		if (data.GPS != undefined && data.GPS.Ubicación != undefined) {
			vm.list_gps_GPS.lenght = 0;
			vm.list_gps_GPS = data.GPS.Ubicación;		
		} else {}

	});

}

vm.getPotenciasTab = function () {

  // Btn's	
	$('#btnAdminGeneral').css('opacity','1');
	$('#btnAdminGPS').css('opacity','1');
	$('#btnAdminPotencias').css('opacity','0.5');
	$('#btnAdminPuerta').css('opacity','1');
	$('#btnAdminHardware').css('opacity','1');
	$('#btnAdminComandos').css('opacity','1');
	$('#btnImagenes').css('opacity','1');
	  
  // Pestañas
	$('#tab_puerta').css('display','none');
	$('#tab_potencias').css('display','block');
	$('#tab_gps').css('display','none');
	$('#tab_puerta').css('display','none');
	$('#tab_hardware').css('display','none');
	$('#tab_comandos').css('display','none');
	$('#tab_general').css('display','none');
	$('#tab_main_imagenes').css('display','none');

  // Pestaña Potencias Init
	$('#li_potencia_activa').addClass('active');
	$('#tab_potencia_activa').addClass('active');
	$('#tab_potencia_activa').addClass('in');	

	$('#li_general_temperatura').removeClass('active');
	$('#tab_general_temperatura').removeClass('active');
	$('#li_general_corriente').removeClass('active');
	$('#tab_general_corriente').removeClass('active');
	$('#li_general_voltage').removeClass('active');
	$('#tab_general_voltage').removeClass('active');
	$('#li_general_energia').removeClass('active');
	$('#tab_general_energia').removeClass('active');
	$('#li_general_bateria').removeClass('active');
	$('#tab_general_bateria').removeClass('active');
	$('#li_general_alimentacion').removeClass('active');
	$('#tab_general_alimentacion').removeClass('active');
	$('#li_general_nivel_notificacion').removeClass('active');
	$('#tab_general_nivel_notificacion').removeClass('active');
	$('#li_general_keepalive').removeClass('active');
	$('#tab_general_keepalive').removeClass('active');

	$('#li_gps_desconectado').removeClass('active');
	$('#tab_gps_desconectado').removeClass('active');
	$('#li_gps_ubicacion').removeClass('active');
	$('#tab_gps_ubicacion').removeClass('active');
	
	$('#li_potencia_aparente').removeClass('active');
	$('#tab_potencia_aparente').removeClass('active');
	$('#li_potencia_reactiva').removeClass('active');
	$('#tab_potencia_reactiva').removeClass('active');
	$('#li_potencia_cosenoPhi').removeClass('active');
	$('#tab_potencia_cosenoPhi').removeClass('active');
	$('#li_potencia_consumo_KW').removeClass('active');
	$('#tab_potencia_consumo_KW').removeClass('active');
	$('#li_potencia_consumo_mensual').removeClass('active');
	$('#tab_potencia_consumo_mensual').removeClass('active');

	$('#li_estado_puerta').removeClass('active');
	$('#tab_estado_puerta').removeClass('active');
	$('#li_tiempo_apertura').removeClass('active');
	$('#tab_tiempo_apertura').removeClass('active');
	$('#li_apertura_diaria').removeClass('active');
	$('#tab_apertura_diaria').removeClass('active');

	$('#li_inclinacion').removeClass('active');
	$('#tab_inclinacion').removeClass('active');
	$('#li_gateway_abierto').removeClass('active');
	$('#tab_gateway_abierto').removeClass('active');
	$('#li_gateway_extraido').removeClass('active');
	$('#tab_gateway_extraido').removeClass('active');
	$('#li_cassete_extraido').removeClass('active');
	$('#tab_cassete_extraido').removeClass('active');

	$('#li_GSM').removeClass('active');
	$('#tab_GSM').removeClass('active');
	$('#li_modo_programacion').removeClass('active');
	$('#tab_modo_programacion').removeClass('active');
	$('#li_ultimo_comando').removeClass('active');
	$('#tab_ultimo_comando').removeClass('active');
	$('#li_ultimo_valor').removeClass('active');
	$('#tab_ultimo_valor').removeClass('active');

	$('#li_imagenes').removeClass('active');
	$('#tab_imagenes').removeClass('active');

	eventsService.getOne(vm.sensor).then(function(data) {

		if (data.Potencias != undefined && data.Potencias["Potencia Activa"] != undefined) {				
			vm.list_potencia_ActivePower.lenght = 0;
			vm.list_potencia_ActivePower = data.Potencias["Potencia Activa"];
		} else {}

		if (data.Potencias != undefined && data.Potencias["Potencia Aparente"] != undefined) {
			vm.list_potencia_AparentPower.lenght = 0;
			vm.list_potencia_AparentPower = data.Potencias["Potencia Aparente"];
		} else {}

		if (data.Potencias != undefined && data.Potencias["Potencia Reactiva"] != undefined) {
			vm.list_potencia_ReactivePower.lenght = 0;
			vm.list_potencia_ReactivePower = data.Potencias["Potencia Reactiva"];
		} else {}

		if (data.Potencias != undefined && data.Potencias["Coseno Phi"] != undefined) {
			vm.list_potencia_CosPhi.lenght = 0;
			vm.list_potencia_CosPhi = data.Potencias["Coseno Phi"];
		} else {}

		if (data.Potencias != undefined && data.Potencias["Consumo eléctrico [kWh]"] != undefined) {
			vm.list_potencia_kWh.lenght = 0;
			vm.list_potencia_kWh = data.Potencias["Consumo eléctrico [kWh]"];
		} else {}

		if (data.Potencias != undefined && data.Potencias["Consumo eléctrico [$]"] != undefined) {
			vm.list_potencia_kWhAmount.lenght = 0;
			vm.list_potencia_kWhAmount = data.Potencias["Consumo eléctrico [$]"];
		} else {}

	});
}

vm.getPuertaTab = function () {

   // Btn's	
	$('#btnAdminGeneral').css('opacity','1');
	$('#btnAdminGPS').css('opacity','1');
	$('#btnAdminPotencias').css('opacity','1');
	$('#btnAdminPuerta').css('opacity','0.5');
	$('#btnAdminHardware').css('opacity','1');
	$('#btnAdminComandos').css('opacity','1');
	$('#btnImagenes').css('opacity','1');
	  
  // Pestañas
	$('#tab_puerta').css('display','none');
	$('#tab_potencias').css('display','none');
	$('#tab_gps').css('display','none');
	$('#tab_puerta').css('display','block');
	$('#tab_hardware').css('display','none');
	$('#tab_comandos').css('display','none');
	$('#tab_general').css('display','none');
	$('#tab_main_imagenes').css('display','none');

  // Pestaña Puerta Init
	$('#li_estado_puerta').addClass('active');
	$('#tab_estado_puerta').addClass('active');
	$('#tab_estado_puerta').addClass('in');

	$('#li_general_temperatura').removeClass('active');
	$('#tab_general_temperatura').removeClass('active');
	$('#li_general_corriente').removeClass('active');
	$('#tab_general_corriente').removeClass('active');
	$('#li_general_voltage').removeClass('active');
	$('#tab_general_voltage').removeClass('active');
	$('#li_general_energia').removeClass('active');
	$('#tab_general_energia').removeClass('active');
	$('#li_general_bateria').removeClass('active');
	$('#tab_general_bateria').removeClass('active');
	$('#li_general_alimentacion').removeClass('active');
	$('#tab_general_alimentacion').removeClass('active');
	$('#li_general_nivel_notificacion').removeClass('active');
	$('#tab_general_nivel_notificacion').removeClass('active');
	$('#li_general_keepalive').removeClass('active');
	$('#tab_general_keepalive').removeClass('active');

	$('#li_gps_desconectado').removeClass('active');
	$('#tab_gps_desconectado').removeClass('active');
	$('#li_gps_ubicacion').removeClass('active');
	$('#tab_gps_ubicacion').removeClass('active');

	$('#li_potencia_activa').removeClass('active');
	$('#tab_potencia_activa').removeClass('active');
	$('#li_potencia_aparente').removeClass('active');
	$('#tab_potencia_aparente').removeClass('active');
	$('#li_potencia_reactiva').removeClass('active');
	$('#tab_potencia_reactiva').removeClass('active');
	$('#li_potencia_cosenoPhi').removeClass('active');
	$('#tab_potencia_cosenoPhi').removeClass('active');
	$('#li_potencia_consumo_KW').removeClass('active');
	$('#tab_potencia_consumo_KW').removeClass('active');
	$('#li_potencia_consumo_mensual').removeClass('active');
	$('#tab_potencia_consumo_mensual').removeClass('active');

	
	$('#li_tiempo_apertura').removeClass('active');
	$('#tab_tiempo_apertura').removeClass('active');
	$('#li_apertura_diaria').removeClass('active');
	$('#tab_apertura_diaria').removeClass('active');

	$('#li_inclinacion').removeClass('active');
	$('#tab_inclinacion').removeClass('active');
	$('#li_gateway_abierto').removeClass('active');
	$('#tab_gateway_abierto').removeClass('active');
	$('#li_gateway_extraido').removeClass('active');
	$('#tab_gateway_extraido').removeClass('active');
	$('#li_cassete_extraido').removeClass('active');
	$('#tab_cassete_extraido').removeClass('active');

	$('#li_GSM').removeClass('active');
	$('#tab_GSM').removeClass('active');
	$('#li_modo_programacion').removeClass('active');
	$('#tab_modo_programacion').removeClass('active');
	$('#li_ultimo_comando').removeClass('active');
	$('#tab_ultimo_comando').removeClass('active');
	$('#li_ultimo_valor').removeClass('active');
	$('#tab_ultimo_valor').removeClass('active');

	$('#li_imagenes').removeClass('active');
	$('#tab_imagenes').removeClass('active');

	eventsService.getOne(vm.sensor).then(function(data) {

		if (data.Puerta != undefined && data.Puerta["Estado Puerta"] != undefined) { 
			vm.list_puerta_DoorState.lenght = 0;
			vm.list_puerta_DoorState = data.Puerta["Estado Puerta"];
		} else {}

		if (data.Puerta != undefined && data.Puerta["Tiempo de Apertura"] != undefined) { 
			vm.list_puerta_DoorOpenTime.lenght = 0;
			vm.list_puerta_DoorOpenTime = data.Puerta["Tiempo de Apertura"];
		} else {}

		if (data.Puerta != undefined && data.Puerta["Aperturas Diarias"] != undefined) { 
			vm.list_puerta_DoorOpenCount.lenght = 0;
			vm.list_puerta_DoorOpenCount = data.Puerta["Aperturas Diarias"];
		} else {}

	});

}

vm.getHardwareTab = function () {
	
  // Btn's	
 
	if (vm.GatewayVersion == 'Basic'){
		$('#btnAdminGeneral').css('opacity','1');
		$('#btnAdminGPS').css('opacity','0.5');
		$('#btnAdminPotencias').css('opacity','0.5');
		$('#btnAdminPuerta').css('opacity','0.5');
		$('#btnAdminHardware').css('opacity','0.5');
		$('#btnAdminComandos').css('opacity','0.5');
		$('#btnAdminImagenes').css('opacity','0.5');
	  } else {
		$('#btnAdminGeneral').css('opacity','1');
		$('#btnAdminGPS').css('opacity','1');
		$('#btnAdminPotencias').css('opacity','1');
		$('#btnAdminPuerta').css('opacity','1');
		$('#btnAdminHardware').css('opacity','0.5');
		$('#btnAdminComandos').css('opacity','1');
		$('#btnImagenes').css('opacity','1');
	  }
	
		
  // Pestañas
	$('#tab_puerta').css('display','none');
	$('#tab_potencias').css('display','none');
	$('#tab_gps').css('display','none');
	$('#tab_puerta').css('display','none');
	$('#tab_hardware').css('display','block');
	$('#tab_comandos').css('display','none');
	$('#tab_general').css('display','none');
	$('#tab_main_imagenes').css('display','none');

  // Pestaña Hardware Init
	$('#li_inclinacion').addClass('active');
	$('#tab_inclinacion').addClass('active');
	$('#tab_inclinacion').addClass('in');

	$('#li_general_temperatura').removeClass('active');
	$('#tab_general_temperatura').removeClass('active');
	$('#li_general_corriente').removeClass('active');
	$('#tab_general_corriente').removeClass('active');
	$('#li_general_voltage').removeClass('active');
	$('#tab_general_voltage').removeClass('active');
	$('#li_general_energia').removeClass('active');
	$('#tab_general_energia').removeClass('active');
	$('#li_general_bateria').removeClass('active');
	$('#tab_general_bateria').removeClass('active');
	$('#li_general_alimentacion').removeClass('active');
	$('#tab_general_alimentacion').removeClass('active');
	$('#li_general_nivel_notificacion').removeClass('active');
	$('#tab_general_nivel_notificacion').removeClass('active');
	$('#li_general_keepalive').removeClass('active');
	$('#tab_general_keepalive').removeClass('active');

	$('#li_gps_desconectado').removeClass('active');
	$('#tab_gps_desconectado').removeClass('active');
	$('#li_gps_ubicacion').removeClass('active');
	$('#tab_gps_ubicacion').removeClass('active');

	$('#li_potencia_activa').removeClass('active');
	$('#tab_potencia_activa').removeClass('active');
	$('#li_potencia_aparente').removeClass('active');
	$('#tab_potencia_aparente').removeClass('active');
	$('#li_potencia_reactiva').removeClass('active');
	$('#tab_potencia_reactiva').removeClass('active');
	$('#li_potencia_cosenoPhi').removeClass('active');
	$('#tab_potencia_cosenoPhi').removeClass('active');
	$('#li_potencia_consumo_KW').removeClass('active');
	$('#tab_potencia_consumo_KW').removeClass('active');
	$('#li_potencia_consumo_mensual').removeClass('active');
	$('#tab_potencia_consumo_mensual').removeClass('active');

	$('#li_estado_puerta').removeClass('active');
	$('#tab_estado_puerta').removeClass('active');
	$('#li_tiempo_apertura').removeClass('active');
	$('#tab_tiempo_apertura').removeClass('active');
	$('#li_apertura_diaria').removeClass('active');
	$('#tab_apertura_diaria').removeClass('active');
	
	$('#li_gateway_abierto').removeClass('active');
	$('#tab_gateway_abierto').removeClass('active');
	$('#li_gateway_extraido').removeClass('active');
	$('#tab_gateway_extraido').removeClass('active');
	$('#li_cassete_extraido').removeClass('active');
	$('#tab_cassete_extraido').removeClass('active');
	$('#li_GSM').removeClass('active');
	$('#tab_GSM').removeClass('active');
	$('#li_FirmwareVersion').removeClass('active');
	$('#tab_FirmwareVersion').removeClass('active');

	$('#li_modo_programacion').removeClass('active');
	$('#tab_modo_programacion').removeClass('active');
	$('#li_ultimo_comando').removeClass('active');
	$('#tab_ultimo_comando').removeClass('active');
	$('#li_ultimo_valor').removeClass('active');
	$('#tab_ultimo_valor').removeClass('active');

	$('#li_imagenes').removeClass('active');
	$('#tab_imagenes').removeClass('active');

	eventsService.getOne(vm.sensor).then(function(data) {
		
		if (data.Hardware != undefined && data.Hardware["Inclinación"] != undefined) { 
			vm.list_hardware_Movement.lenght = 0;
			vm.list_hardware_Movement = data.Hardware["Inclinación"];
		} else {}

		if (data.Hardware != undefined && data.Hardware["Tiempo de Apertura"] != undefined ) { 
			vm.list_hardware_TampersInt.lenght = 0;
			vm.list_hardware_TampersInt = data.Hardware["Tiempo de Apertura"];
		} else {}

		if (data.Hardware != undefined && data.Hardware["Aperturas Diarias"] != undefined) { 
			vm.list_hardware_TampersExt.lenght = 0;
			vm.list_hardware_TampersExt = data.Hardware["Aperturas Diarias"];
		} else {}

		if (data.Hardware != undefined && data.Hardware["Cassette Extraído"] != undefined) { 
			vm.list_hardware_TampersController.lenght = 0;
			vm.list_hardware_TampersController = data.Hardware["Cassette Extraído"];
		} else {}

		if (data.Hardware != undefined && data.Hardware["Señal GSM"] != undefined) { 
			vm.list_hardware_GSM_Signal.lenght = 0;
			vm.list_hardware_GSM_Signal = data.Hardware["Señal GSM"];
		} else {}

		if (data.Hardware != undefined && data.Hardware["Firmware version"] != undefined) { 
			vm.list_hardware_FirmwareVersion.lenght = 0;
			vm.list_hardware_FirmwareVersion = data.Hardware["Firmware version"];
		} else {}

	});

}

vm.getComandosTab = function () {

  // Btn's	
	$('#btnAdminGeneral').css('opacity','1');
	$('#btnAdminGPS').css('opacity','1');
	$('#btnAdminPotencias').css('opacity','1');
	$('#btnAdminPuerta').css('opacity','1');
	$('#btnAdminHardware').css('opacity','1');
	$('#btnAdminComandos').css('opacity','0.5');
	$('#btnImagenes').css('opacity','1');
		
  // Pestañas
	$('#tab_puerta').css('display','none');
	$('#tab_potencias').css('display','none');
	$('#tab_gps').css('display','none');
	$('#tab_puerta').css('display','none');
	$('#tab_hardware').css('display','none');
	$('#tab_comandos').css('display','block');
	$('#tab_general').css('display','none');
	$('#tab_main_imagenes').css('display','none');

  // Pestaña Comandos Init
  	$('#li_modo_programacion').addClass('active');
	$('#tab_modo_programacion').addClass('active');
	$('#tab_modo_programacion').addClass('in');

	$('#li_general_temperatura').removeClass('active');
	$('#tab_general_temperatura').removeClass('active');
	$('#li_general_corriente').removeClass('active');
	$('#tab_general_corriente').removeClass('active');
	$('#li_general_voltage').removeClass('active');
	$('#tab_general_voltage').removeClass('active');
	$('#li_general_energia').removeClass('active');
	$('#tab_general_energia').removeClass('active');
	$('#li_general_bateria').removeClass('active');
	$('#tab_general_bateria').removeClass('active');
	$('#li_general_alimentacion').removeClass('active');
	$('#tab_general_alimentacion').removeClass('active');
	$('#li_general_nivel_notificacion').removeClass('active');
	$('#tab_general_nivel_notificacion').removeClass('active');
	$('#li_general_keepalive').removeClass('active');
	$('#tab_general_keepalive').removeClass('active');

	$('#li_gps_desconectado').removeClass('active');
	$('#tab_gps_desconectado').removeClass('active');
	$('#li_gps_ubicacion').removeClass('active');
	$('#tab_gps_ubicacion').removeClass('active');

	$('#li_potencia_activa').removeClass('active');
	$('#tab_potencia_activa').removeClass('active');
	$('#li_potencia_aparente').removeClass('active');
	$('#tab_potencia_aparente').removeClass('active');
	$('#li_potencia_reactiva').removeClass('active');
	$('#tab_potencia_reactiva').removeClass('active');
	$('#li_potencia_cosenoPhi').removeClass('active');
	$('#tab_potencia_cosenoPhi').removeClass('active');
	$('#li_potencia_consumo_KW').removeClass('active');
	$('#tab_potencia_consumo_KW').removeClass('active');
	$('#li_potencia_consumo_mensual').removeClass('active');
	$('#tab_potencia_consumo_mensual').removeClass('active');

	$('#li_estado_puerta').removeClass('active');
	$('#tab_estado_puerta').removeClass('active');
	$('#li_tiempo_apertura').removeClass('active');
	$('#tab_tiempo_apertura').removeClass('active');
	$('#li_apertura_diaria').removeClass('active');
	$('#tab_apertura_diaria').removeClass('active');

	$('#li_inclinacion').removeClass('active');
	$('#tab_inclinacion').removeClass('active');
	$('#li_gateway_abierto').removeClass('active');
	$('#tab_gateway_abierto').removeClass('active');
	$('#li_gateway_extraido').removeClass('active');
	$('#tab_gateway_extraido').removeClass('active');
	$('#li_cassete_extraido').removeClass('active');
	$('#tab_cassete_extraido').removeClass('active');
	$('#li_GSM').removeClass('active');
	$('#tab_GSM').removeClass('active');

	$('#li_ultimo_comando').removeClass('active');
	$('#tab_ultimo_comando').removeClass('active');
	$('#li_ultimo_valor').removeClass('active');
	$('#tab_ultimo_valor').removeClass('active');

	$('#li_imagenes').removeClass('active');
	$('#tab_imagenes').removeClass('active');

	eventsService.getOne(vm.sensor).then(function(data) {

		if (data.Comandos != undefined && data.Comandos["Modo Programación Activado"] != undefined) { 
			vm.list_comando_ProgStatus.lenght = 0;
			vm.list_comando_ProgStatus = data.Comandos["Modo Programación Activado"];
		} else {}

		if (data.Comandos != undefined && data.Comandos["Ultimo Comando Recibido"] != undefined) { 
			vm.list_comando_ProgCommand.lenght = 0;
			vm.list_comando_ProgCommand = data.Comandos["Ultimo Comando Recibido"];
		} else {}

		if (data.Comandos != undefined && data.Comandos["Ultimo Valor de Comando"] != undefined) { 
			vm.list_comando_ProgValue.lenght = 0;
			vm.list_comando_ProgValue = data.Comandos["Ultimo Valor de Comando"];
		} else {}

	});

}

vm.getImagenesTab = function () {

	// Btn's	
	  $('#btnAdminGeneral').css('opacity','1');
	  $('#btnAdminGPS').css('opacity','1');
	  $('#btnAdminPotencias').css('opacity','1');
	  $('#btnAdminPuerta').css('opacity','1');
	  $('#btnAdminHardware').css('opacity','1');
	  $('#btnAdminComandos').css('opacity','1');
	  $('#btnImagenes').css('opacity','0.5');
		  
	// Pestañas
	  $('#tab_main_imagenes').css('display','block');
	  $('#tab_puerta').css('display','none');
	  $('#tab_potencias').css('display','none');
	  $('#tab_gps').css('display','none');
	  $('#tab_puerta').css('display','none');
	  $('#tab_hardware').css('display','none');
	  $('#tab_comandos').css('display','none');
	  $('#tab_general').css('display','none');
  
	// Pestaña Comandos Init
	  $('#li_imagenes').addClass('active');
	  $('#tab_imagenes').addClass('active');
	  $('#tab_imagenes').addClass('in');	  
  
	  $('#li_general_temperatura').removeClass('active');
	  $('#tab_general_temperatura').removeClass('active');
	  $('#li_general_corriente').removeClass('active');
	  $('#tab_general_corriente').removeClass('active');
	  $('#li_general_voltage').removeClass('active');
	  $('#tab_general_voltage').removeClass('active');
	  $('#li_general_energia').removeClass('active');
	  $('#tab_general_energia').removeClass('active');
	  $('#li_general_bateria').removeClass('active');
	  $('#tab_general_bateria').removeClass('active');
	  $('#li_general_alimentacion').removeClass('active');
	  $('#tab_general_alimentacion').removeClass('active');
	  $('#li_general_nivel_notificacion').removeClass('active');
	  $('#tab_general_nivel_notificacion').removeClass('active');
	  $('#li_general_keepalive').removeClass('active');
	  $('#tab_general_keepalive').removeClass('active');
  
	  $('#li_gps_desconectado').removeClass('active');
	  $('#tab_gps_desconectado').removeClass('active');
	  $('#li_gps_ubicacion').removeClass('active');
	  $('#tab_gps_ubicacion').removeClass('active');
  
	  $('#li_potencia_activa').removeClass('active');
	  $('#tab_potencia_activa').removeClass('active');
	  $('#li_potencia_aparente').removeClass('active');
	  $('#tab_potencia_aparente').removeClass('active');
	  $('#li_potencia_reactiva').removeClass('active');
	  $('#tab_potencia_reactiva').removeClass('active');
	  $('#li_potencia_cosenoPhi').removeClass('active');
	  $('#tab_potencia_cosenoPhi').removeClass('active');
	  $('#li_potencia_consumo_KW').removeClass('active');
	  $('#tab_potencia_consumo_KW').removeClass('active');
	  $('#li_potencia_consumo_mensual').removeClass('active');
	  $('#tab_potencia_consumo_mensual').removeClass('active');
  
	  $('#li_estado_puerta').removeClass('active');
	  $('#tab_estado_puerta').removeClass('active');
	  $('#li_tiempo_apertura').removeClass('active');
	  $('#tab_tiempo_apertura').removeClass('active');
	  $('#li_apertura_diaria').removeClass('active');
	  $('#tab_apertura_diaria').removeClass('active');
  
	  $('#li_inclinacion').removeClass('active');
	  $('#tab_inclinacion').removeClass('active');
	  $('#li_gateway_abierto').removeClass('active');
	  $('#tab_gateway_abierto').removeClass('active');
	  $('#li_gateway_extraido').removeClass('active');
	  $('#tab_gateway_extraido').removeClass('active');
	  $('#li_cassete_extraido').removeClass('active');
	  $('#tab_cassete_extraido').removeClass('active');
	  $('#li_GSM').removeClass('active');
	  $('#tab_GSM').removeClass('active');
  
	  $('#li_ultimo_comando').removeClass('active');
	  $('#tab_ultimo_comando').removeClass('active');
	  $('#li_ultimo_valor').removeClass('active');
	  $('#tab_ultimo_valor').removeClass('active');
	  $('#li_modo_programacion').removeClass('active');
	  $('#tab_modo_programacion').removeClass('active');
  
  
	  eventsService.getOne(vm.sensor).then(function(data) {
  
		if (data.Photo != undefined && data.Photo["Imagen"] != undefined) { 
			vm.list_imagenes.lenght = 0;
			vm.list_imagenes = data.Photo["Imagen"];
		} else {}
  
	  });
  
  }

/***********************************************************************************/

	vm.showImage = function(item) {

		var idImage = item.value
		vm.uidSensableImage = item.uid;
		vm.aliasSensableImage = item.alias ;
		vm.fechaSensableImage = item.datetime;

		eventsService.getImageById(idImage).then(function(data) {
			
			var imageParsed = "data:image/jpg;base64," + data.data;
		
			$('#divImageById').empty().append('<div>' +   
												'<figure><img src="'+ imageParsed +'"></figure>' +
									  		  '</div>');
			$('#modalImageById').modal();

		}); 	

	}

/***********************************************************************************/

	vm.activeEvents = function(){

		sessionStorage.setItem('fromEvents', '1');
		location.href('/heladeras');
		
	}

	vm.activeInformes = function(){

		sessionStorage.setItem('fromInformes', '1');
		location.href('/ajustes');
		
	}

/***********************************************************************************/

	vm.getGeneralTab();
	vm.getAll();
	
	appendModal();
	
	vm.tooltip = function(){
		t(function(){
			$('[data-toggle="tooltip"]').tooltip();
		}, 300);
	}
	
	$('.modal').on('shown.bs.modal', function (e) {
		vm.tooltip();
	});

	r.intervalRefresh = interval(vm.getAll,900000);
	
}