angular.module('app').controller('navCtrl', ['$scope', '$rootScope', '$state', '$timeout', '$interval','tachasService', navCtrl]);

function navCtrl(s, r, state, t, interval,tachas) {
    
    var vm = this;
    vm.connection = {};
    vm.updateMqtt = function(){
        tachas.mqttUpdate(vm.connection).then(function(data){
            t(function(){
                vm.mqttStatus();
            },1000)
        });
    }
    vm.mqttStatus = function(){
        tachas.mqttStatus().then(function(data){
            vm.connection = data;
        });
    }
    $('#mqttModal').on('show.bs.modal', function (event) {
        $('.navbar-collapse').collapse('hide');
        vm.mqttStatus();
    })
    vm.mqttStatus();
    r.mqttStatusInterval = interval(vm.mqttStatus,180000);
}