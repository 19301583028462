var apiRoute = '';

angular.module('app', ['ui.router', 'angular-jwt','blockUI', 'angular-growl', 'angular-table','uiSwitch','ngFileUpload'])
.run(['$rootScope', '$state', 'authManager', 'jwtHelper', '$anchorScroll', 'growl','$interval', function(r, s, authManager, jwtHelper, $anchorScroll, growl, interval) {
    r.hideNav = false;
    r.navTitle = '';
    r.active = s.current.name;
    r.intervalRefresh;
    r.mqttStatusInterval;
    r.isMobile = window.matchMedia("(max-width: 768px)").matches;
    
    r.setStorage = function(name, obj) {
        localStorage.setItem(name, JSON.stringify(obj));
    };
    
    r.getStorage = function(name) {
        return JSON.parse(localStorage.getItem(name));
    };
    
    if (authManager.isAuthenticated() && !r.user) {
        r.user = jwtHelper.decodeToken(localStorage.getItem('id_token')).user;
        r.user.profile = r.getStorage('profile');
    }
    r.downloadCSV = function(data,name){
        var date = new Date()
        var anchor = angular.element('<a/>');
			anchor.attr({
				href: 'data:attachment/csv;charset=utf-8,' + encodeURI(data),
				target: '_blank',
				download: (name + "--" + date.getUTCDate() + "-" + (date.getUTCMonth() + 1) + "-" + date.getUTCFullYear() + '.csv')
			})[0].click();
    };
    r.downloadPDF = function(data,name){
        var anchor = angular.element('<a/>');
			anchor.attr({
				href: data,
				target: '_blank',
				download: (new Date()).getTime()+'-'+name+'.pdf'
			})[0].click();
    };
    r.logout = function() {
        localStorage.removeItem('id_token');
        localStorage.removeItem('profile');
        r.user = false;
        s.go('auth');
        swal.close();
        $('.modal').modal('hide');
        interval.cancel(r.mqttStatusInterval);
    };
    
    r.hasRole = function(role) {
        if (r.user && r.user.rol) {
            if (typeof r.user.rol.descripcion === "string") {
                return r.user.rol.descripcion === role;
            } else {
                for (var i in r.user.rol) {
                    if (r.user.rol[i] === role)
                        return true;                                   
                }
            }
        }
        return false;
    };
    
    r.findByAttr = function(a, q, attr) {
        return a.filter(function(i) {
            if (i[attr] === q) {
                return i;
            }
            return false;
        })[0];
    };
    authManager.checkAuthOnRefresh();
    authManager.redirectWhenUnauthenticated();
    r.$on('tokenHasExpired', function() {
        r.logout();
    });
    
    r.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) {
        setTransparent = true;
        r.profile = r.getStorage('profile');
        r.hideNav = false;
        r.hideGrowl = toState.data && toState.data.hideGrowl;
        interval.cancel(r.intervalRefresh);
        $('.modal:not(#mqttModal)').remove();
        $('.navbar-collapse').collapse('hide')
        // || (toState.data && toState.data.requiresRole && !r.hasRole(toState.data.requiresRole))
        if (toState.data && toState.data.requiresLogin && !r.user) {
            growl.error('acceso no autorizado');
            console.log('acceso no autorizado')
            event.preventDefault();
            if (s.current !== 'auth') {
                s.go('auth');
                swal.close();
            }
        }
        $anchorScroll(0);
    });
    
    r.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams) {
        if (toState.data && toState.data.pageTitle)
        r.navTitle = toState.data.pageTitle;
        r.active = s.current.name;
    });
    
    r.$on('$stateNotFound', function(event, unfoundState, fromState, fromParams) {
        console.log(unfoundState.to);
        console.log(unfoundState.toParams);
        console.log(unfoundState.options);
        s.go('app');
    });
    
    r.$on('$stateChangeError', function(event, toState, toParams, fromState, fromParams, error) {
        console.log(error);
        s.go('app');
    });
    
    r.showWait = function() {
        r.loading = true;
    };
    r.dismissWait = function() {
        r.loading = false;
    };
    r.jsonToCSV =function(header, data) {
		var array = typeof data != 'object' ? JSON.parse(data) : data;

        //add headers
		var str = header.join(',') + '\r\n';
  
		for (var i = 0; i < array.length; i++) {
		  var line = '';
  
		  for (var index in array[i]) {

            //REPLACE commas for ' '
            if( typeof(array[i][index]) === 'string'){
                array[i][index] = Array.from(array[i][index])
                for(var j = 0; j < array[i][index].length; j++ ){
                    if(array[i][index][j] === ','){
                        array[i][index][j] = ' '
                    }
                }
                array[i][index] = array[i][index].join("")
            }else if( array[i][index] === undefined){
                array[i][index] = ' '
            }

            line += array[i][index] + ',';
		  }
  
		  line.slice(0, line.Length - 1);
  
		  str += line + '\r\n';
		}

		return str
	};
    r.sortArrayByDate = function(list, namePropertyDate){
        list.sort(function(a,b){
            if( moment(a[namePropertyDate]).isAfter(moment(b[namePropertyDate])))
                return 1;
            else 
                return -1;
        })
        console.log(list)

        return list
    }
    r.addIndexToTheList = function(list, nameProperty){
        var newList = [];
        var item;

		for(var i = 0; i < list.length; i++){
            item = {};
            item[nameProperty] = i + 1;
            for(key in list[i]){
                item[key] = list[i][key];
            }
            newList.push(item)
		}
		return newList;
	}
    r.get_values_of_list_generic = function (list, properties){
        /*
        returns a new list with the required properties in the array of 'properties'
        example:
            list = [
                { product: { name: 'futbol ball', price:20 }, category: 'sport'},
                { product: { name: 'basket ball', price:30 }, category: 'sport'}
            ]
            propiertes = [
                ['product', 'name'],  //product.name
                ['category']    //category
            ]
            return:
            [
                {'product.name': 'futbol ball', category: 'sport'},
                {'product.name': 'basket ball', category: 'sport'}
            ]
    
        */
        var list_with_filter = [];
    
        list.forEach( function( element ) {
            var data = {};
    
            properties.forEach( function( property ) {
                var value = 0;
                property.forEach( function( prop, i ) {
                    if( value !== undefined && value !== null && element !== undefined && element !== null){
                        if( i === 0)  {
                            value = element[prop]
                        }else {
                            value = value[prop]
                        }
                    }else {
                        value = undefined;
                        return;
                    }
                })
                
                data[property.join('.')] = value
            } )
    
            list_with_filter.push(data);
        })
    
        return list_with_filter;
    }
    r.changeStateToString = function(list){
		list.forEach(function(element){
            if(element["active"] == true){
                if( element["status.LastCommunication.value"].length > 0){
                    if( element["status.mainStatus"] == 0 ){
                        element["active"] = "Normal"
                    }else if ( element["status.mainStatus"] == 1 ){
                        element["active"] = "Advertencia"
                    }else if ( element["status.mainStatus"] == 2 ){
                        element["active"] = "Alerta"
                    }else{
                        element["active"] = "Sensor no disponible";
                    }
                    // clear this property so it doesn't add a row in the csv
                    delete element["status.mainStatus"]
                }else{
                    element["active"] = "Sensor no disponible";
                }
            }else{
                element["active"] = "Deshabilitado";
            }
		})
		return list
	}
    r.changeBooleanToActiveOrInactive = function(list, nameProperty){
        // changes the value of a property of the elements in the list to active if it is true or inactive if it is false
		list.forEach(function(element){
			element[nameProperty] = element[nameProperty] ? 'Activo' : 'Inactivo'
		})
		return list
	}
}])
.factory('httpInerceptor', ['$q', 'growl', '$rootScope', function($q, growl, r) {
    return {
        response: function(response) {
            if (response.data.status !== 406 && response.data.message)
            growl.success(response.data.message);
            r.block = false;
            return response || $q.when(response);
        },
        responseError: function(response) {
            var msg;
            if (response.status == 406) {
                msg = response.data.message;
                if(response.data.error.errors){
                    msg = '';
                    for(e in response.data.error.errors){
                       msg += response.data.error.errors[e].message + '<br>';
                    }
                }
            } else if (response.status == 403 || response.status == 401) {
                msg = "Acceso denegado.";
                r.logout();
            } else {
                msg = "Ocurrio un error inesperado.";
            }
            growl.error(msg);
            return $q.reject(response);
        }
    };
}])
.config(['$httpProvider', 'jwtOptionsProvider', 'growlProvider', 'blockUIConfig',
function($httpProvider, jwtOptionsProvider, growlProvider,blockUIConfig) {
    jwtOptionsProvider.config({
        whiteListedDomains: ['localhost'],
        unauthenticatedRedirectPath: '/login',
        tokenGetter: ['options', function(options) {
            // Skip authentication for any requests ending in .html
            if (options && options.url && options.url.substr(options.url.length - 5) == '.html') {
                return null;
            }
            return localStorage.getItem('id_token');
        }]
    });
    $httpProvider.interceptors.push('jwtInterceptor');
    $httpProvider.interceptors.push('httpInerceptor');
    growlProvider.globalTimeToLive(3000);
    growlProvider.globalDisableCountDown(true);
    growlProvider.globalDisableIcons(true);
    blockUIConfig.template = '<div class="cssload-container"><div class="cssload-speeding-wheel"></div></div>';
}
])
.filter('unsafe', ['$sce', function($sce) {
    return $sce.trustAsHtml;
}])
.directive('ngEnter', function() {
    return function(scope, element, attrs) {
        element.bind("keydown keypress", function(event) {
            if (event.which === 13) {
                event.preventDefault();
                scope.$apply(function() {
                    scope.$eval(attrs.ngEnter, {
                        'event': event
                    });
                });
            }
        });
    };
})
.directive('customOnChange', function() {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        var onChangeHandler = scope.$eval(attrs.customOnChange);
        element.on('change', onChangeHandler);
        element.on('$destroy', function() {
          element.off();
        });
      }
    };
  })
.factory('Util', [
    function() {
        return {
            dhms: function(t) {
                var days,
                hours,
                minutes,
                seconds;
                days = Math.floor(t / 86400);
                t -= days * 86400;
                hours = Math.floor(t / 3600) % 24;
                t -= hours * 3600;
                minutes = Math.floor(t / 60) % 60;
                t -= minutes * 60;
                seconds = t % 60;
                return [days,
                    hours,
                    minutes,
                    seconds
                ];
            }
        };
    }
]);
//FIX PARA MODALES EN TEMPLATES
function appendModal() {
    var checkeventcount = 1,
    prevTarget;
    $('.modal').on('show.bs.modal', function(e) {
        if (typeof prevTarget == 'undefined' || (checkeventcount == 1 && e.target != prevTarget)) {
            prevTarget = e.target;
            checkeventcount++;
            e.preventDefault();
            $(e.target).appendTo('body').modal('show');
        } else if (e.target == prevTarget && checkeventcount == 2) {
            checkeventcount--;
        }
    });
}

// T1892 Cerrar modales al presionar ESC

$(document).keyup(function(e){
    if(e.which==27) {
        $('.modal').modal("hide");        
    }
});