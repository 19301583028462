angular.module('app').factory('logsCmdService', ['$rootScope', '$http', logsCmdService]);

function logsCmdService(r, h) {
    var service = {
        getAll: get,
        exportCsv: exportCsv
    };
    return service;

    function exportCsv(o){
        return h.post(apiRoute + '/api/logs/cmd/csv/',o).then(function(resp) {
            return resp.data;
        });
    }

    function get() {
        return h.get(apiRoute + '/api/logs/cmd').then(function(resp) {
            return resp.data;
        });
    }
}